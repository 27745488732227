.key-data-row {
  padding: 0px;
  margin: auto;
  transform: translateY(-20px);
}
.key-data-col {
  display: grid;
  place-content: center;
  padding: 0.5rem;
}
.key-data-wrapper {
  padding: 3.5rem;
  padding-top: 0;
  padding-bottom: 1rem;
  position: relative;
  width: 100%;
  margin: auto;
}

.key-data-title {
  padding: 0;
  margin: 0;
  font-size: 50px;
  line-height: 67px;
  font-weight: 700;
}

@media screen and (max-width: 1000px) {
  .key-data-row {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 770px) {
  .key-data-title {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 700px) {
  .key-data-wrapper {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .key-data-title {
    padding: 0;
    font-size: 25px;
    line-height: 37px;
    transform: translateX(0px);
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  .key-data-row {
    transform: translateY(0px);
    padding: 0;
  }
  .key-data-col {
    padding: 0;
  }
}
.key-data-row .card-hovered {
  bottom: 17px;
}

@media screen and (min-width: 700px) and (max-width: 770px) {
  .key-data-row .card-hovered {
    bottom: 17px;
  }
  .key-data-row {
    transform: translateY(0px);
    padding: 0;
    row-gap: 70px !important;
  }
}

section.section-key {
  overflow: scroll;
  overflow-y: hidden;
  display: flex;
  gap: 20px;
  width: 90vw;
  margin: auto;
  scroll-snap-type: x mandatory;
  padding: 1.5rem;
  position: relative;
}

section.section-key > article {
  scroll-snap-align: start;
  width: 80vw;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
section.section-key .card-wrapper-res {
  width: 100%;
  margin: auto;
  display: flex;
}
section.section-key article::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 80%;
  top: 10%;
  left: 0;
  background-color: rgba(126, 126, 126, 0.185);
}

section.section-key > article .chart-button:hover {
  color: #0e9898;
}

section.section-key > article .chart-button {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #151515;
  position: absolute;
  bottom: 0;
}

@media screen and (max-width: 700px) {
  section.section-key > article .chart-button {
    font-size: 10px;
  }
  .card-wrapper-res .chart-ratio {
    font-size: 16px;
  }
}
