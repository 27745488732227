.explore-wrapper {
  position: relative;
  width: 100%;
  padding: 1.5rem;
}

.explore-wrapper p.title {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 56px;
  text-transform: uppercase;
  color: black;
  text-align: center;
}
.explore-wrapper p.subtitle {
  margin-top: -30px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: black;
  text-align: center;
}

.explore-wrapper .img-container {
  position: relative;
  width: 100%;
}
.explore-wrapper .img-container img {
  display: block;
  margin: auto;
  max-width: 100%;
  width: 50%;
  height: auto;
}

.explore-wrapper .btn-container {
  text-align: center;
  padding: 30px;
}
.explore-wrapper .btn-container a {
  display: inline-block;
  font-style: normal;
  font-size: 24px;
  line-height: 21px;
  text-transform: uppercase;
  color: black;
  padding: 16px 48px;
  font-weight: 700;
  transition: 0.2s ease-in;
}
.explore-wrapper .btn-container a:hover {
  color: #0e9898;
}

@media screen and (max-width: 700px) {
  .explore-wrapper p.title {
    font-size: 26px;
    line-height: 26px;
    padding-bottom: 12px;
  }
  .explore-wrapper p.subtitle {
    font-size: 15px;
    line-height: 22px;
    padding-top: 20px;
  }
  .explore-wrapper .img-container img {
    width: 100%;
  }
  .explore-wrapper .btn-container a {
    padding: 8px 24px;
    font-size: 13px;
  }
}

.explore-arrow {
  margin-left: 10px;
  animation: arrowAnimation 1s ease-in infinite;
}

@media screen and (max-width: 700px) {
  .explore-wrapper p.title {
    font-size: 20px;
    line-height: 20px;
  }
}
@keyframes arrowAnimation {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(10px);
    opacity: 0;
  }
}

@media screen and (max-width: 1000px) {
  .explore-wrapper {
  }
}
