.theme-page {
  position: relative;
  width: 100%;
  background-color: white;
}

.theme-subheader-wrapper {
  position: relative;
  width: 100%;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding-bottom: 2rem;
  padding-top: 1.5rem;
}
.theme-subheader-wrapper.ness {
  height: auto;
}
/*ANT DESIGN BREADCRUMB CUSTOMIZATION*/
.theme-subheader-wrapper .ant-breadcrumb {
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  font-style: normal;
  line-height: 20px;
  padding: 10px 60px !important;
  position: relative;
  width: 100%;
  font-weight: 700;
  letter-spacing: 2.8px;
  font-size: 14px !important;
  line-height: 20px;
}

.theme-subheader-wrapper span span.ant-breadcrumb-link a:hover {
  color: #0e9898;
}

.theme-subheader-wrapper .ant-breadcrumb a:hover {
  color: #0e9898;
}

.theme-subheader-wrapper .ant-breadcrumb > span:last-child {
  font-weight: normal;
  color: rgb(0, 0, 0);
}

.theme-subheader-wrapper .ant-breadcrumb > span:last-child a {
  font-weight: 400;
  color: rgb(0, 0, 0);
}

.theme-subheader-wrapper .ant-breadcrumb-separator {
  color: rgb(0, 0, 0);
  opacity: 0.6;
}
.theme-subheader-wrapper span.ant-breadcrumb-link a {
  color: rgb(0, 0, 0);
}
.theme-subheader-wrapper span.ant-breadcrumb-link a:hover {
  color: rgb(0, 0, 0);
}

.theme-subheader-wrapper .ant-breadcrumb a:hover {
  color: rgb(0, 0, 0);
}

.theme-subheader-wrapper .theme-subheader-title {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 75px;
  width: 60%;
  padding-left: 60px;
}

@media screen and (max-width: 700px) {
  .theme-subheader-wrapper {
    padding-bottom: 0.5rem;
  }
  .theme-subheader-wrapper .theme-subheader-title {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 40px;
    line-height: 65px;
  }
  .theme-subheader-wrapper .ant-breadcrumb {
    padding-left: 1.5rem !important;
  }
}

.theme-subheader-wrapper .theme-subheader-content {
  color: #626161;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  padding: 0 60px;
  width: 100%;
}

/*IFRAME COMPONENT STYLES*/
.theme-iframe {
  position: relative;
  width: 100%;
  padding: 68px;
  padding-top: 1rem;
  background-color: white;
}

.theme-iframe iframe {
  position: relative;
  width: 100%;
  min-height: 600px;
  border: 1px solid #c2c2c2;
}

.theme-iframe .iframe-footer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px 0;
}

.theme-iframe .iframe-footer ul {
  position: relative;
  width: 100%;
  list-style-type: none;
  padding: 0;
}
.theme-iframe .iframe-footer ul li {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 17px;
  color: #212121;
  margin-bottom: 5px;
}
.theme-iframe .iframe-footer ul li span {
  color: #212121;
  font-weight: 600;
}
/*SPINNER*/
.theme-page-spinner {
  width: 100%;
  position: relative;
  height: 500px;
  display: grid;
  place-content: center;
  background-color: white;
  font-size: 17px;
}

/*RESPOSNIVENESS*/

@media screen and (max-width: 970px) {
  .theme-subheader-wrapper {
    height: auto;
  }
  .theme-subheader-wrapper .theme-subheader-title {
    width: 80%;
  }
  .theme-subheader-wrapper .theme-subheader-content {
    width: 80%;
  }
}

@media screen and (max-width: 560px) {
  .theme-subheader-wrapper .theme-subheader-title {
    width: 100%;
    font-size: 34px;
    line-height: 45px;
  }
  .theme-subheader-wrapper .theme-subheader-content {
    width: 100%;
    font-size: 20px;
    line-height: 28px;
  }
}

@media screen and (max-width: 700px) {
  .theme-subheader-wrapper .theme-subheader-content {
    padding: 0 1.5rem;
    font-size: 18px;
  }
  .theme-iframe {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

/*CUSTOM THEMES*/

section.ness-content {
  padding: 30px;
  padding-top: 0px;
  padding-bottom: 15px;
}

.ness-info {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: end;
}
.ness-info .ant-select-arrow {
  margin-top: -6px !important;
}
.ness-info > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.ness-info > div > main {
  display: flex;
  gap: 10px;
  align-items: center;
  font-weight: 600;
}
.ness-info > div > section {
  display: flex;
  gap: 8px;
}

.ness-info > div > section a {
  display: block;
  background-color: black;
  text-decoration: none;
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 3px;
  transition: 0.1s ease;
}
.ness-info > div > section a:hover {
  transform: scale(1.1);
}

.theme-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: black;
}

.theme-row .status {
  width: 40px;
  height: 40px;

  border-radius: 50%;
}
.theme-row .status.positive {
  background-color: green;
}
.theme-row .status.negative {
  background-color: rgb(255, 0, 0);
}

.theme-row .status.neutral {
  background-color: rgb(184, 184, 184);
}

.theme-row .status.no-change {
  background-color: #ffc400;
}
.theme-info-row > div {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 16px;
}

/*NESS TABLE*/

.ness-table {
  position: relative;
  font-size: 16px;
}

.ness-table .ness-actions {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  padding-bottom: 10px;
}
.ness-table .ness-actions section {
  display: flex;
  gap: 10px;
  color: white;
}
.ness-table .ness-actions section a {
  background-color: black;
  color: white;
  display: inline-block;
  padding: 1px 5px;
  border-radius: 10px;
  font-size: 10px;
  transition: ease-in 0.1s;
}
.ness-table .ness-actions section a:hover {
  transform: scale(1.09);
}

.ness-col-group {
  display: flex;
  flex-direction: column;
}

.ness-col-name {
  width: 30%;
  font-size: 14px;
  padding: 5px;
  font-weight: 500;
}

.ness-col-data {
  width: 70%;
  font-size: 14px;
  padding: 5px;
}

.ant-table-cell.ness-col-name {
  padding: 2px;
  padding-left: 6px;
  border: 0.6px solid rgba(0, 0, 0, 0.582);
}
.ant-table-cell.ness-col-name:hover {
  background-color: red;
}

.ness-table tbody > tr:hover > td {
  background-color: unset !important;
  color: unset !important;
}

.ant-table-cell.ness-col-data {
  border: 0.6px solid rgba(0, 0, 0, 0.582);
  padding: 2px;
  padding-left: 10px;
}

.ness-container {
  position: relative;
  overflow-x: scroll;
  padding: 0 30px;
}

.ness-container main {
  min-width: 1380px;
}

@media screen and (max-width: 1400px) {
  .ness-container {
    width: 95vw;
    margin: auto;
    border: 1px solid rgb(235, 235, 235);
  }
}

@media screen and (max-width: 700px) {
  .ness-container {
    position: relative;
    overflow-x: scroll;
    padding: 0 1.5rem;
    padding-left: 1rem;
  }
}

@media screen and (max-width: 1000px) {
  section.ness-content {
    padding-right: 24px;
  }
}
@media screen and (max-width: 768px) {
  section.ness-content {
    padding-right: 20px;
  }
}
@media screen and (max-width: 568px) {
  section.ness-content {
    padding-right: 16px;
  }
}
@media screen and (max-width: 400px) {
  section.ness-content {
    padding-right: 10px;
  }
}

.ness-download-csv {
  position: relative;
  transition: 0.2s ease-in;
}
.ness-download-csv:hover {
  transform: scale(1.1);
}
