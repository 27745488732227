.ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
  font-size: x-large;
}
.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}
.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}
.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}
.certain-category-search-dropdown .ant-select-dropdown-menu {
  height: auto;
  min-height: min-content;
}
.edt-url {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6px;
}
.edt-all-result {
  padding: 0 8px;
  display: block;
}
.edt-url > div:nth-child(2) {
  color: rgba(97, 97, 97, 0.65);
}
.edt-url > div:nth-child(1) {
  flex: 0.9;
  position: relative;
  text-overflow: ellipsis;
  height: auto;
}
.edt-url > div > .title {
  padding: 0 2px;
  font-weight: 700;
  font-size: 14px;
}
.edt-url > div > .description {
  word-break: break-all;
  position: relative;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  padding: 0 2px;
}

.edt-url > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.certain-category-search-dropdown .ant-select-item-option-content {
  white-space: pre-wrap !important;
}
.ant-select-item:not():first-child {
  margin-top: 12px !important;
  padding: 0 16px !important;
}

@media screen and (max-width: 500px) {
  .edt-url > div > .title {
    padding: 0px;
    font-size: 13px;
  }
  .edt-url > div > .description {
    padding: 0;
  }
  .edt-all-result {
    padding: 0 6px;
  }
}
