@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,400;0,600;0,700;1,200&display=swap);
.all-themes-wrapper {
  background-color: white;
}

section.all-themes-list {
  position: relative;
  width: 100%;
  padding-left: 60px;
  padding-top: 1rem;
  padding-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(6, minmax(100px, 450px));
  grid-gap: 10px 20px;
  padding-right: 60px;
}

@media screen and (max-width: 1150px) {
  section.all-themes-list {
    grid-template-columns: repeat(3, minmax(100px, 450px));
  }
}

@media screen and (max-width: 700px) {
  section.all-themes-list {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(100px, 450px));
  }
}

@media screen and (max-width: 700px) and (min-width: 500px) {
  section.all-themes-list {
    grid-template-columns: repeat(2, minmax(100px, 350px));
  }
}

.theme-card-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.79);
  cursor: pointer;
  aspect-ratio: 1/1;
  transition: 0.15s ease-in;
  max-height: 310px;
  max-width: 330px;
  margin: auto;
  scroll-snap-align: start;
}
.theme-card-wrapper:hover {
  -webkit-transform: scale(1.025);
          transform: scale(1.025);
}

.theme-card-image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.93;
}
.theme-card-wrapper .theme-card-text {
  position: absolute;
  width: 100%;
  height: 70px;
  bottom: 0px;
  padding-left: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.theme-card-wrapper .theme-card-text-with-background {
}

.theme-card-wrapper .theme-card-text h3 {
  bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: white;
  text-transform: uppercase;
}
.theme-card-wrapper .theme-card-text h2 {
  text-transform: capitalize;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  letter-spacing: 1.6px;
}

@media screen and (max-width: 700px) {
  .theme-card-wrapper .theme-card-text h3 {
    font-size: 11px;
  }
  .theme-card-wrapper .theme-card-text h2 {
    font-size: 14px;
  }
}

.theme-test-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 160px);
  overflow: scroll;
  width: 90vw;
  margin: auto;
  grid-gap: 40px;
  scroll-snap-type: x mandatory;
}
.theme-test-div {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.79);
  cursor: pointer;
  aspect-ratio: 1/1;
  transition: 0.15s ease-in;
  max-height: 310px;
  max-width: 330px;
  margin: auto;
  scroll-snap-align: start;
}

.theme-test {
}
.theme-test-desc {
}

.subheader-wrapper {
  background: white;
  color: rgb(0, 0, 0);
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  padding: 48px 0px;
  padding-top: 1.5rem;
  position: relative;
  width: 100%;
}

.subheader-wrapper .ant-breadcrumb {
  color: rgb(0, 0, 0);
  font-weight: 700;
  letter-spacing: 2.8px;
  font-size: 14px !important;
  line-height: 20px;
  padding: 0 60px !important;
  position: relative;
  text-transform: uppercase;
  width: 100%;
}

.subheader-wrapper .ant-breadcrumb a {
  color: rgb(0, 0, 0);
}
.subheader-wrapper span.ant-breadcrumb-link a {
  color: rgb(0, 0, 0);
}
.subheader-wrapper span.ant-breadcrumb-link a:hover {
  color: #0e9898;
}

.subheader-wrapper .ant-breadcrumb a:hover {
  color: #0e9898;
}

.subheader-wrapper .ant-breadcrumb > span:last-child a {
  font-weight: normal;
  color: rgb(0, 0, 0);
}
.subheader-wrapper .ant-breadcrumb-separator {
  color: rgb(0, 0, 0);
  opacity: 0.6;
}

.subheader-wrapper .title {
  font-size: 48px;
  font-weight: 600;
  font-weight: 700;
  line-height: 57.6px;
  color: rgb(33, 33, 33);
  padding: 0 60px;
}

.subheader-wrapper .description {
  color: #626161;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  padding: 0 60px;
}
.subheader-wrapper .source {
  color: #626161;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  padding: 0 60px;
}

.subheader-btn {
  padding: 0 60px;
  text-decoration: none;
  transition: 0.2s ease;
}
.subheader-btn button {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px 48px;
}
.subheader-btn button:hover {
  color: white;
  background-color: black;
}
@media screen and (max-width: 940px) {
  .category-img {
    -webkit-transform: translateY(20%);
            transform: translateY(20%);
  }
}
@media screen and (max-width: 1000px) {
  .category-img {
    -webkit-transform: translateY(30%);
            transform: translateY(30%);
  }
}
@media screen and (max-width: 500px) {
  .title {
    padding: 0 30px;
  }
  .description {
    padding: 0 30px;
  }
  .source {
    padding: 0 30px;
  }
  .ant-breadcrumb {
    padding: 0 30px !important ;
  }
  .subheader-btn {
    padding: 0 30px;
  }
}

@media screen and (max-width: 1000px) {
  .subheader-wrapper .title {
    font-size: 42px;
  }
}

@media screen and (max-width: 700px) {
  .subheader-wrapper {
    padding: 1.5rem 0px;
  }
  .subheader-wrapper .ant-breadcrumb {
    font-size: 11px !important;
    line-height: 18px;
    padding: 0 1.5rem !important;
  }
  .subheader-wrapper .title {
    padding: 0 1.5rem;
    font-size: 36px;
    font-weight: 600;
    font-weight: 700;
    line-height: 50.6px;
    margin-top: -1rem;
  }
  .subheader-wrapper .description {
    padding: 0 1.5rem;
    font-size: 16px;
    line-height: 30px;
  }
  .subheader-wrapper .source {
    padding: 0 1.5rem;
    font-size: 16px;
    line-height: 30px;
  }
}

@media screen and (max-width: 350px) {
  .subheader-wrapper .ant-breadcrumb {
    word-wrap: break-word;
    width: 90%;
  }
}
@media screen and (max-width: 300px) {
  .subheader-wrapper .ant-breadcrumb {
    word-wrap: break-word;
    width: 80%;
  }
}

@media (min-width: 768px) {
  .subheader-wrapper .ant-col-md-16 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1280px) {
  .subheader-wrapper .ant-col-md-16 {
    display: block;
    flex: 0 0 66%;
    max-width: 66%;
  }
}

.category-section-wrapper {
  position: relative;
  width: 100%;
  padding: 0.7rem 4rem;
  background: #fff;
}
.category-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
}

.category-section-header .text {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 32px;
  color: #151515;
}
.category-section-header .view-all-btn {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #151515;
  grid-gap: 8px;
  gap: 8px;
  transition: ease-in 0.3s;
}
.category-section-header .view-all-btn:hover {
  color: #0e9898;
}

@media screen and (max-width: 700px) {
  .category-section-wrapper {
    padding: 0.7rem 1.5rem;
  }

  .category-section-header {
    padding-right: 1.3rem;
  }
  .category-section-header .text {
    font-size: 20px;
    line-height: 30px;
  }
  .category-section-header .view-all-btn {
    font-size: 15px;
    line-height: 24px;
  }
}

.category-section-wrapper .categ-row {
  padding: 20px;
  margin: auto;
  padding-bottom: 0px;
}

@media screen and (max-width: 500px) {
  .category-section-wrapper {
    position: relative;
    width: 100%;
    padding: 15px 1.5rem;
    background: #fff;
  }
  .category-section-header {
    padding-right: 1.2rem;
  }
}

@media screen and (max-width: 400px) {
  .category-section-header {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

/*To Fix- Responsive FontSize for mobile */

.card-wrapper {
  width: 100%;
  position: relative;
  padding-top: 0px;
  box-sizing: border-box;
  margin: auto;
  min-height: 273px;
  display: flex;
  justify-content: center;
}
.card-wrapper::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 80%;
  top: 10%;
  left: 0;
  background-color: rgba(126, 126, 126, 0.185);
}
.chart-card-wrapper {
  max-width: 400px;
  padding: 17px;
  display: inline-block;
  box-sizing: border-box;
  background-color: white;
  transition: 0.3s ease-in;
  margin: auto;
  border: 1px solid white;
  overflow: hidden;
  position: relative;
}

.chart-card-wrapper.larger {
  max-width: 600px;
  width: 90%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-wrapper.larger {
  height: 300px;
}
.chart-card-wrapper:hover {
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(126, 126, 126, 0.185);
  box-sizing: border-box;
  cursor: pointer;
}
.chart-card-wrapper:hover .card-hovered {
  display: block;
}

.chart-card-wrapper:hover .chart-button {
  display: flex;
}

.chart-card-wrapper .chart-title {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
}
.chart-card-wrapper .chart-ratio {
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 32px;
  color: #0e9898;
}

.chart-card-wrapper .chart-year {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #6b6b6b;
}
.chart-card-wrapper .chart-button-present {
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #151515;
  transition: 0.2s ease-in;
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
}

.chart-card-wrapper .chart-button {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #151515;
  transition: 0.2s ease-in;
  display: none;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding-left: 1rem;
}
.chart-card-wrapper .chart-button:hover {
  color: #116530;
}
.card-empty-div {
  position: relative;
  width: 100%;
  height: 40px;
}

.card-hovered {
  padding-top: 20px;
  position: absolute;
  bottom: 19px;
  width: 100%;
  display: none;
  margin: auto;
  -webkit-transform: translateX(-20px);
          transform: translateX(-20px);
  overflow: hidden;
}

.horizontal {
  display: flex;
  flex-direction: row;
}

.flexLayout {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
}

.flexLayout.horizontal {
  flex-direction: row-reverse;
  justify-content: space-around;
  height: 200px;
}
.flexLayout .layout-space {
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  grid-gap: 20px;
  gap: 20px;
}
.flexLayout .layout-space a:hover {
  color: #116530;
}

@media screen and (max-width: 1200px) {
  .card-hovered {
    bottom: 15px;
  }
}
@media screen and (max-width: 900px) {
  .card-hovered {
    bottom: 32px;
  }
}

@media screen and (max-width: 840px) {
  .card-hovered {
    bottom: 40px;
  }
}

@media screen and (max-width: 907px) and (min-width: 769px) {
  .category-section-wrapper .card-hovered {
    bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .card-hovered {
    bottom: 6px;
  }
}

@media screen and (max-width: 576px) {
  .card-hovered {
    bottom: 15px;
  }
}

/* /* @media screen and (max-width: 500px) {
  .card-hovered {
    bottom: 24px;
  } */
@media screen and (max-width: 409px) {
  .card-hovered {
    bottom: 15px;
  }
}
@media screen and (max-width: 410px) and (min-width: 390px) {
  .card-hovered {
    bottom: 25px;
  }
}
@media screen and (max-width: 350px) {
  .card-hovered {
    bottom: 10px;
  }
}

@media screen and (min-width: 490px) and (max-width: 590px) {
  .card-hovered {
    bottom: 20px;
  }
}

@media screen and (max-width: 370px) {
  .card-hovered {
    bottom: 18px;
  }
}

@media screen and (max-width: 999px) and (min-width: 700px) {
  .chart-card-wrapper .chart-button-present {
    font-size: 12px;
  }
  .chart-card-wrapper .chart-title {
    font-size: 14px;
  }
}

@media screen and (max-width: 420px) {
  .chart-card-wrapper .chart-button-present {
    font-size: 12px;
  }
  .chart-card-wrapper .chart-title {
    font-size: 14px;
  }
  .chart-card-wrapper .chart-ratio {
    font-size: 18px;
    line-height: 28px;
  }
  .flexLayout .layout-space {
    grid-gap: 10px;
    gap: 10px;
  }
}
@media screen and (min-width: 260px) and (max-width: 1200px) {
  .key-data-wrapper .card-wrapper img {
    max-height: 200px;
  }
}

.card-wrapper-res {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  height: 180px;
  width: 200px;
}

.card-wrapper-res .chart-title {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
}
.card-wrapper-res .chart-ratio {
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 32px;
  color: #0e9898;
}

.card-wrapper-res .chart-year {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #6b6b6b;
}

@media screen and (max-width: 700px) {
  .card-wrapper-res {
    display: flex;
  }

  .card-wrapper-res .chart-image img {
    max-height: 70px;
  }

  .card-wrapper-res .chart-title {
    height: 10vw;
    font-size: 12px;
    line-height: 14px;
  }
  .card-wrapper-res .chart-year {
    font-size: 10px;
    line-height: 12px;
  }
  .card-wrapper-res .chart-ratio {
    font-size: 14px;
  }

  section.section-key > article .chart-button {
    font-size: 11px;
  }
}

.data-explorer {
  position: relative;
  width: 100%;
  background: #ffffff;
  padding: 1.5rem 60px;
}

.data-explorer > h1.title {
  font-size: 48px;
  font-weight: 700;
  line-height: 50.6px;
  color: rgb(33, 33, 33);
  font-size: 48px;
}

.data-explorer > p.subtitle {
  color: #626161;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  margin-top: -20px;
}

.data-explorer > span {
  -webkit-transform: translateY(20px);
          transform: translateY(20px);
  position: relative;
  display: block;
  width: 80%;
  margin: auto;
  height: 1px;
  background-color: #0e9898;
}

@media screen and (max-width: 750px) {
  .data-explorer > h1.title {
    font-size: 35px;
  }
}

@media screen and (max-width: 700px) {
  .data-explorer {
    background-color: red;
  }
}

.indicator-filters {
  flex: 0.2 1;
  position: relative;
  width: 100%;
  background: hsla(240, 20%, 98%, 0.356);
  padding: 8px;
  padding-bottom: 12px;
  border: 1px solid #d4d4d4a2;
}

.indicator-filters .filters-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #151515;
  padding-left: 17px;
  padding-bottom: 15px;
  letter-spacing: 1.5px;
}

.indicator-filters .filter-area {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: -100px;
  gap: -100px;
}
.indicator-filters .filter-area .filter-comp {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
}
.indicator-filters .filter-area .filter-category {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #151515;
  padding-bottom: 12px;
}

.indicator-filters .filter-area .filter-category::first-letter {
  text-transform: uppercase;
}
.indicator-filters .filter-area .filter-checkbox {
  padding-top: 10px;
}
.indicator-filters .filter-area .filter-checkbox span input[type='checkbox'] {
  padding: 30px;
}
.indicator-filters .filter-area .checkboxes {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.indicator-filters .filter-area .checkboxes .checkbox-value {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #151515;
}
.indicator-filters .filter-area .checkboxes .checkbox-value::first-letter {
  text-transform: uppercase;
}
@media screen and (max-width: 1024px) {
  .indicator-filters {
    flex: 1 1;
    padding: 10px;
  }
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0e9898 !important;
  border-color: #0e9898 !important;
}

.ant-checkbox .ant-checkbox-inner:hover {
  border-color: #0e9898 !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #0e9898 !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #0e9898 !important;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #0e9898 !important;
}
[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel {
  background-color: green;
}
[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 5px;
  overflow: hidden;
  border: 0px;
}

/* .filter-range-picker {
  background-color: orangered;
  display: flex;
  flex-direction: column;
  width: 160px;
} */

.filter-range-picker:hover {
  border: #0e9898 1px solid !important;
}

.ant-picker-suffix {
  display: none !important;
}
.ant-picker-range .ant-picker-active-bar {
  background: #0e9898 !important;
  margin-left: 2px !important;
}
.ant-picker-focused {
  border-color: #0e9898 !important;
}

.indicator-filters .ant-collapse-header {
  font-weight: 600;
  font-size: 16px;
  padding: 0 !important;
  padding-bottom: 2px !important;
  padding-left: 17px !important;
}
.indicator-filters .ant-picker-input input {
  font-size: 12px !important;
}

.indicator-chart-wrapper {
  flex: 0.8 1;
  position: relative;
  width: 100%;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  padding-bottom: 5px;
}
.indicator-chart-wrapper .header {
  display: flex;
  padding: 8px;
  padding-left: 17px;
  padding-right: 17px;
  padding-bottom: 0;
}
.header .text {
  flex: 0.75 1;
}

.header .text h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
  color: #151515;
  margin-bottom: 5px;
}

.indicator-chart-wrapper .header .text p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.indicator-chart-wrapper .header .icons {
  flex: 0.25 1;
  display: flex;
  justify-content: flex-end;
}
.indicator-chart-wrapper .header .icons .items {
  grid-gap: 20px;
  gap: 20px;
  display: flex;
}
.indicator-chart-wrapper .header .icons .items div img {
  width: 18px;
  height: 18px;
}
.indicator-chart-wrapper .header .icons .items div {
  transition: 0.2s ease;
  cursor: pointer;
}
.indicator-chart-wrapper .header .icons .items div:hover {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.indicator-chart-wrapper .chart {
  position: relative;
  width: 100%;
}
.indicator-chart-wrapper .chart img {
  max-width: 100%;
  position: relative;
  display: block;
  object-fit: contain;
}
.indicator-footer {
  padding: 40px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.indicator-footer .txt {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  color: #151515;
  transition: 0.2s ease;
}
.indicator-footer .txt:hover {
  cursor: pointer;
  font-size: 18px;
}

.indicator-footer .share {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  width: 220px;

  font-style: normal;
  font-weight: 600;
  justify-content: space-around;
  font-size: 14px;
  line-height: 20px;
  color: #4d4d4d;
  display: flex;
  background-color: rgba(255, 255, 255, 0.671);
  padding: 16px;
}

.undp-text {
  color: white;
  margin-top: 5px;
  padding: 0;
  margin-bottom: 0;
  text-transform: uppercase;
}

.indicator-hashtags {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: start;
  padding-left: 5px;
  color: #828282;
}

.edt-url a {
  color: #0e9898 !important;
}

.publication-date:hover,
.publication-title:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: rgba(10, 105, 194, 0.25);
          text-decoration-color: rgba(10, 105, 194, 0.25);
}

.edt-text {
  color: white;
  margin-top: 5px;
  padding: 0;
  margin-bottom: 0;
  font-size: x-large;
  text-transform: uppercase;
}
.indicator-footer .share div > img {
  cursor: pointer;
  transition: 0.1s ease;
}
.indicator-footer .share div:hover > img {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}
.explore-chart-spin {
  position: relative;
  width: 100%;
  display: grid;
  place-content: center;
  height: 300px;
}

.echart-tooltip {
  z-index: 0 !important;
  padding: 0px !important;
  width: auto;
  box-shadow: none !important;
  font-size: 16px !important;
  /* background: none !important; */
  background-color: none !important;
  margin-left: 120px;
  max-height: 300px;
  pointer-events: all !important;
  overflow-y: auto;
}

.echart-tooltip.modalopen {
  margin-left: -50px;
}

.echart-tooltip.modalopen.indicator {
  margin-left: 130px;
}

.echart-tooltip.modalopen.indicator {
  margin-left: 130px;
}
.positive-trend {
  color: green;
}

.negative-trend {
  color: red;
}

@media screen and (max-width: 1024px) {
  .indicator-chart-wrapper {
    flex: 1 1;
  }
}

@media screen and (max-width: 700px) {
  .header .text h5 {
    font-size: 20px;
    line-height: 22px;
  }

  .indicator-chart-wrapper .header .text p {
    font-size: 13px;
    line-height: 17px;
  }
  .indicator-chart-wrapper .header .icons .items div img {
    width: 15px;
    height: 15px;
  }
}
/*TOOLTIP POSITION*/

@media screen and (min-width: 1500px) {
  .echart-tooltip {
    margin-left: 200px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .echart-tooltip.indicator {
    margin-left: 100px;
  }
}
@media screen and (max-width: 1500px) and (min-width: 1400px) {
  .echart-tooltip.indicator {
    margin-left: 157px;
  }
}
@media screen and (min-width: 1500px) {
  .echart-tooltip.indicator {
    margin-left: 250px;
  }
}
@media screen and (max-width: 1250px) {
  .echart-tooltip.indicator {
    margin-left: 50px;
  }
}
@media screen and (max-width: 1100px) {
  .echart-tooltip.indicator {
    margin-left: -20px;
  }
}
@media screen and (max-width: 1000px) {
  .echart-tooltip.indicator {
    margin-left: -100px;
  }
}
@media screen and (max-width: 900px) {
  .echart-tooltip.indicator {
    margin-left: -180px;
  }
}

.dcsv-img:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.indicator-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  padding: 25px 65px;
  padding-bottom: 10px;
}

.indicator-wrapper .header {
  width: 100%;
  position: relative;
}

.add-indicator-container {
  display: flex;
  justify-content: 'center';
  align-items: 'center';
  padding-top: 25px;
  padding-bottom: 4.5rem;
}

.add-indicator-container a {
  margin: auto;
  box-sizing: border-box;
  display: inline-flex;
  font-size: 14px;
  line-height: 21px;
  color: rgb(0, 0, 0);
  padding: 16px 48px;
  transition: 0.4s ease-in;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.add-indicator-container.height-min {
  min-height: 400px;
}

.add-indicator-container > a:hover > span:nth-child(2) {
  color: rgba(0, 0, 0, 0.733);
}

.add-indicator-container a.new:hover {
  color: rgba(0, 0, 0, 0.733);
}

.add-indicator-container.active a {
  color: #151515;
  background-color: white;
}

.add-indicator-btn {
  font-size: 48px;
  font-weight: 700;
  padding: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 4.5rem;
}
.add-indicator-btn a {
  margin: auto;
  box-sizing: border-box;
  display: inline-flex;
  font-size: 14px;
  line-height: 21px;
  color: rgb(0, 0, 0);
  padding: 16px 48px;
  transition: 0.4s ease-in;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}
.add-indicator-btn > a:hover > span:nth-child(2) {
  color: rgba(0, 0, 0, 0.733);
}

.add-indicator-btn a.new:hover {
  color: rgba(0, 0, 0, 0.733);
}

.add-indicator-btn.active a {
  color: #151515;
  background-color: white;
}
.theme-subheader-wrapper.explorer-subheader {
  height: auto;
}
.theme-subheader-wrapper.explorer-subheader .theme-subheader-title {
}

/*MODAL styling*/

@media screen and (max-width: 1024px) {
  .indicator-wrapper {
    flex-direction: column;
  }

  .indicator-wrapper .filter-button {
    position: relative;
    display: inline-block;
    font-size: 20px;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .indicator-wrapper .filter-button span {
    position: relative;
    display: inline;
    padding: 0.3rem 0.5rem;
    max-width: 120px;
    display: flex;
    background-color: #d9d9d9;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
  }
}

@media screen and (max-width: 700px) {
  .indicator-wrapper {
    flex-direction: column;
    padding: 20px 1.5rem;
    padding-bottom: 10px;
  }
  .theme-subheader-wrapper.explorer-subheader {
    height: 100px;
  }
  .indicator-wrapper .filter-button span {
    padding: 0.1rem 0.2rem;
    max-width: 100px;
    font-size: 18px;
  }
  .download-img-modal .ant-modal-body {
    padding: 10px !important;
  }
  .add-indicator-container.height-min {
    min-height: 600px;
  }
}

@media screen and (max-width: 500px) {
  .indicator-wrapper .filter-button {
    padding-bottom: 1rem;
  }
  .indicator-wrapper .filter-button span {
    padding: 0.1rem 0.3rem;
    max-width: 100px;

    font-size: 16px;
  }
}

.modal-wrapper {
  width: 100%;
  position: absolute;
  z-index: 19000;
  inset: 0;
  top: 30px;
  background-color: rgba(21, 21, 21, 0.85);
  overflow-y: hidden;
}

.modal-wrapper .bar-container {
  display: flex;
  justify-content: space-between;
  background-color: #f8f8fa;
  align-items: center;
  padding: 0 10px;
}
.modal-wrapper .bar-container > div:nth-child(2) {
  cursor: pointer;
}
.modal-wrapper .bar-container > div:nth-child(2):hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.modal-wrapper .empty {
  position: relative;
  width: 100%;
  height: 10vh;
}
.modal-wrapper .breadcrumbs {
  background-color: #f8f8fa;
  padding: 16px 18px;
}

.modal-wrapper .breadcrumbs .breaditem {
  color: #151515;
}

.modal-wrapper .ant-breadcrumb-separator {
  color: rgb(0, 0, 0) !important;
  opacity: 0.4;
}

.modal-wrapper .empty {
  position: relative;
  width: 100%;
  height: 10vh;
}
.modal-wrapper .empty-bottom {
  position: relative;
  width: 100%;
  height: 900px;
  background-color: rgba(21, 21, 21, 0.85);
}
@media screen and (max-width: 700px) {
  .modal-wrapper .breadcrumbs {
    padding: 16px 0px;
  }
}

@media screen and (max-width: 500px) {
  .modal-wrapper .breadcrumbs {
    padding: 16px 0px;
    -webkit-transform: translateX(-30px);
            transform: translateX(-30px);
  }
}

.indicator-chart-card-wrapper {
  max-width: 150px;
  padding: 8px;
  padding-bottom: 2px;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  transition: 0.3s ease-in;
  border: 1px solid rgb(243, 243, 243);
  margin: auto;
  height: 100%;
}

.indicator-chart-card-wrapper:hover {
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(126, 126, 126, 0.185);
  box-sizing: border-box;
  cursor: pointer;
}
.indicator-chart-card-wrapper .content .icon {
  width: 20px;
  padding-top: 10px;
  cursor: pointer;
  transition: 0.1s ease;
}
.indicator-chart-card-wrapper .content .icon img {
  display: none;
}
.indicator-chart-card-wrapper:hover .icon img {
  display: block;
  width: 20px;
}
.indicator-chart-card-wrapper div > img {
  display: inline-block;
  max-width: 100%;
  margin: auto;
}
.indicator-chart-card-wrapper .content {
  margin-top: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.indicator-chart-card-wrapper .content .text {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.indicator-chart-card-wrapper div > div h3:nth-child(1) {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  width: 100%;
}
.indicator-chart-card-wrapper div > div h3:nth-child(2) {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #0e9898;
}

.indicator-chart-card-wrapper .content .icon:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

@media screen and (max-width: 500px) {
  .indicator-chart-card-wrapper {
    max-width: 300px;
  }
}

.related-indicators-wrapper {
  position: relative;
  width: 100%;
  background-color: rgb(255, 255, 255);
  height: 350px;
}

.related-indicators-wrapper .header {
  padding: 15px 20px;
  padding-bottom: 5px;
  padding-left: 28px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.related-indicators-wrapper .pagination .arrows {
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
  left: 50%;
  right: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  margin-left: 130px;
}
.related-indicators-wrapper .pagination .arrows > div {
  cursor: pointer;
  transition: ease;
}
.related-indicators-wrapper .header .pagination .arrows > div:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.related-indicators-wrapper .header h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 49px;
  text-transform: uppercase;
}
.related-indicators-wrapper .indicatorsCharts-wrapper {
  width: 100%;
  position: relative;
  display: flex;
}
.indicatorsCharts-wrapper .indicatorsCharts-filters {
  position: relative;
  width: 30%;
  height: auto;
  margin-top: -30px;
}
.indicatorsCharts-wrapper .indicatorsCharts-filters .subcategory-filter {
  display: block;
  padding: 14px;
  padding-top: 5px;
  padding-left: 28px;
  padding-bottom: 1px;
  font-weight: 500;
  font-size: 20px;
}
.indicatorsCharts-wrapper .indicatorsCharts-filters section {
  display: flex;
  flex-direction: column;
  height: 400px;
  padding: 20px;
  padding-top: 0px;
}

.related-indicators-wrapper .indicatorsCharts {
  position: relative;
  width: 100%;
  padding: 10px;
}

.pagination-wrapper {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, minMax(150px, 1fr));
  grid-gap: 2px;
  gap: 2px;
  align-content: space-between;
}

/*Search btn*/
.indicator-search-btn {
  padding-right: 13px;
}
.indicator-search-btn .s-input input {
  width: 200px;
  outline: none;
  border: none;
  border: 1px solid #0e9898;
  padding: 8px 15px;
  font-size: 14px;
  border-radius: 20px;
}

.modal-search img {
  overflow: hidden;
  max-height: 250px;
  display: block;
  max-width: 100%;
  margin: auto;
}

.modal-search {
  width: 400%;
  max-height: 300px;
  margin-left: 120px;
  margin-top: -30px;
}
.indicators-checkbox {
  display: flex;
  flex-direction: column;
  width: 240px;
}

.indicator-or {
  display: block;
  font-size: 16px;
  margin-top: 5px;
  font-weight: 400;
}

.no-subcategories-modal {
  display: block;
  padding-left: 10px;
  margin-top: 30px;
}

@media screen and (max-width: 1100px) {
  .pagination-wrapper {
    grid-template-columns: repeat(auto-fit, minMax(150px, 1fr));
    max-height: 280px;
    grid-gap: 20px;
    overflow-y: scroll;
    border: 1px solid #e1e1e169;
  }
  .modal-search {
    width: 100%;
    max-height: 290px;
    margin-left: 2px;
    margin-top: -2px;
  }
  .related-indicators-wrapper {
    height: 400px;
  }
}

@media screen and (max-width: 700px) {
  .pagination-wrapper {
    grid-template-columns: repeat(2, minMax(150px, 1fr));
    max-height: 280px;
    overflow-y: scroll;

    padding-top: 1.5rem !important;
  }
  .pagination-wrapper .indicator-chart-card-wrapper {
    max-width: 230px;
  }

  .related-indicators-wrapper .indicatorsCharts-wrapper {
    flex-direction: column;
  }

  .reset-filt {
    margin-top: 2rem;
    margin-left: 0.6rem;
    display: inline-block;
    padding: 0.2rem 0.4rem;
    background-color: #d9d9d9;
    cursor: pointer;
  }

  .related-indicators-wrapper .indicatorsCharts {
    padding-top: 0;
  }
  /*Search btn*/
  .indicator-search-btn {
    padding-right: 15px;
  }
  .indicator-search-btn .s-input input {
    width: 10px;
    padding: 0 !important;
    font-size: 13px;
    border-radius: 10px;
  }
  .related-indicators-wrapper .header {
    padding-bottom: 10px;
    align-items: center;
    padding-left: 8px;
    background-color: rgb(250 250 250);
    flex-wrap: wrap;
  }
  .related-indicators-wrapper .header span > span.filt {
    display: inline-block;
    padding: 0.2rem 0.4rem;
    background-color: #d9d9d9;
    border-radius: 6px;
  }
  .modal-search {
    width: 100%;
    max-height: 175px;
    margin-left: 152px;
    margin-top: -2px;
  }
  .modal-search img {
    max-height: 150px;
    display: block;
    max-width: 100%;
    margin: auto;
  }
}

@media screen and (max-width: 500px) {
  .pagination-wrapper {
    padding-top: 1.5rem !important;
    display: flex;
    scroll-snap-type: x mandatory;
    grid-gap: 2rem;
    max-height: 280px;
    overflow-y: scroll;
  }
  .pagination-wrapper > div {
    scroll-snap-align: start;
    height: 200px;
    width: 240px;
  }
  .modal-search {
    width: 100%;
    max-height: 175px;
    margin-left: 100px;
    margin-top: -2px;
  }
}
@media screen and (max-width: 420px) and (min-width: 380px) {
  .modal-search {
    margin-left: 80px;
  }
}
@media screen and (max-width: 380px) {
  /* .pagination-wrapper {
    grid-template-columns: repeat(2, minMax(150px, 250px));
    max-height: 280px;
    place-content: center;
    overflow-y: scroll;
  } */
  .modal-search {
    width: 100%;
    max-height: 175px;
    margin-left: 100px;
    margin-top: -2px;
  }
}

.theme-padding-wrapper {
  position: relative;
  width: 100%;
  padding: 3.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin: auto;
}
.theme-padding-wrapper h1 {
  padding: 0;
  margin: 0;
  font-size: 46px;
  line-height: 67px;
  font-weight: 700;
}
@media screen and (max-width: 1281px) {
  .theme-padding-wrapper h1 {
    padding: 0 3.5rem;
  }
}

@media screen and (max-width: 700px) {
  .theme-padding-wrapper h1 {
    padding: 0.2rem 1.5rem;
    font-size: 25px;
  }
}

.theme-section-wrapper {
  position: relative;
  width: 100%;
  padding: 16px 0;
  display: grid;
  grid-template-columns: repeat(5, minmax(60px, 250px));
  grid-gap: 10px 20px;
  justify-content: space-between;
  width: 90vw;
}

.themes-spinner {
  position: relative;
  width: 100%;
  height: 70vh;
  display: grid;
  place-content: center;
}

@media screen and (min-width: 1500px) {
  .top-menu-item .theme-padding-wrapper {
    padding: 0.2rem 10rem;
  }

  .top-menu-item .theme-section-wrapper {
    grid-template-columns: repeat(5, minmax(60px, 400px));
  }
}
@media screen and (min-width: 1900px) {
  .top-menu-item .theme-padding-wrapper {
    padding: 0.2rem 16rem;
  }
}

@media screen and (max-width: 911px) and (min-width: 500px) {
  .theme-section-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(100px, 200px));
  }
}
@media screen and (max-width: 525px) {
  .theme-section-wrapper {
    grid-template-columns: repeat(1, minmax(280px, 250px));
  }
}

/*ERROR CLASSES*/

.theme-error {
  position: relative;
  width: 100%;
  font-size: 30px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 500px) {
  .theme-error {
    padding: 0 0.5rem;
    font-size: 22px;
  }
}
@media screen and (max-width: 1280px) {
  .theme-padding-wrapper {
    padding: 0;
  }
  .theme-section-wrapper {
    max-width: calc(35vw + 300px);
    padding: 30px;
    padding-top: 0;
    grid-template-columns: repeat(3, minmax(60px, 350px));
    justify-content: space-around;
  }
  .theme-btn-responsive {
    padding-left: 30px !important;
  }
}

@media screen and (max-width: 900px) {
  .theme-section-wrapper {
    max-width: 100vw;
    grid-template-columns: repeat(2, minmax(60px, 250px));
  }
  .theme-padding-wrapper {
    padding: 0.5rem 0rem;
  }
  .theme-padding-wrapper .theme-card-text {
  }

  .theme-padding-wrapper .theme-card-text h3 {
    font-size: 12px;
  }
  .theme-padding-wrapper .theme-card-text h1 {
    margin-bottom: 0;
    font-size: 14px;
  }
  .responsive-btn-row {
    justify-content: center;
  }
}

.theme-section-wrapper.desktop {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(60px, 400px));
  grid-gap: 10px 20px;
  justify-content: space-around;
  max-width: 100vw;
  padding: 16px 2.5rem;
}

@media screen and (max-width: 1280px) {
  .theme-section-wrapper.desktop {
    grid-template-columns: repeat(auto-fit, minmax(60px, 280px));
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    justify-content: space-between;
  }
  .report-view-btn.theme-view-btn {
    padding-right: 3.5rem;
  }
}
@media screen and (max-width: 1279px) and (min-width: 961px) {
  .theme-section-wrapper.desktop {
    grid-template-columns: repeat(3, minmax(60px, 330px));
  }
}

@media screen and (min-width: 1280px) {
  .theme-section-wrapper.desktop {
    padding: 0;
    justify-content: space-between;
    grid-template-columns: repeat(4, minmax(60px, 400px));
  }
}

@media screen and (min-width: 1278px) and (max-width: 1282px) {
  .theme-section-wrapper.desktop {
    padding: 0 3.5rem;
  }
}
@media screen and (max-width: 960px) and (min-width: 700px) {
  .theme-section-wrapper.desktop {
    grid-template-columns: repeat(2, minmax(60px, 400px));
  }
}

@media screen and (min-width: 500px) and (max-width: 624px) {
  .theme-section-wrapper.desktop {
    grid-template-columns: repeat(auto-fit, minmax(60px, 200px));
  }
}
@media screen and (max-width: 700px) and (min-width: 500px) {
  .theme-section-wrapper.desktop {
    grid-template-columns: repeat(2, minmax(60px, 400px));
  }
}
@media screen and (max-width: 500px) {
  .theme-section-wrapper.desktop {
    /* grid-template-columns: repeat(2, minmax(60px, 440px)); */
    /* grid-gap: 1rem;
    padding: 1.5rem; */
  }
  .theme-padding-wrapper .theme-card-text {
  }
}

@media screen and (max-width: 700px) {
  .theme-section-wrapper {
    padding: 0 1.5rem;
  }
  .theme-section-wrapper.desktop {
    padding: 1.5rem;
    justify-content: space-between;
    margin: auto;
    overflow: scroll;
    display: grid;
    scroll-snap-type: x mandatory;
    width: 90vw;
    grid-template-columns: repeat(4, 180px);
  }
  .theme-padding-wrapper {
    padding: 0;
  }
  .report-view-btn.theme-view-btn {
    padding: 0 2rem;
    padding-top: 0.7rem;
    padding-bottom: 2rem;
    text-align: end;
  }
}

.key-data-row {
  padding: 0px;
  margin: auto;
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
}
.key-data-col {
  display: grid;
  place-content: center;
  padding: 0.5rem;
}
.key-data-wrapper {
  padding: 3.5rem;
  padding-top: 0;
  padding-bottom: 1rem;
  position: relative;
  width: 100%;
  margin: auto;
}

.key-data-title {
  padding: 0;
  margin: 0;
  font-size: 50px;
  line-height: 67px;
  font-weight: 700;
}

@media screen and (max-width: 1000px) {
  .key-data-row {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 770px) {
  .key-data-title {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 700px) {
  .key-data-wrapper {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .key-data-title {
    padding: 0;
    font-size: 25px;
    line-height: 37px;
    -webkit-transform: translateX(0px);
            transform: translateX(0px);
    padding-bottom: 1rem;
    padding-top: 1rem;
  }
  .key-data-row {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    padding: 0;
  }
  .key-data-col {
    padding: 0;
  }
}
.key-data-row .card-hovered {
  bottom: 17px;
}

@media screen and (min-width: 700px) and (max-width: 770px) {
  .key-data-row .card-hovered {
    bottom: 17px;
  }
  .key-data-row {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    padding: 0;
    grid-row-gap: 70px !important;
    row-gap: 70px !important;
  }
}

section.section-key {
  overflow: scroll;
  overflow-y: hidden;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  width: 90vw;
  margin: auto;
  scroll-snap-type: x mandatory;
  padding: 1.5rem;
  position: relative;
}

section.section-key > article {
  scroll-snap-align: start;
  width: 80vw;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
section.section-key .card-wrapper-res {
  width: 100%;
  margin: auto;
  display: flex;
}
section.section-key article::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 80%;
  top: 10%;
  left: 0;
  background-color: rgba(126, 126, 126, 0.185);
}

section.section-key > article .chart-button:hover {
  color: #0e9898;
}

section.section-key > article .chart-button {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #151515;
  position: absolute;
  bottom: 0;
}

@media screen and (max-width: 700px) {
  section.section-key > article .chart-button {
    font-size: 10px;
  }
  .card-wrapper-res .chart-ratio {
    font-size: 16px;
  }
}

.employment-indicators-wrapper {
  color: #212121;
  padding-left: 60px;
  padding-right: 60px;
  /* background-image: url('./employmentBg.png');
  background-repeat: no-repeat;
  background-size: cover; */
  background-color: rgb(245, 245, 245);
  padding-top: 1rem;
  /* background-position: top; */
  /* min-height: 500px; */
  padding-bottom: 2rem;
  margin-right: 3.5rem;
  margin-left: 3.5rem;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.employment-indicators-wrapper main {
  width: 100%;
  position: relative;
  padding-left: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  grid-gap: 0rem;
}

section h1.text a {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 46px;
  color: black;
}
section h1.text {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 46px;
}
section h1.text a {
  color: #212121;
  text-decoration: none;
  transition: 0.1s ease;
}
section h1.text a:hover {
  color: #004444;
}

@media screen and (max-width: 764px) {
  .employment-indicators-wrapper {
    margin-right: 0rem;
    margin-left: 0rem;
  }
}

.employment-indicators-wrapper .explore-ind {
  position: relative;
  padding: 2rem;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  padding-left: 0;
  letter-spacing: 2.8px;
}
.employment-indicators-wrapper .explore-ind::before {
  content: '';
  position: absolute;
  width: 3px;
  background-color: rgba(211, 211, 211, 0.644);
  height: 90%;
  top: 30px;
  right: 0;
}
.employment-indicators-wrapper .explore-ind span {
  position: relative;
  display: block;
  padding-bottom: 1rem;
  color: #0e9898;
  width: 150px;
}
.employment-indicators-wrapper .explore-ind span::after {
  content: '';
  position: absolute;
  left: 0;
  height: 2px;
  width: 40px;
  bottom: 0;
  background-color: #0e9898;
}
.employment-indicators-wrapper .column {
  display: flex;
}

.employment-indicators-wrapper .text {
  padding-top: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  z-index: 3;
}

.employment-indicators-wrapper .text h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 46px;
}
.employment-indicators-wrapper .text h1 a {
  color: #212121;
  text-decoration: none;
  transition: 0.1s ease;
}
.employment-indicators-wrapper .text h1 a:hover {
  color: #004444;
}
.employment-indicators-wrapper .text h3 {
  color: #212121;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 41px;
  text-transform: none;
}
.employment-indicators-wrapper .indicators-wrapper {
  position: relative;
  width: 80%;
  padding-top: 5px;
  display: grid;
  grid-template-columns: repeat(1, 250px);
}
.employment-indicators-wrapper .indicators-wrapper .indicator {
  color: rgba(0, 0, 0, 0.85);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.15s ease-in;
}

.employment-indicators-wrapper .indicators-wrapper .indicator:hover {
  color: #0e9898;
}
.modules-spinner {
  position: relative;
  width: 100%;
  height: 70vh;
  display: grid;
  place-content: center;
}

/*Indicator section grid*/

@media screen and (max-width: 500px) {
  .employment-indicators-wrapper {
    flex-direction: column;
  }
  .employment-indicators-wrapper main {
    padding-left: 0rem;
  }
  .employment-indicators-wrapper .explore-ind::before {
    top: unset;
    width: 100%;
    height: 3px;
    bottom: 10px;
  }
}

@media screen and (max-width: 960px) {
  .employment-indicators-wrapper .indicators-wrapper {
    width: 80%;
  }
}

@media screen and (max-width: 764px) {
  .employment-indicators-wrapper .indicators-wrapper {
    width: 100%;
    margin: auto;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1215px) {
  .employment-indicators-wrapper .indicators-wrapper {
    width: 80%;
  }
}

/*ADJUST BACKGROUND IMAGE*/

@media screen and (max-width: 800px) {
  .employment-indicators-wrapper {
    background-size: 125% 78%;
    background-position: bottom;
  }
}
@media screen and (max-width: 700px) {
  .employment-indicators-wrapper {
    background-size: contain;
    background-position: bottom;
  }
  .employment-indicators-wrapper .text {
    font-size: 18px;
  }
  section h1.text a {
    font-size: 18px;
  }
}

@media screen and (max-width: 630px) {
  .employment-indicators-wrapper .indicators-wrapper {
    padding-top: 20px;
    grid-template-columns: repeat(1, 300px);
  }
  .employment-indicators-wrapper .text h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 34px;
  }
}

@media screen and (max-width: 700px) {
  .employment-indicators-wrapper {
    padding: 1.5rem;
  }
  .employment-indicators-wrapper .column .explore-ind {
    font-size: 18px;
    line-height: 18px;
    padding-right: 1rem;
  }
  .employment-indicators-wrapper .text h1 {
    font-size: 24px;
    line-height: 31px;
    padding-top: 0.8rem;
  }
}

@media screen and (max-width: 400px) {
  .employment-indicators-wrapper .indicators-wrapper {
    position: relative;
    width: 100%;
    padding-top: 0;
    grid-template-columns: repeat(1, 200px);
  }

  .employment-indicators-wrapper .indicators-wrapper .indicator {
    font-size: 13px;
  }
}

@media screen and (max-width: 700px) {
  .employment-indicators-wrapper main {
    display: flex;
    overflow: scroll;
    scroll-snap-type: x mandatory;
  }

  .employment-indicators-wrapper main section {
    scroll-snap-align: start;
  }
  .employment-indicators-wrapper .explore-ind {
    font-size: 16px;
  }
  .employment-indicators-wrapper .explore-ind span {
    width: 200px;
  }
}

.explore-wrapper {
  position: relative;
  width: 100%;
  padding: 1.5rem;
}

.explore-wrapper p.title {
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 56px;
  text-transform: uppercase;
  color: black;
  text-align: center;
}
.explore-wrapper p.subtitle {
  margin-top: -30px;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: black;
  text-align: center;
}

.explore-wrapper .img-container {
  position: relative;
  width: 100%;
}
.explore-wrapper .img-container img {
  display: block;
  margin: auto;
  max-width: 100%;
  width: 50%;
  height: auto;
}

.explore-wrapper .btn-container {
  text-align: center;
  padding: 30px;
}
.explore-wrapper .btn-container a {
  display: inline-block;
  font-style: normal;
  font-size: 24px;
  line-height: 21px;
  text-transform: uppercase;
  color: black;
  padding: 16px 48px;
  font-weight: 700;
  transition: 0.2s ease-in;
}
.explore-wrapper .btn-container a:hover {
  color: #0e9898;
}

@media screen and (max-width: 700px) {
  .explore-wrapper p.title {
    font-size: 26px;
    line-height: 26px;
    padding-bottom: 12px;
  }
  .explore-wrapper p.subtitle {
    font-size: 15px;
    line-height: 22px;
    padding-top: 20px;
  }
  .explore-wrapper .img-container img {
    width: 100%;
  }
  .explore-wrapper .btn-container a {
    padding: 8px 24px;
    font-size: 13px;
  }
}

.explore-arrow {
  margin-left: 10px;
  -webkit-animation: arrowAnimation 1s ease-in infinite;
          animation: arrowAnimation 1s ease-in infinite;
}

@media screen and (max-width: 700px) {
  .explore-wrapper p.title {
    font-size: 20px;
    line-height: 20px;
  }
}
@-webkit-keyframes arrowAnimation {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
    opacity: 0;
  }
}
@keyframes arrowAnimation {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  to {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
    opacity: 0;
  }
}

@media screen and (max-width: 1000px) {
  .explore-wrapper {
  }
}

.home-reports-wrapper {
  position: relative;
  width: 100%;
  background: #ffffff;
  overflow: hidden;
  padding: 3.5rem;
  padding-top: 0.5rem;
}

.reports-section-wrapper {
  position: relative;
  width: 100%;
  padding: 16px 0;
  display: grid;
  grid-template-columns: repeat(4, minmax(100px, 450px));
  grid-gap: 10px 20px;
  justify-content: flex-start;
}
@media screen and (min-width: 911px) and (max-width: 1200px) {
  .reports-section-wrapper {
    grid-template-columns: repeat(3, minmax(160px, 430px));
  }
}
@media screen and (max-width: 911px) and (min-width: 500px) {
  .reports-section-wrapper {
    grid-template-columns: repeat(2, minmax(100px, 430px));
  }
}
@media screen and (max-width: 525px) {
  .reports-section-wrapper {
    grid-template-columns: repeat(1, minmax(280px, 250px));
  }
}

.home-reports-wrapper h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #151515;
  padding-top: 15px;
}

.home-reports-wrapper p {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #6b6b6b;
  margin-top: -14px;
}
.home-reports-wrapper .main-report {
  position: relative;
  width: 90%;
  margin: auto;
  padding-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-reports-wrapper .main-report .img-container {
  position: relative;
  display: inline-block;
}
.home-reports-wrapper .main-report .img-container img {
  max-width: 100%;
  display: block;
}
.home-reports-wrapper .main-report .absolute-wrapper {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  padding-left: 32px;
  padding-top: 20px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.66) 47.58%, rgba(0, 0, 0, 0.91) 100%);
}
.home-reports-wrapper .main-report .absolute-wrapper .report-title {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: white;
  max-width: 418px;
}
.home-reports-wrapper .main-report .absolute-wrapper .report-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: white;
  padding-top: 16px;
  padding-bottom: 10px;
}
.report-view-btn {
  padding: 10px;
  padding-right: 3px;
  text-align: end;
}

.report-view-btn a {
  position: relative;
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #005757;
  transition: 0.2s ease-in;
  letter-spacing: 0.3px;
}
.report-view-btn a::before {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -4px;
  background-color: rgb(228, 228, 228);
  height: 3px;
}
.report-view-btn a::after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -4px;
  background-color: #2a9898;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: left;
          transform-origin: left;
  height: 3px;
  transition: 0.4s linear;
}
.report-view-btn a:hover {
  color: #2a9898;
}

.report-view-btn a:hover::after {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.reports-first-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reports-message {
  height: 300px;
  display: grid;
  place-content: center;
}
@media screen and (max-width: 460px) {
  .home-reports-wrapper .main-report .absolute-wrapper .report-title {
    font-size: 15px;
    line-height: 20px;
  }
  .home-reports-wrapper .main-report .absolute-wrapper .report-subtitle {
    font-size: 12px;
    line-height: 22px;
    padding-top: 10px;
    padding-bottom: 5px;
  }
}

@media screen and (max-width: 900px) {
  .reports-section-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(200px, 450px));
  }
}

@media screen and (max-width: 700px) {
  .report-view-btn {
    padding-top: 1px;
    padding-right: 2rem;
  }
  .report-view-btn a {
    font-size: 16px;
  }
  .reports-section-wrapper {
    grid-template-columns: repeat(2, minmax(100px, 450px));
    grid-gap: 10px 20px;
    justify-content: flex-start;
  }
  .single-report-wrapper .txt-content .title {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 900px) {
  .home-reports-wrapper {
    padding: 0 1.5rem;
  }
  .home-reports-wrapper h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 900px) and (min-width: 765px) {
  .home-reports-wrapper {
    padding: 0 3.5rem;
  }
}
@media screen and (max-width: 500px) {
  .home-reports-wrapper {
    padding: 0 1.5rem;
  }
  .home-reports-wrapper h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 920px) and (min-width: 700px) {
  .reports-section-wrapper {
    grid-template-columns: repeat(2, minmax(250px, 400px));
  }
}

@media screen and (max-width: 699px) and (min-width: 501px) {
  .reports-section-wrapper {
    grid-template-columns: repeat(2, minmax(200px, 300px));
  }
}

@media screen and (max-width: 700px) {
  .reports-section-wrapper {
    overflow: scroll;
    scroll-snap-type: x mandatory;
    grid-template-columns: repeat(4, minmax(200px, 300px));
    width: 90vw;
    margin: auto;
  }
}

.single-report-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  padding: 0px 0px;
  flex-direction: column;
  max-width: 450px;
  cursor: pointer;
  transition: 0.2s ease-in;
  background-color: rgb(245, 245, 245);
  margin-bottom: 20px;
  box-shadow: 10px -2px 26px -13px rgba(0, 0, 0, 0.38);
  -webkit-box-shadow: 10px -2px 26px -13px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: 10px -2px 26px -13px rgba(0, 0, 0, 0.38);
  scroll-snap-align: start;
}
.single-report-wrapper::after {
  content: '';
  width: 90%;
  margin: auto;
  height: 3px;
  background-color: #2a9898;
  bottom: 3px;
  left: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transform-origin: left;
          transform-origin: left;
  transition: 0.3s ease;
}
.single-report-wrapper:hover {
  -webkit-transform: scale(1.06);
          transform: scale(1.06);
}
.single-report-wrapper:hover::after {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.single-report-wrapper .txt-content .title {
  position: relative;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #151515;
  padding-top: 0.7rem;
  padding-left: 1rem;
}
.single-report-wrapper .txt-content .title:hover > span {
  color: #2a9898;
}
.single-report-wrapper .txt-content .title span:nth-child(2) {
  visibility: hidden;
}
.single-report-wrapper:hover .txt-content .title span:nth-child(2) {
  visibility: visible;
}

.single-report-wrapper .txt-content .subtitle {
  padding-left: 1rem;
  width: 90%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #474747;
  padding-top: 2rem;
}
.single-report-wrapper .txt-content .author {
  padding-left: 1rem;
  width: 90%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #474747;
  padding-top: 5px;
}
.single-report-wrapper img {
  width: 100%;
  display: block;
  margin: auto;
  max-width: 100%;
  object-fit: cover;
  height: 150px;
}

@media screen and (max-width: 560px) and (min-width: 500px) {
  .single-report-wrapper {
    padding: 8px;
  }
  .single-report-wrapper .txt-content .title {
    font-size: 13px;
    line-height: 22px;
  }
  .single-report-wrapper .txt-content .subtitle {
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (max-width: 700px) {
  .single-report-wrapper {
    width: 180px;
  }
}

* {
  font-family: 'Source Sans Pro', sans-serif !important;
}

.home-content {
  background-color: white;
  max-width: 1580px;
  overflow-x: hidden;
}
.home-intro-content {
  padding: 0 3.5rem;
  padding-top: 1.5rem;
}

@media screen and (max-width: 1280px) {
  .home-intro-content {
    padding: 0 3.5rem;
  }
}

@media screen and (max-width: 700px) {
  .home-intro-content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.3rem;
  }
}

.home-intro-content h1 {
  color: #0e9898;
  margin: 0;
}
.home-intro-insights {
  font-size: 20px;
  font-weight: bold;
  line-height: 21px;
  letter-spacing: 2px;
  color: black;
  margin-top: -10px;
}

@media screen and (max-width: 390px) {
  .home-intro-content h1 {
    font-size: 22px !important;
  }
}

@media screen and (max-width: 364px) {
  .home-intro-content h1 {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 610px) and (min-width: 450px) {
  .home-intro-content h1 {
    font-size: 28px;
    line-height: 46px;
    margin-bottom: -10px;
  }
  .home-intro-insights {
    margin-top: 10px;
  }
}

@media screen and (max-width: 450px) {
  .home-intro-content h1 {
    margin-top: -10px;
    font-size: 24px;
    line-height: 36px;
  }

  .home-intro-insights {
    font-size: 14px;
    margin-top: -2px;
    line-height: 1;
    letter-spacing: 0px;
  }
}

@media screen and (max-width: 380px) and (min-width: 350px) {
  .home-intro-content h1 {
    font-size: 19px;
  }
  .home-intro-insights {
    font-size: 12px;
    margin-top: -3px;
  }
}

@media screen and (max-width: 350px) {
  .home-intro-content h1 {
    font-size: 18px;
  }
  .home-intro-insights {
    font-size: 12px;
    margin-top: -6px;
  }
}

/* Admin Dashboard header font styling*/
.ant-table-thead > tr > th {
  font-weight: 600 !important;
  font-size: 17px !important;
}

.admin-table-row {
  font-size: 16px;
}

.ant-modal-body {
  font-size: 16px !important;
}
.ant-modal-body input {
  font-size: 16px;
}

.ant-modal-body .ant-form-item {
  font-size: 16px;
}

img {
  max-inline-size: 100%;
  block-size: auto;
}

.ant-table-wrapper {
  overflow-x: scroll;
}
.ant-table {
  min-width: 1200px;
}

html {
  -webkit-print-color-adjust: exact;
}

.explore-ind-test {
  position: relative;
  padding: 2rem;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 21px;
  font-weight: 700;
  padding-left: 0rem;
  letter-spacing: 2.8px;
}

.explore-ind-test span {
  position: relative;
  display: block;
  padding-bottom: 1rem;
  color: #0e9898;
  width: 300px;
}
.explore-ind-test span::after {
  content: '';
  position: absolute;
  left: 0;
  height: 2px;
  width: 40px;
  bottom: 0;
  background-color: #0e9898;
}

@media screen and (max-width: 700px) {
  .explore-ind-test {
    font-size: 16px;
    padding-left: 1.5rem;
  }
}

@media screen and (max-width: 1280px) {
  .explore-ind-test.theme {
    padding-left: 3.5rem;
  }
}
@media screen and (max-width: 700px) {
  .explore-ind-test.theme {
    padding-left: 1.5rem;
  }
  .explore-ind-test.report {
    padding-left: 0rem;
  }
  .explore-ind-test {
    padding-top: 1rem;
    padding-bottom: 0rem;
  }
}

.home-tabs-container {
  position: relative;
  width: 100%;
  padding: 3.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
}

.home-tabs-container section {
  padding: 12px;
  flex: 0.5 1;
  background: rgb(245, 245, 245);
  font-size: 34px;
  font-weight: 700;
  letter-spacing: 2px;
  color: black;
  transition: ease-in 0.3s;
}

.home-tabs-container section:hover {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  cursor: pointer;
  background: #0e9898;
  color: white;
}

.home-tabs-container section.active {
  background: #0e9898;
  color: white;
}

@media screen and (max-width: 700px) {
  .home-tabs-container {
    padding: 1rem 1.5rem;
  }
  .home-tabs-container section {
    font-size: 24px;
  }
}

@media screen and (max-width: 400px) {
  .home-tabs-container section {
    font-size: 16px;
  }
}

.indicator-content {
  display: flex;
  padding: 0 1rem;
}

.indicator-graphs {
  position: relative;
  background-color: white;
  padding: 60px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  grid-gap: 60px;
  gap: 60px;
  flex: 0.7 1;
}
.indicator-graphs-footer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.indicator-graphs-footer .share {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  width: 200px;
  font-style: normal;
  font-weight: 600;
  justify-content: space-around;
  font-size: 14px;
  line-height: 20px;
  color: #151515;
  display: flex;
  background-color: rgba(255, 255, 255, 0.671);
  padding: 5px;
}
.indicator-graphs-footer .share div img {
  cursor: pointer;
  transition: 0.2s ease-in;
}
.indicator-graphs-footer .share div img:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.indicator-graphs .indicator-chart-section {
  position: relative;
  display: flex;
  flex-direction: column;
  grid-gap: 1.5rem;
  gap: 1.5rem;
}

.indicator-graphs .indicator-chart-section .chart-section-title {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 32px;
  text-transform: uppercase;
  color: #151515;
  padding: 0;
}

.indicator-graphs .indicator-chart-section .chart-section-category {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #151515;
  margin-top: -20px;
}

.indicator-graphs .indicator-chart-section .indicator-chart img {
  max-width: 100%;
  display: block;
  margin: auto;
}
.indicator-related {
  position: relative;
  background-color: rgb(253, 253, 253);
  padding: 25px;
  flex: 0.3 1;
}

.indicator-related .download-data-btn {
  display: block;
  background-color: #0e9898;
  position: relative;
  width: 100%;
  text-align: center;
  color: white;
  padding: 1.5rem;
  cursor: pointer;
  transition: 0.2s ease-in;
  max-width: 400px;
}

.indicator-related .download-data-btn:hover {
  background-color: #000000;
}
.indicator-related .download-data-btn > span {
  font-size: 16px;
  line-height: 24px;
  color: white;
  font-weight: 600;
}

.indicator-title {
  display: block;
  padding-top: 1.5rem;
  position: relative;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 2.45px;
  line-height: 32px;
  text-transform: uppercase;
  color: #0e9898;
}
.indicator-title::after {
  content: '';
  position: absolute;
  width: 20%;
  height: 2px;
  background-color: #0e9898;
  bottom: 0;
  left: 0;
}

.indicator-related-components {
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  gap: 20px;
  padding: 20px;
  padding-left: 0;
  padding-bottom: 60px;
}

.indicator-related-components .indicator-related-card {
  max-height: 150px;
  cursor: pointer;
  padding: 15px;
  display: flex;
  padding-left: 4px;
  cursor: pointer;
  transition: 0.2s ease;
}
.indicator-related-components .indicator-related-card > div:nth-child(2) {
  flex: 0.5 1;
}

.indicator-related-components .indicator-related-card > img {
  max-width: 100%;
  display: block;
  width: 100%;
}
.indicator-related-components .indicator-related-card:hover {
  box-shadow: 2px 9px 35px -11px rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 2px 9px 35px -11px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 2px 9px 35px -11px rgba(0, 0, 0, 0.37);
  box-sizing: border-box;
  cursor: pointer;
}
.indicator-related-components .indicator-related-card .indicator-related-card-title {
  flex: 0.5 1;
  letter-spacing: 1.2px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
  color: #151515;
}

.indicator-related-reports {
  padding: 10px;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  cursor: pointer;
}

.indicator-related-reports .indicator-report {
  display: flex;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
  color: #151515;
  max-height: 150px;
  overflow: hidden;
}

.indicator-related-reports .indicator-report:hover {
  box-shadow: 2px 9px 35px -11px rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 2px 9px 35px -11px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 2px 9px 35px -11px rgba(0, 0, 0, 0.37);
  box-sizing: border-box;
  cursor: pointer;
}
.indicator-related-reports .indicator-report p {
  padding-top: 0.5rem;
  padding-left: 5px;
  flex: 0.5 1;
  letter-spacing: 1.2px;
}
.indicator-related-reports .indicator-report > div {
  padding: 0;
  position: relative;
  flex: 0.5 1;
}
.indicator-related-reports .indicator-report > div > img {
  max-height: 150px;
  object-fit: cover;
  display: block;
  max-width: 100%;
  width: 100%;
}
.indicator-themes {
  display: grid;
  grid-template-columns: repeat(2, minmax(50px, 1fr));
  grid-gap: 20px;
  padding-top: 10px;
  padding-bottom: 30px;
}

h3.target {
  padding-top: 20px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: #151515;
}

p.decent {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  color: #151515;
}

.indicator-filters-wrapper {
  position: relative;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 0px;
  background-color: white;
}
.indicator-filters-wrapper .button-filters {
  position: relative;
  display: flex;
  grid-gap: 15px;
  gap: 15px;
}
.indicator-filters-wrapper .button-filters button {
  display: flex;
  align-items: center;
  grid-gap: 9px;
  gap: 9px;
  padding: 12px 16px;
}
.indicator-filters-wrapper .button-filters button:hover {
  border: 1px solid #0e9898;
}
.indicator-filters-wrapper .button-filters button .btn-filter-title {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #0e9898;
}
.indicator-filters-wrapper .button-filters button .btn-filter-title span {
  text-transform: none;
}
.indicator-tab-filters {
  position: relative;
  width: 100%;
  padding: 0px;
  padding-top: 20px;
  display: flex;
  grid-gap: 30px;
  gap: 30px;
}

.indicator-tab-filters .filters-tabpane {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #6b6b6b;
}
.indicator-tab-filters .filters-tabpane:hover {
  color: #0e9898 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0e9898 !important;
  border: #0e9898 !important ;
}
.ant-tabs-tab {
  border: #0e9898 !important ;
  outline: #0e9898 !important ;
}

.ant-tabs-tab-btn:hover {
  color: #0e9898;
}
.indicator-tab-filters button {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-top: 10px;
  grid-gap: 9px;
  gap: 9px;
  padding: 12px 16px;
}

.indicator-tab-filters button .btn-filter-title {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #0e9898;
}
.indicator-tab-filters button:hover {
  border: 1px solid #0e9898;
}

.map-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 20px;
  gap: 20px;
}

.bar-chart {
  padding-top: 30px;
}

.select-options {
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  flex-wrap: wrap;
}

.select-options .sel-opts {
  position: relative;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
@media screen and (max-width: 370px) {
  .select-options .sel-opts {
    flex-wrap: wrap;
  }
}
/*SPINNER**/
.indicator-page-spinner {
  position: relative;
  width: 100%;
  height: 300px;
  background: white;
  display: grid;
  place-content: center;
}

/*ERROR**/
.indicator-page-error {
  position: relative;
  width: 100%;
  height: 300px;
  background: white;
  display: grid;
  place-content: center;
  color: rgb(0, 0, 0);
}

/*NO data*/

.related-unavailable {
  position: relative;
  width: 100%;
  background-color: white;
  height: 150px;
  display: grid;
  place-content: center;
  font-size: 18px;
}

.indicator-messages {
  height: 300px;
  display: grid;
  place-content: center;
  font-weight: 500;
}

/*NO DATA*/
.indicator-page-no-data {
  padding-top: 3rem;
  position: relative;
  width: 100%;
}
.indicator-page-no-data img {
  display: block;
  max-width: 100%;
  margin: auto;
}
.indicator-filters-wrapper .button-filters .year-filters {
  width: 28rem;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.indicator-filters-wrapper .button-filters .dropdown-button-wrapper {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}
.indicator-filters-wrapper .button-filters .dropdown-button-wrapper .dropdown-filter {
  font-size: 17px;
  color: #0e9898;
  display: grid;
  place-content: center;
}
.indicator-filters-wrapper .button-filters .year-filters .start-year,
.end-year {
  width: 9rem;
}

.indicator-filters-wrapper .button-filters .year-filters .start-year {
  color: #0e9898;
}
.indicator-filters-wrapper .button-filters .year-filters .end-year {
  color: #0e9898;
}

@media screen and (max-width: 1500px) and (min-width: 1200px) {
  /* .indicator-content {
    margin-right: 70px;
  }
  .indicator-graphs {
    flex: 0.5;
  }
  .indicator-related {
    flex: 0.6;
  } */

  /* .indicator-related-components {
    flex-direction: row;
  }
  .indicator-related-components .flexLayout {
    padding: 2rem;
    padding-top: 10px;
  }
  .indicator-related-reports {
    flex-direction: row;
  }
  .indicator-themes {
    grid-template-columns: repeat(3, minmax(50px, 1fr));
  } */
}
@media screen and (max-width: 1200px) and (min-width: 900px) {
  .indicator-related .download-data-btn {
    padding: 0.4rem;
  }
  .indicator-related-reports {
    flex-direction: row;
  }

  .indicator-related-components .flexLayout {
    padding: 2rem;
    padding-top: 10px;
  }
  .indicator-related-components .flexLayout .layout-space {
    grid-gap: 5px;
  }
  .indicator-related-components .card-wrapper {
    height: 200px !important;
  }
  .indicator-related-components .card-wrapper .chart-title {
    font-size: 14px;
  }
  .indicator-related-components .card-wrapper .chart-ratio {
    font-size: 17px;
  }
}

@media screen and (max-width: 1200px) {
  .indicator-graphs {
    padding-right: 1rem;
  }
  .indicator-content {
    flex-direction: column;
  }
  .indicator-related-components {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 500px));
  }
}

@media screen and (max-width: 800px) {
  .indicator-filters-wrapper {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .indicator-filters-wrapper .button-filters button {
    grid-gap: 9px;
    gap: 9px;
    padding: 6px 8px;
    width: 72%;
    max-width: 300px;
  }
  .indicator-filters-wrapper .button-filters .dropdown-button-wrapper {
    flex-wrap: wrap;
  }
  .indicator-filters-wrapper .button-filters {
    grid-gap: 10px;
    gap: 10px;
    display: grid;
  }
  .indicator-content {
    padding: 0 1.5rem;
  }

  .indicator-graphs {
    padding-left: 0rem;
    padding-right: 0rem;
    flex: 1 1;
  }
  .indicator-related {
    padding: 0;
  }
  .indicator-related-components {
    padding: 0;
    padding-top: 1.5rem;
    grid-template-columns: repeat(1, minmax(200px, 700px));
  }
  .indicator-related-components .chart-card-wrapper.larger {
    max-width: 800px;
  }
  .indicator-related-components .chart-card-wrapper .chart-button-present {
    position: absolute;
    margin-top: 129px;
  }
  .indicator-related .download-data-btn {
    padding: 1rem;
  }
  .indicator-related-reports {
    display: grid;
    grid-template-columns: repeat(1, minmax(300px, 1fr));
  }
  .indicator-related-reports .single-report-wrapper {
    max-width: 700px;
  }
}
.indicator-related-components .chart-card-wrapper .chart-button-present {
  position: absolute;
  margin-top: 129px;
}
@media screen and (max-width: 500px) {
  .indicator-filters-wrapper .button-filters .dropdown-button-wrapper {
    display: grid;
  }
}

@media screen and (max-width: 390px) {
  .indicator-filters-wrapper {
    padding: 0 1.5rem;
  }
  .indicator-filters-wrapper .button-filters {
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: auto;
  }
  .indicator-filters-wrapper .button-filters button {
    grid-gap: 6px;
    gap: 6px;
    width: 75%;
    padding: 3px 4px;
  }
  .indicator-filters-wrapper .year-filters {
    display: flex;
  }
}

.download-csv-btn-map {
  position: relative;
  transition: 0.1s ease-in;
  cursor: pointer;
}

.download-csv-btn-map:hover {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

.report-filters {
  flex: 0.2 1;
  position: relative;
  width: 100%;
  /* background: hsla(240, 20%, 98%, 0.356); */
  height: 800px;
  padding-bottom: 12px;
}

span.filter-drawer {
  cursor: pointer;
  font-weight: 600;
  display: inline-flex;
  background-color: #d9d9d9;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0.6rem;
}
span.filter-drawer span:nth-child(1) {
  margin-right: 5px;
}

@media screen and (max-width: 1280px) {
  .report-filters {
    height: auto;
  }
}

.report-filters .filters-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #151515;
  padding: 1rem;
  padding-left: 0;
  padding-top: 0.2rem;
}

.report-filters .filter-area {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 30px;
  gap: 30px;
}
.report-filters .filter-area .filter-comp {
  position: relative;
  width: 100%;
}
.report-filters .filter-area .filter-category {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;
  color: #151515;
  padding-bottom: 12px;
  margin-top: 20px !important;
}

.report-filters .filter-area .filter-category::first-letter {
  text-transform: uppercase;
}
.report-filters .filter-area .filter-checkbox {
  font-weight: 700;
  padding-top: 10px;
}
.report-filters .filter-area .filter-checkbox span input[type='checkbox'] {
  padding: 30px;
}
.report-filters .filter-area .checkboxes {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.report-filters .filter-area .checkboxes .checkbox-value {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #151515;
}
.report-filters .filter-area .checkboxes .checkbox-value::first-letter {
  text-transform: uppercase;
}
@media screen and (max-width: 750px) {
  .report-filters .filter-area {
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
  }
  .report-filters {
    padding: 10px;
  }
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0e9898 !important;
  border-color: #0e9898 !important;
}

.filter-label-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 8px;
  margin: 10px;
  background-color: #0e9898 !important;
  border-color: #0e9898 !important;
  border-radius: 30px;
}

.filter-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: white;
}

.filter-remove-icon {
  margin-left: 10px;
}
.ant-checkbox .ant-checkbox-inner:hover {
  border-color: #0e9898 !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #0e9898 !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #0e9898 !important;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #0e9898 !important;
}

.filter-sort {
  position: relative;
  margin-left: auto;
  float: right;
}
.filter-sort > .comp {
  position: relative;
}

.report-filters .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 1rem 0;
}

.reports-container {
  position: relative;
  width: 100%;
  background-color: white;
  color: black;
  padding: 20px 60px;
  padding-bottom: 2px;
  padding-top: 1.5rem;
}

.reports-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  padding: 61px 65px;
  padding-bottom: 10px;
}

.reports-wrapper .header {
  width: 100%;
  position: relative;
}

.reports-container > h1.title {
  font-size: 48px;
  font-weight: 700;
  line-height: 57.6px;
  color: rgb(33, 33, 33);
}
.reports-content {
  position: relative;
  width: 100%;
  padding: 2px 60px;
  display: flex;
}

.reports-content > .reports {
  flex: 0.8 1;
}

/*Filters*/

.done-filter {
  padding-top: 0.7rem;
  display: flex;
  padding-left: 0.7rem;
  transition: 0.1s ease;
  justify-content: space-between;
  width: 70%;
  max-width: 200px;
}
.done-filter span {
  cursor: pointer;
  color: black;
  background-color: #d9d9d9;
  padding: 0.4rem 1rem;
}

@media screen and (max-width: 1024px) {
  .reports-content > .reports {
    flex: 1 1;
  }
}

@media screen and (max-width: 700px) {
  .reports-content {
    padding: 2px 1.5rem;
  }
}

.publication-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding-left: 5px;
  text-align: start;
  padding: 0 1rem;
}

.publication-date {
  font-size: 12.8px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.6px;
  margin-top: 20px;
  text-align: start;
  padding-left: 5px;
  width: 100%;
  color: #424242;
  padding: 0 1rem;
  text-transform: uppercase;
}

.publication-date:hover,
.publication-title:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: rgba(10, 105, 194, 0.25);
          text-decoration-color: rgba(10, 105, 194, 0.25);
}

.reports-publications {
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 370px));
  grid-gap: 2rem;
  padding-top: 35px;
  flex: 0.8 1;
  position: relative;
  width: 100%;
  padding-bottom: 70px;
}

@media screen and (min-width: 1500px) {
  .reports-publications {
    grid-template-columns: repeat(auto-fill, 1fr);
  }
}

@media screen and (max-width: 1500px) and (min-width: 1201px) {
  .reports-publications {
    padding-right: 0;
    grid-template-columns: repeat(3, minmax(200px, 400px));
  }
}

@media screen and (max-width: 1200px) and (min-width: 700px) {
  .reports-publications {
    padding: 0;
    padding-top: 3rem;
    grid-template-columns: repeat(2, minmax(200px, 450px));
  }
}

@media screen and (max-width: 700px) and (min-width: 500px) {
  .reports-publications {
    padding: 0;
    padding-top: 3rem;
    grid-template-columns: repeat(2, minmax(200px, 360px));
  }
}

@media screen and (max-width: 500px) {
  .reports-publications {
    padding: 0;
    padding-top: 3rem;
    grid-template-columns: repeat(1, minmax(200px, 500px));
  }
}

.reports-container > p.subtitle {
  color: #626161;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  margin-top: -20px;
}

.ant-card-grid {
  float: left;
  width: auto !important;
  min-height: 300px;
  max-height: 300px;
  min-width: 160px;
  max-width: 160px;
  padding: 0 !important;
  margin: 12px;
  margin-top: 30px;
  text-align: center;
  border: none;
  box-shadow: none !important;
  transition: all 0.3s;
}

.ant-image {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}
.publications-pagination {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
}

.publications-pagination .ant-pagination-item-active {
  font-weight: 500;
  font-size: medium !important;
  width: 30px !important;
  height: 30px !important;
  background: #fff;
  border: 1px solid #666666 !important;
  color: #666666 !important;
}

.publications-pagination .ant-pagination-item {
  font-weight: 500;
  font-size: medium !important;
  width: 30px !important;
  height: 30px !important;

  background: #fff;
  border-radius: 0px !important;
  border: 1px solid #666666 !important;
  color: #666666 !important;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  border: 1px solid #eeeeee !important;
  color: #eeeeee !important;
  cursor: not-allowed;
}

.publications-pagination .ant-pagination-prev,
.publications-pagination .ant-pagination-next,
.publications-pagination .ant-pagination-jump-prev,
.publications-pagination .ant-pagination-jump-next {
  display: inline-block;
  width: 30px !important;
  height: 30px !important;
  font-size: medium;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border: 1px solid #d9d9d9c4;
  border-radius: 0px !important;
  cursor: pointer;
  transition: all 0.3s;
}

@media screen and (max-width: 750px) {
  .reports-heading > h1.title {
    font-size: 35px;
  }
}

@media screen and (max-width: 750px) {
  .reports-wrapper {
    flex-direction: column;
  }
}

.screen-reports {
  position: relative;
  width: 100%;
  flex: 0.8 1;
  display: flex;
  flex-direction: column;
}

.publication-card {
  height: 280px;
  cursor: pointer;
  transition: 0.1s ease-in;
  position: relative;
  box-shadow: 10px 10px 20px -3px rgba(0, 0, 0, 0.34);
  -webkit-box-shadow: 10px 10px 20px -3px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 10px 10px 20px -3px rgba(0, 0, 0, 0.34);
}

.publication-card:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.noReports-image {
  padding: 3rem;
  position: relative;
  width: 100%;
  text-align: center;
}
.noReports-image img {
  display: block;
  max-width: 50%;
  margin: auto;
}

.filter-sort {
  width: 100%;
  padding-left: 1px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (min-width: 1024px) {
  .filter-sort {
    justify-content: flex-end;
  }
}
@media screen and (max-width: 390px) {
  .filter-sort {
    flex-direction: column;
    grid-gap: 2rem;
    gap: 2rem;
    align-items: flex-start;
  }
}

.subcategory-wrapper {
  position: relative;
  width: 100%;
  padding: 0.7rem 4rem;
  background: #fff;
}
.noIndicator-error {
  display: block;
  text-align: center;
  font-size: 25px;
  line-height: 32px;
}

.subcategory-indicator-cards {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 999px) and (min-width: 700px) {
  .subcategory-indicator-cards {
    padding-left: 60px;
    display: grid;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-row-gap: 2rem;
    row-gap: 2rem;
    grid-template-columns: repeat(2, minmax(200px, 600px));
  }
}

@media screen and (max-width: 700px) {
  .subcategory-wrapper {
    padding: 15px 1.5rem;
  }

  .subcategory-indicator-cards {
    display: grid;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    grid-row-gap: 2rem;
    row-gap: 2rem;
    grid-template-columns: repeat(1, minmax(200px, 600px));
  }
}

.subcategory-indicator-cards-res {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  grid-gap: 20px;
  gap: 20px;
  width: 90vw;
  margin: auto;
  scroll-snap-type: x mandatory;
  padding: 1.5rem;
  position: relative;
}

.subcategory-indicator-cards-res article {
  scroll-snap-align: start;
  width: 80vw;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 150px;
}

.subcategory-indicator-cards-res article > div {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.subcategory-indicator-cards-res .chart-button {
  display: none;
}

.theme-page {
  position: relative;
  width: 100%;
  background-color: white;
}

.theme-subheader-wrapper {
  position: relative;
  width: 100%;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding-bottom: 2rem;
  padding-top: 1.5rem;
}
.theme-subheader-wrapper.ness {
  height: auto;
}
/*ANT DESIGN BREADCRUMB CUSTOMIZATION*/
.theme-subheader-wrapper .ant-breadcrumb {
  text-transform: uppercase;
  color: rgb(0, 0, 0);
  font-style: normal;
  line-height: 20px;
  padding: 10px 60px !important;
  position: relative;
  width: 100%;
  font-weight: 700;
  letter-spacing: 2.8px;
  font-size: 14px !important;
  line-height: 20px;
}

.theme-subheader-wrapper span span.ant-breadcrumb-link a:hover {
  color: #0e9898;
}

.theme-subheader-wrapper .ant-breadcrumb a:hover {
  color: #0e9898;
}

.theme-subheader-wrapper .ant-breadcrumb > span:last-child {
  font-weight: normal;
  color: rgb(0, 0, 0);
}

.theme-subheader-wrapper .ant-breadcrumb > span:last-child a {
  font-weight: 400;
  color: rgb(0, 0, 0);
}

.theme-subheader-wrapper .ant-breadcrumb-separator {
  color: rgb(0, 0, 0);
  opacity: 0.6;
}
.theme-subheader-wrapper span.ant-breadcrumb-link a {
  color: rgb(0, 0, 0);
}
.theme-subheader-wrapper span.ant-breadcrumb-link a:hover {
  color: rgb(0, 0, 0);
}

.theme-subheader-wrapper .ant-breadcrumb a:hover {
  color: rgb(0, 0, 0);
}

.theme-subheader-wrapper .theme-subheader-title {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 75px;
  width: 60%;
  padding-left: 60px;
}

@media screen and (max-width: 700px) {
  .theme-subheader-wrapper {
    padding-bottom: 0.5rem;
  }
  .theme-subheader-wrapper .theme-subheader-title {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    font-size: 40px;
    line-height: 65px;
  }
  .theme-subheader-wrapper .ant-breadcrumb {
    padding-left: 1.5rem !important;
  }
}

.theme-subheader-wrapper .theme-subheader-content {
  color: #626161;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  padding: 0 60px;
  width: 100%;
}

/*IFRAME COMPONENT STYLES*/
.theme-iframe {
  position: relative;
  width: 100%;
  padding: 68px;
  padding-top: 1rem;
  background-color: white;
}

.theme-iframe iframe {
  position: relative;
  width: 100%;
  min-height: 600px;
  border: 1px solid #c2c2c2;
}

.theme-iframe .iframe-footer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 35px 0;
}

.theme-iframe .iframe-footer ul {
  position: relative;
  width: 100%;
  list-style-type: none;
  padding: 0;
}
.theme-iframe .iframe-footer ul li {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 17px;
  color: #212121;
  margin-bottom: 5px;
}
.theme-iframe .iframe-footer ul li span {
  color: #212121;
  font-weight: 600;
}
/*SPINNER*/
.theme-page-spinner {
  width: 100%;
  position: relative;
  height: 500px;
  display: grid;
  place-content: center;
  background-color: white;
  font-size: 17px;
}

/*RESPOSNIVENESS*/

@media screen and (max-width: 970px) {
  .theme-subheader-wrapper {
    height: auto;
  }
  .theme-subheader-wrapper .theme-subheader-title {
    width: 80%;
  }
  .theme-subheader-wrapper .theme-subheader-content {
    width: 80%;
  }
}

@media screen and (max-width: 560px) {
  .theme-subheader-wrapper .theme-subheader-title {
    width: 100%;
    font-size: 34px;
    line-height: 45px;
  }
  .theme-subheader-wrapper .theme-subheader-content {
    width: 100%;
    font-size: 20px;
    line-height: 28px;
  }
}

@media screen and (max-width: 700px) {
  .theme-subheader-wrapper .theme-subheader-content {
    padding: 0 1.5rem;
    font-size: 18px;
  }
  .theme-iframe {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

/*CUSTOM THEMES*/

section.ness-content {
  padding: 30px;
  padding-top: 0px;
  padding-bottom: 15px;
}

.ness-info {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: end;
}
.ness-info .ant-select-arrow {
  margin-top: -6px !important;
}
.ness-info > div {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.ness-info > div > main {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  font-weight: 600;
}
.ness-info > div > section {
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}

.ness-info > div > section a {
  display: block;
  background-color: black;
  text-decoration: none;
  color: white;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 3px;
  transition: 0.1s ease;
}
.ness-info > div > section a:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.theme-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: black;
}

.theme-row .status {
  width: 40px;
  height: 40px;

  border-radius: 50%;
}
.theme-row .status.positive {
  background-color: green;
}
.theme-row .status.negative {
  background-color: rgb(255, 0, 0);
}

.theme-row .status.neutral {
  background-color: rgb(184, 184, 184);
}

.theme-row .status.no-change {
  background-color: #ffc400;
}
.theme-info-row > div {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 16px;
}

/*NESS TABLE*/

.ness-table {
  position: relative;
  font-size: 16px;
}

.ness-table .ness-actions {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  padding-bottom: 10px;
}
.ness-table .ness-actions section {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  color: white;
}
.ness-table .ness-actions section a {
  background-color: black;
  color: white;
  display: inline-block;
  padding: 1px 5px;
  border-radius: 10px;
  font-size: 10px;
  transition: ease-in 0.1s;
}
.ness-table .ness-actions section a:hover {
  -webkit-transform: scale(1.09);
          transform: scale(1.09);
}

.ness-col-group {
  display: flex;
  flex-direction: column;
}

.ness-col-name {
  width: 30%;
  font-size: 14px;
  padding: 5px;
  font-weight: 500;
}

.ness-col-data {
  width: 70%;
  font-size: 14px;
  padding: 5px;
}

.ant-table-cell.ness-col-name {
  padding: 2px;
  padding-left: 6px;
  border: 0.6px solid rgba(0, 0, 0, 0.582);
}
.ant-table-cell.ness-col-name:hover {
  background-color: red;
}

.ness-table tbody > tr:hover > td {
  background-color: unset !important;
  color: unset !important;
}

.ant-table-cell.ness-col-data {
  border: 0.6px solid rgba(0, 0, 0, 0.582);
  padding: 2px;
  padding-left: 10px;
}

.ness-container {
  position: relative;
  overflow-x: scroll;
  padding: 0 30px;
}

.ness-container main {
  min-width: 1380px;
}

@media screen and (max-width: 1400px) {
  .ness-container {
    width: 95vw;
    margin: auto;
    border: 1px solid rgb(235, 235, 235);
  }
}

@media screen and (max-width: 700px) {
  .ness-container {
    position: relative;
    overflow-x: scroll;
    padding: 0 1.5rem;
    padding-left: 1rem;
  }
}

@media screen and (max-width: 1000px) {
  section.ness-content {
    padding-right: 24px;
  }
}
@media screen and (max-width: 768px) {
  section.ness-content {
    padding-right: 20px;
  }
}
@media screen and (max-width: 568px) {
  section.ness-content {
    padding-right: 16px;
  }
}
@media screen and (max-width: 400px) {
  section.ness-content {
    padding-right: 10px;
  }
}

.ness-download-csv {
  position: relative;
  transition: 0.2s ease-in;
}
.ness-download-csv:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.glance-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 60px;
}

.subcategory-data {
  padding: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
  width: 90%;
  margin: auto;
  min-width: 1280px;
  overflow-x: scroll;
}

.subcategory-data .subcategory-name {
  font-size: 20px;
  font-weight: 600;
}

.subcategory-data .line {
  width: 100%;
  margin: auto;
  height: 2px;
  background-color: rgb(0, 0, 0);
}

.data-info-row {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.data-row {
  display: flex;
  width: 60%;
  justify-content: space-around;
}

.data-row .data-info {
  display: flex;
  justify-content: space-around;
}

/*Graph info*/

.glance-info {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: end;
  padding-right: 4rem;
  padding-bottom: 0;
}
.glance-info > div {
  display: flex;
  padding: 12px;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 0;
}

.glance-info .theme-category {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.glance-info .comparison-dates {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-gap: 30px;
  gap: 30px;
}
.glance-info .comparison-dates > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.glance-info .comparison-dates > div span:nth-child(1) {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
}
.glance-info .ant-select-arrow {
  margin-top: -6px !important;
  color: white !important;
}
.glance-info .ant-select-selector {
  border-radius: 4px !important;
  background-color: #27323e !important;
  color: white;
}
.glance-info .theme-category span:nth-child(1) {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
}
.csv-down-glance {
  position: relative;
  transition: 0.2s ease-in;
}

.csv-down-glance:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

/* .glance-info .theme-category span:nth-child(2) {
  text-transform: uppercase;

  padding: 5px;
  font-weight: 500;
  border-radius: 5px;
} */
/*TABLE STYLING*/

.change-col {
  /* background-color: #27323e; */
  color: white;
  text-align: center;
}

.glance-table {
  pointer-events: none;
  padding-top: 20px;
  position: relative;
  width: 100%;
  text-align: center;
}
.glance-table thead > tr > th {
  background-color: unset;
  color: #8b8a8a;
}
.glance-table thead > tr > th:last-child {
  /* background-color: #27323e; */
  color: #27323e;
  border-bottom: none;
}
.glance-table tbody > tr > td {
  padding: 10px;
  text-align: center;
  border-bottom: none;
}
.glance-table tbody > tr > td:first-child {
  text-align: start;
  border-bottom: none;
}

.glance-table tbody > tr > td:last-child {
  text-align: center;
  border-bottom: none;
}

.glance-col {
  border-bottom: 0px !important;

  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
}

.glance-col-name {
  padding-left: 40px;
  min-width: 300px;
  max-width: 300px;
  word-break: break-all;
}

.glance-col-change {
  text-align: center;
  padding: 0;
  margin: 0;
}
.glance-col-change .negative {
  color: red;
}
.glance-col-change .positive {
  color: rgb(14, 152, 152);
}

.glance-col-current {
  text-align: end;
  font-weight: bold;
}
.glance-table tbody > tr:hover > td {
  background-color: unset !important;
  color: unset !important;
}
.glance-table tbody > tr:hover > .glance-col-change {
  background-color: unset !important;
  color: unset !important;
}
.glance-table .ant-table-tbody > tr:hover > td {
  background-color: unset !important;
  color: unset !important;
}

/*GLANCE SUBHEADER*/

.glance-subheader {
  position: relative;
  background-color: #ffffff;
  padding: 60px;
  padding-top: 1.5rem;
  padding-bottom: 1px;
}
.glance-subheader h1 {
  font-weight: 700;
  color: rgb(0, 0, 0);
  line-height: 40px;
  font-size: 48px;
  padding-top: 1.5rem;
}

.glance-subheader .ant-breadcrumb-separator {
  margin: 0 8px;
  color: rgb(0, 0, 0);
}

.glance-table .ant-table-thead > tr > th {
  padding: 0 20px !important;
}
.glance-table tbody > tr > td:hover {
  color: unset !important  ;
  background-color: unset !important;
}

.glance-container {
  position: relative;
  width: 100%;
  overflow-x: scroll;
}

@media screen and (max-width: 1420px) {
  .glance-info {
    margin: auto;
    width: 92%;
    padding: 0;
  }
  .glance-info > div {
    padding-right: 0;
  }
}
@media screen and (min-width: 1351px) and (max-width: 1419px) {
  .glance-info {
    width: 100%;
    padding-right: calc(3vw + 10px);
  }
}
@media screen and (min-width: 1311px) and (max-width: 1350px) {
  .glance-info {
    width: 100%;
    padding-right: calc(2vw + 10px);
  }
}

@media screen and (min-width: 1281px) and (max-width: 1310px) {
  .glance-info {
    width: 100%;
    padding-right: 1rem;
  }
}

@media screen and (max-width: 1280px) {
  .glance-container {
    width: 90%;
    margin: auto;
  }
  .glance-info {
    position: relative;
  }
  .glance-info > div {
    padding-right: 0;
  }
}

@media screen and (max-width: 500px) {
  .glance-wrapper .theme-subheader-title {
    font-size: 30px;
  }
}

.ant-select-dropdown-menu-item-group-title {
  color: #666;
  font-weight: bold;
  font-size: x-large;
}
.certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
  border-bottom: 1px solid #f6f6f6;
}
.certain-category-search-dropdown .ant-select-dropdown-menu-item {
  padding-left: 16px;
}
.certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
  text-align: center;
  cursor: default;
}
.certain-category-search-dropdown .ant-select-dropdown-menu {
  height: auto;
  min-height: -webkit-min-content;
  min-height: min-content;
}
.edt-url {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6px;
}
.edt-all-result {
  padding: 0 8px;
  display: block;
}
.edt-url > div:nth-child(2) {
  color: rgba(97, 97, 97, 0.65);
}
.edt-url > div:nth-child(1) {
  flex: 0.9 1;
  position: relative;
  text-overflow: ellipsis;
  height: auto;
}
.edt-url > div > .title {
  padding: 0 2px;
  font-weight: 700;
  font-size: 14px;
}
.edt-url > div > .description {
  word-break: break-all;
  position: relative;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  padding: 0 2px;
}

.edt-url > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.certain-category-search-dropdown .ant-select-item-option-content {
  white-space: pre-wrap !important;
}
.ant-select-item:not():first-child {
  margin-top: 12px !important;
  padding: 0 16px !important;
}

@media screen and (max-width: 500px) {
  .edt-url > div > .title {
    padding: 0px;
    font-size: 13px;
  }
  .edt-url > div > .description {
    padding: 0;
  }
  .edt-all-result {
    padding: 0 6px;
  }
}

.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.logo {
  float: left;
  width: 120px;
  height: 70px;
  margin: 16px 24px 16px 0;
  display: flex;
  align-items: center;
}

.menu {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 50px;
  height: 100px;
  display: flex;
  align-items: center;
  float: right;
  text-transform: uppercase;
  color: #282828;
}

.right-menu {
  float: right;
  color: #282828;
}

.pic {
  position: absolute;
  width: 43.22px;
  height: 70px;
  left: 60px;
  top: 24px;
  mix-blend-mode: multiply;
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.ant-menu-overflow-item.avatar-menu-item::after {
  border: none !important;
}

.borderless::after {
  opacity: 0;
}

.col-left-menu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-gap: 20px;
  gap: 20px;
  margin-top: -15px;
  padding-right: 12px;
}

.col-left-menu .menu-dropdowns {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.header-dropdown {
  display: block;
  cursor: pointer;
  font-size: 30px;
  margin-top: 10px;
}

.search-icon:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.header-icons {
  display: flex;
}

.header-category {
  position: relative;

  font-weight: 700;
  letter-spacing: 2.8px;
  text-transform: uppercase;
  padding-bottom: 12px;
}
.header-category::before {
  content: '';
  position: absolute;
  bottom: 0px;
  height: 2px;
  width: 50px;
}

/**HEADER SUBMENU*/

.data-submenu {
  position: relative;
  width: 100%;
  display: flex;
  height: 300px;
  margin: auto;
}

.data-submenu .data-by-topic {
  padding-top: 1rem;
  position: relative;
  flex: 0.15 1;
  font-size: 16px;
  max-width: 170px;
  text-transform: uppercase;
  font-weight: 700;
  padding-right: 1rem;
  color: #0e9898;
  line-height: 21px;
  letter-spacing: 2.45px;
  padding-bottom: 1rem;
}
.data-submenu .data-by-topic:nth-child(1) {
  padding-bottom: 0.5rem;
}
.data-submenu .data-by-topic:nth-child(2) {
  padding-top: 0.5rem;
}

.data-submenu .data-submenu-menus {
  position: relative;
}

.data-submenu .data-submenu-menus > div {
  cursor: pointer;
}

.data-submenu .data-submenu-menus > div > p:hover {
  color: #004040;
}
.data-submenu .data-submenu-menus::after {
  content: '';
  right: 0;
  top: 0;
  width: 3px;
  height: 80%;
  background-color: #e9e9e9b0;
  position: absolute;
}

.data-submenu .data-submenu-menus::before {
  content: '';
  right: 0;
  top: 0;
  width: 3px;
  height: 15%;
  background-color: #0e9898;
  position: absolute;
  z-index: 3;
}
.data-submenu .data-submenu-menus.institution::before {
  top: 54px;
}
.data-submenu .submenu-data-list {
  padding: 2rem;
  padding-left: 3rem;
  padding-top: 0;
  font-weight: 100;
}
.data-submenu .submenu-data-list .category-title {
  display: block;
  padding: 1rem;
  padding-left: 0;
  position: relative;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 2.45px;
  font-weight: 700;
  text-transform: uppercase;
  color: #0e9898;
  transition: 0.2s ease-in;
}

.data-submenu .submenu-data-list .category-title:hover {
  color: #004444;
}
.data-submenu .submenu-data-list .category-title::before {
  content: '';
  bottom: 0;
  width: 30px;
  height: 2px;
  background-color: #0e9898;
  position: absolute;
  left: 0;
}

.data-submenu .submenu-data-list .submodule-list {
  position: relative;
  display: flex;
  flex-direction: column;
}
.data-submenu .submenu-data-list .submodule-list > li {
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 600;
  margin-top: 1rem;
  letter-spacing: 1.3px;
}
.data-submenu .submenu-data-list .submodule-list > li:hover {
  color: #0e9898;
}
.data-submenu .header-inst {
  position: relative;
}
.data-submenu .header-inst {
  position: relative;
  padding: 3.5rem;
  padding-top: 1rem;
  padding-left: 3rem;
  width: 100%;
  grid-template-columns: repeat(5, 200px);
}
.data-submenu .header-inst > p {
  display: inline-block;
  position: relative;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 2.45px;
  font-weight: 700;
  color: #0e9898;
  text-transform: uppercase;
  padding: 1rem;
  padding-left: 0;
  padding-top: 0;
}
.data-submenu .header-inst > p::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 20%;
  left: 0;
  height: 2px;
  background-color: #0e9898;
}

.data-submenu .header-inst-list {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 1rem;
}

.data-submenu .header-inst-list a {
  font-size: 14px;
  font-weight: 600;
  transition: 0.1s ease-in;
}

.data-submenu .header-inst-list a:hover {
  color: #0e9898;
}
.data-submenu .header-inst-list a:hover {
}

.data-submenu .header-inst-list a span:nth-child(1) {
}

.submenu-theme {
  position: relative;
  transition: 0.1s ease-in;
}
.submenu-theme .theme-link {
  display: block;
}
.submenu-theme .theme-link p {
  font-weight: 700;
  letter-spacing: 1.8px;
}
.submenu-theme .theme-link picture img {
  background-color: rgb(128, 0, 255);
}
.submenu-theme:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

/*Themes*/

.row-header-wrapper {
  max-width: 1800px;
  margin: auto;
}

/*RESPONSIVENESS*/
.mobile-nav {
  display: none;
  background-color: white;
  width: 120%;
  -webkit-transform: translateX(-16px);
          transform: translateX(-16px);
  height: 100vh;
  position: fixed;
  z-index: 2000;
  top: 0;
}

.mobile-nav-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  height: 100vh;
  flex-direction: column;
}
.mobile-nav-wrapper .links {
  flex: 0.27 1;
  position: relative;
  width: 100%;
  background-color: rebeccapurple;
}
.mobile-nav-wrapper .content {
  flex: 0.73 1;
  position: relative;
  width: 100%;
  background-color: #0e9898;
}

.tab {
  height: 100vh;
  width: 100%;
  display: flex;
}
.mobile-nav-wrapper .ant-tabs-left > .ant-tabs-content-holder {
  border-left: none !important;
}
.mobile-nav-wrapper .ant-tabs-nav {
  flex: 0.27 1 !important;
}
.mobile-nav-wrapper .ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar {
  left: 0;
  background-color: #0e9898 !important;
  width: 4px;
}

.mobile-nav .tab-content {
  -webkit-animation: animate 0.25s ease-in;
          animation: animate 0.25s ease-in;
  height: 100vh;
}

@-webkit-keyframes animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mobile-nav-wrapper .ant-tabs-tab {
  display: block !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: 0 !important;
}

.mobile-nav-wrapper .ant-tabs-tab.ant-tabs-tab-active {
  background-color: aliceblue;
}
.mobile-nav-wrapper .ant-tabs-tab::before {
  top: 0;
  content: '';
  position: absolute;
  left: 0;
  height: 180%;
  width: 4px;
  background-color: rgb(0, 0, 0);
}
.mobile-nav-wrapper .tabcontent {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 4px 20px;
  justify-content: space-between;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  max-width: 100vw;
}
.mobile-nav-wrapper .mobile-logo {
  padding: 20px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100vw;
}
.mobile-nav-wrapper .mobile-logo img {
  max-width: 100%;
  display: block;
  width: 200px;
  height: 70px;
}
@media screen and (max-width: 400px) {
  .mobile-nav-wrapper .mobile-logo img {
    width: 150px;
    height: 50px;
  }
}
.mobile-nav-wrapper .ant-tabs-tab:nth-last-child(2) {
  overflow: hidden;
}

.mobile-nav-wrapper .ant-tabs-content-holder {
  flex: 0.73 1;
}

.ant-drawer-close {
  left: 0 !important;
  right: unset !important;
  position: relative !important;
}
.ant-drawer-body {
  padding-top: 0px !important;
}

/*TRANSITION ANT DRAWER*/
.ant-drawer > * {
  transition: box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.7s ease !important;
  transition: transform 0.7s ease, box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1) !important;
  transition: transform 0.7s ease, box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), -webkit-transform 0.7s ease !important;
}

@media screen and (max-width: 1280px) {
  .desktop-nav.active {
    display: none;
  }
  .mobile-nav.active {
    display: block;
    color: black;
  }
}

/**DATA SUBMENU RESPONSIVENESS*/

@media screen and (max-width: 1280px) {
  .data-submenu {
    flex-direction: column;
    padding: 0;
    max-width: 600px;
    width: 80%;
    margin: unset;
    padding-top: 0;
    padding-left: 0;
  }
  .data-submenu a {
    color: black;
    font-size: 15px;
  }
  .data-submenu .data-by-topic::after,
  .data-by-topic::before {
    display: none;
  }

  .data-submenu .submenu-data-list {
    padding-left: 0;
  }
  .data-submenu .data-by-topic:nth-child(1) {
    padding-bottom: 1rem;
  }
  .data-submenu .data-by-topic:nth-child(2) {
    padding-top: 1rem;
  }

  .data-submenu-menus {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    grid-gap: -20px;
    gap: -20px;
  }
  .data-submenu .data-by-topic {
    flex: 1 1;
  }

  .data-submenu .data-by-topic:nth-child(1),
  .data-by-topic:nth-child(2) {
    padding-bottom: 0;
  }

  .header-inst {
    padding-left: 0rem !important;
  }
  .header-inst-list {
    grid-template-columns: repeat(2, 300px) !important;
  }
}
.mobile-nav-wrapper .ant-tabs-nav-list {
  width: 100% !important;
}

@media screen and (max-width: 900px) {
  .ant-tabs-content-holder {
    display: none !important;
  }
  .ant-tabs {
    position: relative;
    width: 100% !important;
  }

  .ant-tabs-left > .ant-tabs-nav {
    width: 100%;
    min-width: 100vw !important;
  }
  .tab {
    display: block;
    padding-right: 4rem;
  }
  .data-submenu {
    padding-top: 0px;
  }
}

/*LOGO AND MENU RESPONSIVE HANDLER*/

.header-responsive {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.responsive-logo-menu {
  flex: 0.6 1;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.responsive-icons {
  flex: 0.2 1;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 500px) and (min-width: 400px) {
  .responsive-icons {
    flex: 0.25 1;
  }
}

@media screen and (max-width: 400px) {
  .responsive-icons {
    flex: 0.25 1;
  }
}
.theme-btn-responsive {
  padding-top: 1rem;
  max-width: 700px;
}

@media screen and (min-width: 1400px) {
  .theme-btn-responsive {
    padding-top: 1rem;
    max-width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 1030px) {
  .header-inst-list {
    grid-template-columns: repeat(2, 200px) !important;
  }
}

@media screen and (max-width: 900px) {
  .data-submenu-menus::after,
  .data-submenu-menus::before {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .header-inst-list {
    grid-template-columns: repeat(1, 200px) !important;
  }
}

@media screen and (max-width: 500px) {
  .data-submenu-menus {
    display: block;
  }
  .data-submenu .data-by-topic:nth-child(1) {
    padding-bottom: 0.3rem;
  }
  .data-submenu .data-by-topic:nth-child(2) {
    padding-top: 0.3rem;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1642px) {
  .data-submenu {
    height: 400px;
  }
}

@media screen and (max-width: 500px) {
  .data-submenu .data-submenu-menus {
    display: flex;
    align-items: center;
  }
  .data-submenu-menus > .data-by-topic:nth-child(2) {
    margin-top: 3px;
  }
}

.footer-container {
  position: relative;
  width: 100%;
  padding: 2rem 60px;
  max-width: 100%;
  overflow-x: hidden;
}

.footer-container .footer-img {
  width: 100%;
  position: relative;
}
.footer-container .footer-img::after {
  content: '';
  position: absolute;
  width: 80%;
  bottom: 0;
  right: 0;

  height: 3px;
  background-color: #0e9898;
}
.footer-container .footer-content {
  display: flex;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.footer-container .footer-content .text {
  width: 100%;
  flex: 0.3 1;
}
.footer-container .footer-content .text > h2 {
  text-align: start !important;
  font-size: 30px;
  line-height: 45px;
  font-weight: 700;
}
.footer-container .footer-content .text > p {
  font-size: 18px;
  line-height: 31.5px;
  font-weight: 400;
  color: #424242;
}

.footer-container .footer-content .dynamic-data {
  flex: 0.7 1;
  width: 50%;
  margin-left: auto;
  display: flex;
  justify-content: space-around;
  padding-left: 2rem;
}
.footer-container .footer-content .dynamic-data > div > h4 {
  font-size: 20px;
  line-height: 45px;

  font-weight: 700;
}
.footer-container .footer-content .dynamic-data > div {
  padding-left: 4rem;
}
.footer-container .footer-content .dynamic-data > div > section {
  display: grid;
  grid-template-columns: repeat(2, 100px);
  grid-gap: 15px 28px;
}
.footer-container .footer-content .dynamic-data > div > section a {
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 400;
  color: #727272;
  transition: ease 0.2s;
}
.footer-container .footer-content .dynamic-data > div > section a:hover {
  color: #0e9898;
}
/*

font-size:
14px
16.8px
font-weight:
400

*/

@media screen and (max-width: 1100px) {
  .footer-container .footer-img::after {
    width: 75%;
    bottom: 0;
    left: 210px;
  }
}

@media screen and (max-width: 920px) {
  .footer-container .footer-content .dynamic-data {
    padding: 0;
    padding-left: 1rem;
  }
  .footer-container .footer-content .dynamic-data > div {
    padding: 0;
  }
  .footer-container .footer-img::after {
    width: 65%;
  }
}
@media screen and (max-width: 800px) {
  .footer-container .footer-img::after {
    width: 65%;
    left: 160px;
  }
}

@media screen and (max-width: 770px) {
  .footer-container {
    padding: 2rem 30px;
  }
  .footer-container .footer-content {
    flex-direction: column;
  }
  .footer-container .footer-content .dynamic-data {
    padding: 0;
    justify-content: start;
    grid-gap: 3rem;
    gap: 3rem;
  }
  .footer-container .footer-content .text > h2 {
    line-height: 20px;
  }
  .footer-container .footer-content .text > p {
    font-size: 18px;
    line-height: 24.5px;
  }
}

@media screen and (max-width: 590px) {
  .footer-container .footer-content .text > h2 {
    line-height: 40px;
    font-size: 20px;
    margin-bottom: 0;
  }
  .footer-container .footer-img::after {
    width: 60%;
    height: 2px;
    left: 150px;
  }
  .footer-container .footer-img {
    width: 100%;
    position: relative;
  }
  .footer-container .footer-content .dynamic-data > div > section {
    grid-gap: 15px 6px;
  }
  .footer-container .footer-content .dynamic-data {
    grid-gap: 1.5rem;
    gap: 1.5rem;
  }
}

@media screen and (max-width: 390px) {
  .footer-container .footer-img::after {
    width: 50%;
  }
}

@media screen and (max-width: 1000px) {
  .footer-container .footer-content {
  }
  .footer-container .footer-content .text {
  }

  .footer-container .footer-content .dynamic-data {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .footer-container .footer-content .dynamic-data {
    flex-wrap: wrap;
  }
}

.brief-wrapper-container {
  position: relative;
  width: 100%;
  padding: 60px;
  padding-top: 1rem;
}
.brief-header {
  margin: auto;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.info-briefs {
  margin-right: -10px;
  align-items: flex-start;
  position: relative;
  display: flex;
  grid-gap: 35px;
  gap: 35px;
  justify-content: center;
}
.brief-info {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 30px;
  gap: 30px;
}
.brief-info > div {
  color: black;
  padding: 3px;
  border-radius: 2px;
}
.brief-info > div > span:nth-child(1) {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: rgb(0, 0, 0);
}
.brief-info > div > span:nth-child(2) {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: rgb(95, 95, 95);
}
.brief-info .ant-select-arrow {
  margin-top: -6px !important;
}
.brief-category {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: black;
  font-weight: 500;
  color: rgb(255, 255, 255);
  font-size: 23px;
  padding: 5px;
}
.brief-info span:nth-child(2) {
  display: block;
  color: black;
  padding: 2px 12px;
  border-radius: 7px;
}
.span-category {
  color: rgb(0, 0, 0);
  display: block;
  padding: 2px 12px;
  border-radius: 7px;
}
/**Add indicator*/
.brief-add {
  border: 1px solid #0e9898;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  margin: auto;
  position: relative;
  max-height: 250px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.textarea {
  max-width: 555px;
}
.briefs-list {
  display: flex;
  flex-direction: column;
  grid-gap: 25px;
  gap: 25px;
}
.no-briefs {
  position: relative;
  width: 100%;
  height: 200px;
  display: grid;
  place-content: center;
  background-color: rgb(231, 231, 231);
}
.brief-img {
  display: grid;
  place-content: center;
  position: relative;
  width: 250px;
  height: 250px;
}
.brief-img img {
  position: relative;
  display: block;
  max-width: 100%;
}
.brief-delete {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  padding: 4px 10px;
  font-size: 17px;
  padding-left: 5px;
  cursor: pointer;
  border: 1px solid rgb(0, 0, 0);
  color: black;
  border-radius: 5px;
  transition: 0.1s ease-in;
}
.brief-delete:hover {
  border: 1px solid rgb(255, 255, 255);
  background-color: red;
  color: white;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  opacity: 0.9;
}
.add-brief-indicator {
  padding: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-brief-indicator .ant-select-arrow {
  margin-top: -6px !important;
}
.submit-brief-btn {
  margin: auto;
  width: 80%;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.submit-brief-btn a {
  text-decoration: none;
  display: inline-block;
  padding: 5px 15px;
  background-color: black;
  color: white;
  font-size: 16px;
  border-radius: 10px;
  transition: 0.1s ease;
}
.submit-brief-btn a:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  color: white;
}
/*ALL BRIEFS**/
.all-brief-date {
  width: 80%;
  margin: auto;
  background-color: white;
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px;
  display: flex;
  justify-content: flex-end;
}
.all-brief-category {
  width: 100%;
  margin: auto;
  background-color: black;
  color: white;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px;
}
.single-brief-parent {
  margin: auto;
  position: relative;
  width: 90%;
  border-bottom: dashed black 1px;
  padding-bottom: 1rem;
}
.single-brief-component {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 170px;
  padding: 10px;
}
.single-brief-component .single-brief-img {
  margin-right: 10px;
  position: relative;
  width: 60%;
}
.single-brief-component .single-brief-img img {
  position: relative;
  display: block;
  max-width: 100%;
  position: relative;
  height: 170px;
  width: 300px;
  object-fit: contain;
}
.brief-bar {
  margin-right: 35px;
  padding: 0;
  position: relative;
  min-height: 80px;
  /* height: 10px; */
  height: 80px;
  width: 6px;
  max-width: 14px;
  border: 1px solid rgba(0, 0, 0, 0.527);
  border-radius: 2px;
  box-shadow: 0.09px 0.09px 3px 0.1px #000000;
}
.brief-text {
  width: 80%;
  font-size: 14px;
  align-self: start;
  height: 100%;
  display: flex;
  align-items: center;
}
/*BRIEF SUBHEADER*/
.brief-subheader {
  background: #ffffff;
  color: rgb(0, 0, 0);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 20px 30px;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  position: relative;
  width: 100%;
  padding-left: 60px;
  text-transform: uppercase;
}
.brief-subheader .ant-breadcrumb-separator {
  color: rgb(0, 0, 0);
  opacity: 0.6;
}
.brief-subheader span > span.ant-breadcrumb-link a {
  color: black;
}
.brief-subheader span > span.ant-breadcrumb-link a:hover {
  color: #0e9898;
}
.brief-subheader.ant-breadcrumb a:hover {
  color: #0e9898;
}
.brief-subheader h1 {
  font-size: 48px;
  font-weight: 600;
  font-weight: 700;
  line-height: 57.6px;
  color: rgb(33, 33, 33);
  padding-top: 15px;
  text-transform: none;
}
/*ADD BRIEF BTN*/
.add-brief-btn {
  position: relative;
  width: 100%;
  padding: 30px;
}
/*BRIEF CARD*/
.brief-card-list {
  padding: 30px 60px;
  padding-top: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 400px));
  justify-items: center;
  grid-gap: 60px 40px;
  gap: 60px 40px;
  padding-bottom: 100px;
}
.brief-card {
  box-sizing: border-box;
  max-width: 400px;
  position: relative;
  width: 100%;
  cursor: pointer;
  transition: ease 0.1s;
  padding: 14px;
  box-shadow: 10px 10px 35px -13px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 10px 10px 35px -13px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 10px 10px 35px -13px rgba(0, 0, 0, 0.4);
}
.brief-card:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  border: 1px solid rgb(190, 190, 190);
}

.brief-card .brief-img-12 {
  position: relative;
  width: 100%;
}
.brief-card .brief-img-12 img {
  display: block;
  max-width: 100%;
  width: 100%;
  background-color: black;
  margin: auto;
}
.brief-card .date {
  padding: 10px;
  color: gray;
  font-size: 13px;
}
.brief-card .category {
  color: black;
  font-weight: 600;
  line-height: 28px;
  font-size: 18px;
  padding: 10px;
}
.delete-btn:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.dwn-btn:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}
.adding-brief {
  position: relative;
  height: 500px;
  background-color: rgb(255, 255, 255);
}
.adding-brief::before {
  content: '';
  width: 100%;
  position: absolute;
  top: -300px;
  height: 300px;
  right: 0;
  background-color: rgba(243, 243, 243, 0.233);
  z-index: 43534;
}
.no-briefs-added {
  width: 100%;
  position: relative;
  height: 400px;
  display: grid;
  place-content: center;
  font-size: 23px;
  font-weight: 500;
}
.brief-header .info-briefs .brief-info div:nth-child(1) {
  text-align: center;
}
@media screen and (max-width: 700px) {
  .brief-subheader {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .brief-subheader .ant-breadcrumb {
    padding: 0 !important;
  }
  .brief-subheader h1 {
    font-size: 36px;
    line-height: 45px;
  }
  .brief-card-list {
    padding: 30px 1.5rem;
  }
  .brief-wrapper-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .brief-header .info-briefs .brief-info {
    flex-wrap: wrap;
  }
  .brief-add {
    flex-direction: column;
    padding: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 420px;
  }
  .brief-add div:nth-child(2) {
    width: 100%;
  }
  .brief-img {
    display: block;
    margin: auto;
    width: 100%;
    width: 250px;
  }
  .brief-delete {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 500px) {
  .brief-header .info-briefs .brief-info {
    justify-content: flex-start;
  }
  .brief-header .info-briefs .brief-info div:nth-child(1) {
    text-align: start;
  }
  .brief-header .info-briefs .brief-info .sel-brief {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
}
@media screen and (min-width: 1400px) {
  .brief-card-list {
    grid-template-columns: repeat(4, minmax(200px, 500px));
    grid-gap: 60px;
  }
}
@media screen and (max-width: 1280px) {
  .brief-card-list {
    grid-template-columns: repeat(3, minmax(200px, 500px));
    grid-gap: 40px;
  }
}
@media screen and (max-width: 1000px) {
  .brief-card-list {
    grid-template-columns: repeat(2, minmax(200px, 500px));
    grid-gap: 20px;
  }
}
@media screen and (max-width: 570px) {
  .brief-card-list {
    grid-template-columns: repeat(1, minmax(200px, 500px));
    grid-gap: 20px;
  }
}
.single-brief-container {
  position: relative;
  width: 80%;
  max-width: 1200px;
  margin: auto;
  border-left: 1px solid black;
  border-right: 1px solid black;
  min-height: 100vh;
  padding: 0 20px;
}
.single-brief-container header {
  position: relative;
  display: flex;

  align-items: baseline;
}
.single-brief-container header .brief-logo {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  -webkit-transform: translateY(-17px);
          transform: translateY(-17px);
}
.single-brief-container header .brief-logo span {
  font-weight: 600;
  font-size: 25px;
}
.single-brief-container header img {
  width: 200px;
  max-width: 100%;
  display: block;
}
.single-brief-container .indicator-wrapper {
  width: 100%;
  padding: 0;
}
.brief-storyline-text {
  font-size: 12px;
  position: relative;
  width: 90%;
  margin: auto;
  padding: 4px 16px;
  max-width: 100%;
  word-break: break-word;
  word-spacing: 4px;
  margin-left: 80px;
  max-width: 650px;
}
.single-brief-component section {
  margin-left: -115px;
}
@media screen and (max-width: 801px) {
  .single-brief-component .indicator-wrapper .brief-text {
    padding-left: 1rem;
    font-size: 12px;
    margin-left: 1.5rem;
    margin-bottom: 3rem;
  }
  .single-brief-component .indicator-wrapper .brief-text .text {
    margin-top: -20px;
  }

  .single-brief-component .indicator-wrapper .brief-bar {
    -webkit-transform: translateY(40px);
            transform: translateY(40px);
  }
  .brief-storyline-text {
    margin-left: 0;
    max-width: 100%;
    padding-left: 45px;
  }
  .single-brief-component section {
    margin-left: 0;
  }
}

.institution-container {
  position: relative;
  width: 100%;
}

.institution-container .institution-indicators {
  position: relative;
  width: 100%;
  padding: 1rem 60px;
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 450px));
  grid-gap: 1rem;
}

.institution-container .no-ind {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #151515;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data-inst {
  position: relative;
  padding: 3.5rem;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 24px;
}

.institution-container section {
  padding: 2rem 3.5rem;
  padding-top: 0rem;
}

.institution-container section h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 32px;
  color: #151515;
}

@media screen and (max-width: 1300px) {
  .institution-container .institution-indicators {
    grid-template-columns: repeat(2, minmax(200px, 750px));
    grid-gap: 3rem;
  }
}

@media screen and (max-width: 1000px) {
  .institution-container section {
    padding: 0.2rem 2rem;
  }
  /* .institution-container section h1 {
    padding-left: 2rem;
  } */
}
@media screen and (max-width: 1200px) {
  .institution-container section {
    padding: 2rem 60px;
  }
}

@media screen and (max-width: 750px) {
  .institution-container section {
    padding: 2rem 60px;
  }

  .institution-container section .key-data-col {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 700px) {
  .institution-container section h1 {
    padding-left: 0.8rem;
    font-size: 16px;
  }
  .institution-container section a.view-all-btn {
    font-size: 12px;
  }
  .institution-container .institution-indicators {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .institution-container section {
    padding: 1rem 0rem;
  }
}
@media screen and (max-width: 900px) {
  .institution-container .institution-indicators {
    grid-template-columns: repeat(2, minmax(200px, 400px));
    grid-gap: 1rem;
  }
}

@media screen and (max-width: 790px) {
  .institution-container .institution-indicators {
    grid-template-columns: repeat(1, minmax(200px, 700px));
    grid-gap: 1rem;
  }
}

section .inst-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3.2rem 2rem 10px;
}
section .inst-header-container .view-all-btn {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #151515;
  grid-gap: 8px;
  gap: 8px;
  transition: ease-in 0.3s;
}

section .inst-header-container .view-all-btn:hover {
  color: #0e9898;
}

@media screen and (max-width: 700px) {
  section .inst-header-container {
    padding: 0s;
  }
}

.search-content {
  position: relative;
  width: 100%;
}
.search-content .title {
  color: #212121;
  font-weight: 700;
  line-height: 50.6px;
  font-size: 42px;
  text-align: center;
  font-weight: 600;
}

.search-content section {
  min-height: 500px;
  max-width: 60%;
  margin: auto;
  padding: 6px 12px;
}

.search-content section .search-link {
  cursor: pointer;
}

.search-content section .search-link:nth-child(2n + 1) {
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}
.search-content section .search-link:nth-child(2n + 1):hover {
  background-color: rgb(243, 243, 243);
}
.search-content section .search-link:nth-child(2n + 2) {
  background-color: rgb(245, 245, 245);
  cursor: pointer;
}
.search-content section .search-link:nth-child(2n + 2):hover {
  background-color: rgb(251, 250, 250);
  cursor: pointer;
}
.search-content .search-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 16px;
  transition: 0.2s ease-in;
  margin-top: 10px;
}

.search-content .search-item:hover > p,
.search-content .search-item:hover > p > span {
  color: #0e9898;
}

.search-content .search-item > p:nth-child(1) {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: black;
  flex: 0.8 1;
}
.search-content .search-item > p:nth-child(1) > span {
  transition: 0.2s ease-in;
}
.search-content .search-item .search-category {
  transition: 0.2s ease-in;
  font-size: 12px;
  color: #757575;
  line-height: 18px;
  letter-spacing: 2.1px;
  font-weight: 700;
}
.search-content .search-item > p:nth-child(1) > span:nth-child(2) {
  font-weight: 500;
  display: inline-block;
  font-size: 14px;
  width: 100%;
}

@media screen and (max-width: 700px) {
  .search-content section {
    min-height: 500px;
    max-width: 80%;
    margin: auto;
    padding: 6px 12px;
  }
}

.institutions-list {
  position: relative;
  width: 100%;
  padding: 1rem 3.5rem;
  margin: auto;
}

.institutions-list section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 350px));
  grid-gap: 1rem;
  justify-content: center;
}

.institutions-list h1 {
  text-align: center;
  font-size: 36px;
  font-weight: 400;
}

article.institution-list {
  max-width: 350px;
  background: #0e9898;
  padding: 16px;
  transition: 0.2s ease-in;
}
article.institution-list h3,
article.institution-list h2 {
  text-align: center;
  color: white;
}
article.institution-list h3 {
  font-weight: 700;
  font-size: 30px;
}

article.institution-list:hover {
  -webkit-transform: scale(1.04);
          transform: scale(1.04);
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  article.institution-list {
    display: flex;
    justify-content: space-between;
  }
  article.institution-list h2,
  article.institution-list h3 {
    font-size: 13px;
  }
}

