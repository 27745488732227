.data-explorer {
  position: relative;
  width: 100%;
  background: #ffffff;
  padding: 1.5rem 60px;
}

.data-explorer > h1.title {
  font-size: 48px;
  font-weight: 700;
  line-height: 50.6px;
  color: rgb(33, 33, 33);
  font-size: 48px;
}

.data-explorer > p.subtitle {
  color: #626161;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  margin-top: -20px;
}

.data-explorer > span {
  transform: translateY(20px);
  position: relative;
  display: block;
  width: 80%;
  margin: auto;
  height: 1px;
  background-color: #0e9898;
}

@media screen and (max-width: 750px) {
  .data-explorer > h1.title {
    font-size: 35px;
  }
}

@media screen and (max-width: 700px) {
  .data-explorer {
    background-color: red;
  }
}
