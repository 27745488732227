.brief-wrapper-container {
  position: relative;
  width: 100%;
  padding: 60px;
  padding-top: 1rem;
}
.brief-header {
  margin: auto;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.info-briefs {
  margin-right: -10px;
  align-items: flex-start;
  position: relative;
  display: flex;
  gap: 35px;
  justify-content: center;
}
.brief-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.brief-info > div {
  color: black;
  padding: 3px;
  border-radius: 2px;
}
.brief-info > div > span:nth-child(1) {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: rgb(0, 0, 0);
}
.brief-info > div > span:nth-child(2) {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: rgb(95, 95, 95);
}
.brief-info .ant-select-arrow {
  margin-top: -6px !important;
}
.brief-category {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: black;
  font-weight: 500;
  color: rgb(255, 255, 255);
  font-size: 23px;
  padding: 5px;
}
.brief-info span:nth-child(2) {
  display: block;
  color: black;
  padding: 2px 12px;
  border-radius: 7px;
}
.span-category {
  color: rgb(0, 0, 0);
  display: block;
  padding: 2px 12px;
  border-radius: 7px;
}
/**Add indicator*/
.brief-add {
  border: 1px solid #0e9898;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  margin: auto;
  position: relative;
  max-height: 250px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.textarea {
  max-width: 555px;
}
.briefs-list {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.no-briefs {
  position: relative;
  width: 100%;
  height: 200px;
  display: grid;
  place-content: center;
  background-color: rgb(231, 231, 231);
}
.brief-img {
  display: grid;
  place-content: center;
  position: relative;
  width: 250px;
  height: 250px;
}
.brief-img img {
  position: relative;
  display: block;
  max-width: 100%;
}
.brief-delete {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 4px 10px;
  font-size: 17px;
  padding-left: 5px;
  cursor: pointer;
  border: 1px solid rgb(0, 0, 0);
  color: black;
  border-radius: 5px;
  transition: 0.1s ease-in;
}
.brief-delete:hover {
  border: 1px solid rgb(255, 255, 255);
  background-color: red;
  color: white;
  transform: scale(1.05);
  opacity: 0.9;
}
.add-brief-indicator {
  padding: 20px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-brief-indicator .ant-select-arrow {
  margin-top: -6px !important;
}
.submit-brief-btn {
  margin: auto;
  width: 80%;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.submit-brief-btn a {
  text-decoration: none;
  display: inline-block;
  padding: 5px 15px;
  background-color: black;
  color: white;
  font-size: 16px;
  border-radius: 10px;
  transition: 0.1s ease;
}
.submit-brief-btn a:hover {
  transform: scale(1.1);
  color: white;
}
/*ALL BRIEFS**/
.all-brief-date {
  width: 80%;
  margin: auto;
  background-color: white;
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px;
  display: flex;
  justify-content: flex-end;
}
.all-brief-category {
  width: 100%;
  margin: auto;
  background-color: black;
  color: white;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px;
}
.single-brief-parent {
  margin: auto;
  position: relative;
  width: 90%;
  border-bottom: dashed black 1px;
  padding-bottom: 1rem;
}
.single-brief-component {
  width: 100%;
  display: flex;
  align-items: center;
  min-height: 170px;
  padding: 10px;
}
.single-brief-component .single-brief-img {
  margin-right: 10px;
  position: relative;
  width: 60%;
}
.single-brief-component .single-brief-img img {
  position: relative;
  display: block;
  max-width: 100%;
  position: relative;
  height: 170px;
  width: 300px;
  object-fit: contain;
}
.brief-bar {
  margin-right: 35px;
  padding: 0;
  position: relative;
  min-height: 80px;
  /* height: 10px; */
  height: 80px;
  width: 6px;
  max-width: 14px;
  border: 1px solid rgba(0, 0, 0, 0.527);
  border-radius: 2px;
  -webkit-box-shadow: 0.09px 0.09px 3px 0.1px #000000;
  box-shadow: 0.09px 0.09px 3px 0.1px #000000;
}
.brief-text {
  width: 80%;
  font-size: 14px;
  align-self: start;
  height: 100%;
  display: flex;
  align-items: center;
}
/*BRIEF SUBHEADER*/
.brief-subheader {
  background: #ffffff;
  color: rgb(0, 0, 0);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding: 20px 30px;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  position: relative;
  width: 100%;
  padding-left: 60px;
  text-transform: uppercase;
}
.brief-subheader .ant-breadcrumb-separator {
  color: rgb(0, 0, 0);
  opacity: 0.6;
}
.brief-subheader span > span.ant-breadcrumb-link a {
  color: black;
}
.brief-subheader span > span.ant-breadcrumb-link a:hover {
  color: #0e9898;
}
.brief-subheader.ant-breadcrumb a:hover {
  color: #0e9898;
}
.brief-subheader h1 {
  font-size: 48px;
  font-weight: 600;
  font-weight: 700;
  line-height: 57.6px;
  color: rgb(33, 33, 33);
  padding-top: 15px;
  text-transform: none;
}
/*ADD BRIEF BTN*/
.add-brief-btn {
  position: relative;
  width: 100%;
  padding: 30px;
}
/*BRIEF CARD*/
.brief-card-list {
  padding: 30px 60px;
  padding-top: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 400px));
  justify-items: center;
  gap: 60px 40px;
  padding-bottom: 100px;
}
.brief-card {
  box-sizing: border-box;
  max-width: 400px;
  position: relative;
  width: 100%;
  cursor: pointer;
  transition: ease 0.1s;
  padding: 14px;
  box-shadow: 10px 10px 35px -13px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 10px 10px 35px -13px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 10px 10px 35px -13px rgba(0, 0, 0, 0.4);
}
.brief-card:hover {
  transform: scale(1.05);
  border: 1px solid rgb(190, 190, 190);
}

.brief-card .brief-img-12 {
  position: relative;
  width: 100%;
}
.brief-card .brief-img-12 img {
  display: block;
  max-width: 100%;
  width: 100%;
  background-color: black;
  margin: auto;
}
.brief-card .date {
  padding: 10px;
  color: gray;
  font-size: 13px;
}
.brief-card .category {
  color: black;
  font-weight: 600;
  line-height: 28px;
  font-size: 18px;
  padding: 10px;
}
.delete-btn:hover {
  transform: scale(1.05);
}
.dwn-btn:hover {
  transform: scale(1.05);
}
.adding-brief {
  position: relative;
  height: 500px;
  background-color: rgb(255, 255, 255);
}
.adding-brief::before {
  content: '';
  width: 100%;
  position: absolute;
  top: -300px;
  height: 300px;
  right: 0;
  background-color: rgba(243, 243, 243, 0.233);
  z-index: 43534;
}
.no-briefs-added {
  width: 100%;
  position: relative;
  height: 400px;
  display: grid;
  place-content: center;
  font-size: 23px;
  font-weight: 500;
}
.brief-header .info-briefs .brief-info div:nth-child(1) {
  text-align: center;
}
@media screen and (max-width: 700px) {
  .brief-subheader {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .brief-subheader .ant-breadcrumb {
    padding: 0 !important;
  }
  .brief-subheader h1 {
    font-size: 36px;
    line-height: 45px;
  }
  .brief-card-list {
    padding: 30px 1.5rem;
  }
  .brief-wrapper-container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .brief-header .info-briefs .brief-info {
    flex-wrap: wrap;
  }
  .brief-add {
    flex-direction: column;
    padding: 20px;
    justify-content: flex-start;
    align-items: flex-start;
    max-height: 420px;
  }
  .brief-add div:nth-child(2) {
    width: 100%;
  }
  .brief-img {
    display: block;
    margin: auto;
    width: 100%;
    width: 250px;
  }
  .brief-delete {
    margin-top: 1rem;
  }
}
@media screen and (max-width: 500px) {
  .brief-header .info-briefs .brief-info {
    justify-content: flex-start;
  }
  .brief-header .info-briefs .brief-info div:nth-child(1) {
    text-align: start;
  }
  .brief-header .info-briefs .brief-info .sel-brief {
    transform: translateX(-10px);
  }
}
@media screen and (min-width: 1400px) {
  .brief-card-list {
    grid-template-columns: repeat(4, minmax(200px, 500px));
    grid-gap: 60px;
  }
}
@media screen and (max-width: 1280px) {
  .brief-card-list {
    grid-template-columns: repeat(3, minmax(200px, 500px));
    grid-gap: 40px;
  }
}
@media screen and (max-width: 1000px) {
  .brief-card-list {
    grid-template-columns: repeat(2, minmax(200px, 500px));
    grid-gap: 20px;
  }
}
@media screen and (max-width: 570px) {
  .brief-card-list {
    grid-template-columns: repeat(1, minmax(200px, 500px));
    grid-gap: 20px;
  }
}
.single-brief-container {
  position: relative;
  width: 80%;
  max-width: 1200px;
  margin: auto;
  border-left: 1px solid black;
  border-right: 1px solid black;
  min-height: 100vh;
  padding: 0 20px;
}
.single-brief-container header {
  position: relative;
  display: flex;

  align-items: baseline;
}
.single-brief-container header .brief-logo {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transform: translateY(-17px);
}
.single-brief-container header .brief-logo span {
  font-weight: 600;
  font-size: 25px;
}
.single-brief-container header img {
  width: 200px;
  max-width: 100%;
  display: block;
}
.single-brief-container .indicator-wrapper {
  width: 100%;
  padding: 0;
}
.brief-storyline-text {
  font-size: 12px;
  position: relative;
  width: 90%;
  margin: auto;
  padding: 4px 16px;
  max-width: 100%;
  word-break: break-word;
  word-spacing: 4px;
  margin-left: 80px;
  max-width: 650px;
}
.single-brief-component section {
  margin-left: -115px;
}
@media screen and (max-width: 801px) {
  .single-brief-component .indicator-wrapper .brief-text {
    padding-left: 1rem;
    font-size: 12px;
    margin-left: 1.5rem;
    margin-bottom: 3rem;
  }
  .single-brief-component .indicator-wrapper .brief-text .text {
    margin-top: -20px;
  }

  .single-brief-component .indicator-wrapper .brief-bar {
    transform: translateY(40px);
  }
  .brief-storyline-text {
    margin-left: 0;
    max-width: 100%;
    padding-left: 45px;
  }
  .single-brief-component section {
    margin-left: 0;
  }
}
