.glance-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 60px;
}

.subcategory-data {
  padding: 10px;
  padding-top: 10px;
  padding-bottom: 20px;
  width: 90%;
  margin: auto;
  min-width: 1280px;
  overflow-x: scroll;
}

.subcategory-data .subcategory-name {
  font-size: 20px;
  font-weight: 600;
}

.subcategory-data .line {
  width: 100%;
  margin: auto;
  height: 2px;
  background-color: rgb(0, 0, 0);
}

.data-info-row {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.data-row {
  display: flex;
  width: 60%;
  justify-content: space-around;
}

.data-row .data-info {
  display: flex;
  justify-content: space-around;
}

/*Graph info*/

.glance-info {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: end;
  padding-right: 4rem;
  padding-bottom: 0;
}
.glance-info > div {
  display: flex;
  padding: 12px;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 0;
}

.glance-info .theme-category {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.glance-info .comparison-dates {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.glance-info .comparison-dates > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.glance-info .comparison-dates > div span:nth-child(1) {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
}
.glance-info .ant-select-arrow {
  margin-top: -6px !important;
  color: white !important;
}
.glance-info .ant-select-selector {
  border-radius: 4px !important;
  background-color: #27323e !important;
  color: white;
}
.glance-info .theme-category span:nth-child(1) {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
}
.csv-down-glance {
  position: relative;
  transition: 0.2s ease-in;
}

.csv-down-glance:hover {
  transform: scale(1.1);
}

/* .glance-info .theme-category span:nth-child(2) {
  text-transform: uppercase;

  padding: 5px;
  font-weight: 500;
  border-radius: 5px;
} */
/*TABLE STYLING*/

.change-col {
  /* background-color: #27323e; */
  color: white;
  text-align: center;
}

.glance-table {
  pointer-events: none;
  padding-top: 20px;
  position: relative;
  width: 100%;
  text-align: center;
}
.glance-table thead > tr > th {
  background-color: unset;
  color: #8b8a8a;
}
.glance-table thead > tr > th:last-child {
  /* background-color: #27323e; */
  color: #27323e;
  border-bottom: none;
}
.glance-table tbody > tr > td {
  padding: 10px;
  text-align: center;
  border-bottom: none;
}
.glance-table tbody > tr > td:first-child {
  text-align: start;
  border-bottom: none;
}

.glance-table tbody > tr > td:last-child {
  text-align: center;
  border-bottom: none;
}

.glance-col {
  border-bottom: 0px !important;

  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
}

.glance-col-name {
  padding-left: 40px;
  min-width: 300px;
  max-width: 300px;
  word-break: break-all;
}

.glance-col-change {
  text-align: center;
  padding: 0;
  margin: 0;
}
.glance-col-change .negative {
  color: red;
}
.glance-col-change .positive {
  color: rgb(14, 152, 152);
}

.glance-col-current {
  text-align: end;
  font-weight: bold;
}
.glance-table tbody > tr:hover > td {
  background-color: unset !important;
  color: unset !important;
}
.glance-table tbody > tr:hover > .glance-col-change {
  background-color: unset !important;
  color: unset !important;
}
.glance-table .ant-table-tbody > tr:hover > td {
  background-color: unset !important;
  color: unset !important;
}

/*GLANCE SUBHEADER*/

.glance-subheader {
  position: relative;
  background-color: #ffffff;
  padding: 60px;
  padding-top: 1.5rem;
  padding-bottom: 1px;
}
.glance-subheader h1 {
  font-weight: 700;
  color: rgb(0, 0, 0);
  line-height: 40px;
  font-size: 48px;
  padding-top: 1.5rem;
}

.glance-subheader .ant-breadcrumb-separator {
  margin: 0 8px;
  color: rgb(0, 0, 0);
}

.glance-table .ant-table-thead > tr > th {
  padding: 0 20px !important;
}
.glance-table tbody > tr > td:hover {
  color: unset !important  ;
  background-color: unset !important;
}

.glance-container {
  position: relative;
  width: 100%;
  overflow-x: scroll;
}

@media screen and (max-width: 1420px) {
  .glance-info {
    margin: auto;
    width: 92%;
    padding: 0;
  }
  .glance-info > div {
    padding-right: 0;
  }
}
@media screen and (min-width: 1351px) and (max-width: 1419px) {
  .glance-info {
    width: 100%;
    padding-right: calc(3vw + 10px);
  }
}
@media screen and (min-width: 1311px) and (max-width: 1350px) {
  .glance-info {
    width: 100%;
    padding-right: calc(2vw + 10px);
  }
}

@media screen and (min-width: 1281px) and (max-width: 1310px) {
  .glance-info {
    width: 100%;
    padding-right: 1rem;
  }
}

@media screen and (max-width: 1280px) {
  .glance-container {
    width: 90%;
    margin: auto;
  }
  .glance-info {
    position: relative;
  }
  .glance-info > div {
    padding-right: 0;
  }
}

@media screen and (max-width: 500px) {
  .glance-wrapper .theme-subheader-title {
    font-size: 30px;
  }
}
