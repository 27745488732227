.theme-padding-wrapper {
  position: relative;
  width: 100%;
  padding: 3.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  margin: auto;
}
.theme-padding-wrapper h1 {
  padding: 0;
  margin: 0;
  font-size: 46px;
  line-height: 67px;
  font-weight: 700;
}
@media screen and (max-width: 1281px) {
  .theme-padding-wrapper h1 {
    padding: 0 3.5rem;
  }
}

@media screen and (max-width: 700px) {
  .theme-padding-wrapper h1 {
    padding: 0.2rem 1.5rem;
    font-size: 25px;
  }
}

.theme-section-wrapper {
  position: relative;
  width: 100%;
  padding: 16px 0;
  display: grid;
  grid-template-columns: repeat(5, minmax(60px, 250px));
  grid-gap: 10px 20px;
  justify-content: space-between;
  width: 90vw;
}

.themes-spinner {
  position: relative;
  width: 100%;
  height: 70vh;
  display: grid;
  place-content: center;
}

@media screen and (min-width: 1500px) {
  .top-menu-item .theme-padding-wrapper {
    padding: 0.2rem 10rem;
  }

  .top-menu-item .theme-section-wrapper {
    grid-template-columns: repeat(5, minmax(60px, 400px));
  }
}
@media screen and (min-width: 1900px) {
  .top-menu-item .theme-padding-wrapper {
    padding: 0.2rem 16rem;
  }
}

@media screen and (max-width: 911px) and (min-width: 500px) {
  .theme-section-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(100px, 200px));
  }
}
@media screen and (max-width: 525px) {
  .theme-section-wrapper {
    grid-template-columns: repeat(1, minmax(280px, 250px));
  }
}

/*ERROR CLASSES*/

.theme-error {
  position: relative;
  width: 100%;
  font-size: 30px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 500px) {
  .theme-error {
    padding: 0 0.5rem;
    font-size: 22px;
  }
}
@media screen and (max-width: 1280px) {
  .theme-padding-wrapper {
    padding: 0;
  }
  .theme-section-wrapper {
    max-width: calc(35vw + 300px);
    padding: 30px;
    padding-top: 0;
    grid-template-columns: repeat(3, minmax(60px, 350px));
    justify-content: space-around;
  }
  .theme-btn-responsive {
    padding-left: 30px !important;
  }
}

@media screen and (max-width: 900px) {
  .theme-section-wrapper {
    max-width: 100vw;
    grid-template-columns: repeat(2, minmax(60px, 250px));
  }
  .theme-padding-wrapper {
    padding: 0.5rem 0rem;
  }
  .theme-padding-wrapper .theme-card-text {
  }

  .theme-padding-wrapper .theme-card-text h3 {
    font-size: 12px;
  }
  .theme-padding-wrapper .theme-card-text h1 {
    margin-bottom: 0;
    font-size: 14px;
  }
  .responsive-btn-row {
    justify-content: center;
  }
}

.theme-section-wrapper.desktop {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(60px, 400px));
  grid-gap: 10px 20px;
  justify-content: space-around;
  max-width: 100vw;
  padding: 16px 2.5rem;
}

@media screen and (max-width: 1280px) {
  .theme-section-wrapper.desktop {
    grid-template-columns: repeat(auto-fit, minmax(60px, 280px));
    padding-left: 3.5rem;
    padding-right: 3.5rem;
    justify-content: space-between;
  }
  .report-view-btn.theme-view-btn {
    padding-right: 3.5rem;
  }
}
@media screen and (max-width: 1279px) and (min-width: 961px) {
  .theme-section-wrapper.desktop {
    grid-template-columns: repeat(3, minmax(60px, 330px));
  }
}

@media screen and (min-width: 1280px) {
  .theme-section-wrapper.desktop {
    padding: 0;
    justify-content: space-between;
    grid-template-columns: repeat(4, minmax(60px, 400px));
  }
}

@media screen and (min-width: 1278px) and (max-width: 1282px) {
  .theme-section-wrapper.desktop {
    padding: 0 3.5rem;
  }
}
@media screen and (max-width: 960px) and (min-width: 700px) {
  .theme-section-wrapper.desktop {
    grid-template-columns: repeat(2, minmax(60px, 400px));
  }
}

@media screen and (min-width: 500px) and (max-width: 624px) {
  .theme-section-wrapper.desktop {
    grid-template-columns: repeat(auto-fit, minmax(60px, 200px));
  }
}
@media screen and (max-width: 700px) and (min-width: 500px) {
  .theme-section-wrapper.desktop {
    grid-template-columns: repeat(2, minmax(60px, 400px));
  }
}
@media screen and (max-width: 500px) {
  .theme-section-wrapper.desktop {
    /* grid-template-columns: repeat(2, minmax(60px, 440px)); */
    /* grid-gap: 1rem;
    padding: 1.5rem; */
  }
  .theme-padding-wrapper .theme-card-text {
  }
}

@media screen and (max-width: 700px) {
  .theme-section-wrapper {
    padding: 0 1.5rem;
  }
  .theme-section-wrapper.desktop {
    padding: 1.5rem;
    justify-content: space-between;
    margin: auto;
    overflow: scroll;
    display: grid;
    scroll-snap-type: x mandatory;
    width: 90vw;
    grid-template-columns: repeat(4, 180px);
  }
  .theme-padding-wrapper {
    padding: 0;
  }
  .report-view-btn.theme-view-btn {
    padding: 0 2rem;
    padding-top: 0.7rem;
    padding-bottom: 2rem;
    text-align: end;
  }
}
