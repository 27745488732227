.institution-container {
  position: relative;
  width: 100%;
}

.institution-container .institution-indicators {
  position: relative;
  width: 100%;
  padding: 1rem 60px;
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 450px));
  grid-gap: 1rem;
}

.institution-container .no-ind {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #151515;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-data-inst {
  position: relative;
  padding: 3.5rem;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 24px;
}

.institution-container section {
  padding: 2rem 3.5rem;
  padding-top: 0rem;
}

.institution-container section h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 32px;
  color: #151515;
}

@media screen and (max-width: 1300px) {
  .institution-container .institution-indicators {
    grid-template-columns: repeat(2, minmax(200px, 750px));
    grid-gap: 3rem;
  }
}

@media screen and (max-width: 1000px) {
  .institution-container section {
    padding: 0.2rem 2rem;
  }
  /* .institution-container section h1 {
    padding-left: 2rem;
  } */
}
@media screen and (max-width: 1200px) {
  .institution-container section {
    padding: 2rem 60px;
  }
}

@media screen and (max-width: 750px) {
  .institution-container section {
    padding: 2rem 60px;
  }

  .institution-container section .key-data-col {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 700px) {
  .institution-container section h1 {
    padding-left: 0.8rem;
    font-size: 16px;
  }
  .institution-container section a.view-all-btn {
    font-size: 12px;
  }
  .institution-container .institution-indicators {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .institution-container section {
    padding: 1rem 0rem;
  }
}
@media screen and (max-width: 900px) {
  .institution-container .institution-indicators {
    grid-template-columns: repeat(2, minmax(200px, 400px));
    grid-gap: 1rem;
  }
}

@media screen and (max-width: 790px) {
  .institution-container .institution-indicators {
    grid-template-columns: repeat(1, minmax(200px, 700px));
    grid-gap: 1rem;
  }
}

section .inst-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3.2rem 2rem 10px;
}
section .inst-header-container .view-all-btn {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #151515;
  gap: 8px;
  transition: ease-in 0.3s;
}

section .inst-header-container .view-all-btn:hover {
  color: #0e9898;
}

@media screen and (max-width: 700px) {
  section .inst-header-container {
    padding: 0s;
  }
}
