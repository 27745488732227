.report-filters {
  flex: 0.2;
  position: relative;
  width: 100%;
  /* background: hsla(240, 20%, 98%, 0.356); */
  height: 800px;
  padding-bottom: 12px;
}

span.filter-drawer {
  cursor: pointer;
  font-weight: 600;
  display: inline-flex;
  background-color: #d9d9d9;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0.6rem;
}
span.filter-drawer span:nth-child(1) {
  margin-right: 5px;
}

@media screen and (max-width: 1280px) {
  .report-filters {
    height: auto;
  }
}

.report-filters .filters-title {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #151515;
  padding: 1rem;
  padding-left: 0;
  padding-top: 0.2rem;
}

.report-filters .filter-area {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.report-filters .filter-area .filter-comp {
  position: relative;
  width: 100%;
}
.report-filters .filter-area .filter-category {
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 26px;
  color: #151515;
  padding-bottom: 12px;
  margin-top: 20px !important;
}

.report-filters .filter-area .filter-category::first-letter {
  text-transform: uppercase;
}
.report-filters .filter-area .filter-checkbox {
  font-weight: 700;
  padding-top: 10px;
}
.report-filters .filter-area .filter-checkbox span input[type='checkbox'] {
  padding: 30px;
}
.report-filters .filter-area .checkboxes {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.report-filters .filter-area .checkboxes .checkbox-value {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #151515;
}
.report-filters .filter-area .checkboxes .checkbox-value::first-letter {
  text-transform: uppercase;
}
@media screen and (max-width: 750px) {
  .report-filters .filter-area {
    flex-direction: row;
    gap: 10px;
  }
  .report-filters {
    padding: 10px;
  }
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0e9898 !important;
  border-color: #0e9898 !important;
}

.filter-label-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 8px;
  margin: 10px;
  background-color: #0e9898 !important;
  border-color: #0e9898 !important;
  border-radius: 30px;
}

.filter-label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: white;
}

.filter-remove-icon {
  margin-left: 10px;
}
.ant-checkbox .ant-checkbox-inner:hover {
  border-color: #0e9898 !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #0e9898 !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #0e9898 !important;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #0e9898 !important;
}

.filter-sort {
  position: relative;
  margin-left: auto;
  float: right;
}
.filter-sort > .comp {
  position: relative;
}

.report-filters .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 1rem 0;
}
