.footer-container {
  position: relative;
  width: 100%;
  padding: 2rem 60px;
  max-width: 100%;
  overflow-x: hidden;
}

.footer-container .footer-img {
  width: 100%;
  position: relative;
}
.footer-container .footer-img::after {
  content: '';
  position: absolute;
  width: 80%;
  bottom: 0;
  right: 0;

  height: 3px;
  background-color: #0e9898;
}
.footer-container .footer-content {
  display: flex;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
.footer-container .footer-content .text {
  width: 100%;
  flex: 0.3;
}
.footer-container .footer-content .text > h2 {
  text-align: start !important;
  font-size: 30px;
  line-height: 45px;
  font-weight: 700;
}
.footer-container .footer-content .text > p {
  font-size: 18px;
  line-height: 31.5px;
  font-weight: 400;
  color: #424242;
}

.footer-container .footer-content .dynamic-data {
  flex: 0.7;
  width: 50%;
  margin-left: auto;
  display: flex;
  justify-content: space-around;
  padding-left: 2rem;
}
.footer-container .footer-content .dynamic-data > div > h4 {
  font-size: 20px;
  line-height: 45px;

  font-weight: 700;
}
.footer-container .footer-content .dynamic-data > div {
  padding-left: 4rem;
}
.footer-container .footer-content .dynamic-data > div > section {
  display: grid;
  grid-template-columns: repeat(2, 100px);
  grid-gap: 15px 28px;
}
.footer-container .footer-content .dynamic-data > div > section a {
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 400;
  color: #727272;
  transition: ease 0.2s;
}
.footer-container .footer-content .dynamic-data > div > section a:hover {
  color: #0e9898;
}
/*

font-size:
14px
16.8px
font-weight:
400

*/

@media screen and (max-width: 1100px) {
  .footer-container .footer-img::after {
    width: 75%;
    bottom: 0;
    left: 210px;
  }
}

@media screen and (max-width: 920px) {
  .footer-container .footer-content .dynamic-data {
    padding: 0;
    padding-left: 1rem;
  }
  .footer-container .footer-content .dynamic-data > div {
    padding: 0;
  }
  .footer-container .footer-img::after {
    width: 65%;
  }
}
@media screen and (max-width: 800px) {
  .footer-container .footer-img::after {
    width: 65%;
    left: 160px;
  }
}

@media screen and (max-width: 770px) {
  .footer-container {
    padding: 2rem 30px;
  }
  .footer-container .footer-content {
    flex-direction: column;
  }
  .footer-container .footer-content .dynamic-data {
    padding: 0;
    justify-content: start;
    gap: 3rem;
  }
  .footer-container .footer-content .text > h2 {
    line-height: 20px;
  }
  .footer-container .footer-content .text > p {
    font-size: 18px;
    line-height: 24.5px;
  }
}

@media screen and (max-width: 590px) {
  .footer-container .footer-content .text > h2 {
    line-height: 40px;
    font-size: 20px;
    margin-bottom: 0;
  }
  .footer-container .footer-img::after {
    width: 60%;
    height: 2px;
    left: 150px;
  }
  .footer-container .footer-img {
    width: 100%;
    position: relative;
  }
  .footer-container .footer-content .dynamic-data > div > section {
    grid-gap: 15px 6px;
  }
  .footer-container .footer-content .dynamic-data {
    gap: 1.5rem;
  }
}

@media screen and (max-width: 390px) {
  .footer-container .footer-img::after {
    width: 50%;
  }
}

@media screen and (max-width: 1000px) {
  .footer-container .footer-content {
  }
  .footer-container .footer-content .text {
  }

  .footer-container .footer-content .dynamic-data {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .footer-container .footer-content .dynamic-data {
    flex-wrap: wrap;
  }
}
