.indicator-chart-card-wrapper {
  max-width: 150px;
  padding: 8px;
  padding-bottom: 2px;
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  transition: 0.3s ease-in;
  border: 1px solid rgb(243, 243, 243);
  margin: auto;
  height: 100%;
}

.indicator-chart-card-wrapper:hover {
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(126, 126, 126, 0.185);
  box-sizing: border-box;
  cursor: pointer;
}
.indicator-chart-card-wrapper .content .icon {
  width: 20px;
  padding-top: 10px;
  cursor: pointer;
  transition: 0.1s ease;
}
.indicator-chart-card-wrapper .content .icon img {
  display: none;
}
.indicator-chart-card-wrapper:hover .icon img {
  display: block;
  width: 20px;
}
.indicator-chart-card-wrapper div > img {
  display: inline-block;
  max-width: 100%;
  margin: auto;
}
.indicator-chart-card-wrapper .content {
  margin-top: 10px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.indicator-chart-card-wrapper .content .text {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.indicator-chart-card-wrapper div > div h3:nth-child(1) {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  width: 100%;
}
.indicator-chart-card-wrapper div > div h3:nth-child(2) {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  color: #0e9898;
}

.indicator-chart-card-wrapper .content .icon:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 500px) {
  .indicator-chart-card-wrapper {
    max-width: 300px;
  }
}
