.modal-wrapper {
  width: 100%;
  position: absolute;
  z-index: 19000;
  inset: 0;
  top: 30px;
  background-color: rgba(21, 21, 21, 0.85);
  overflow-y: hidden;
}

.modal-wrapper .bar-container {
  display: flex;
  justify-content: space-between;
  background-color: #f8f8fa;
  align-items: center;
  padding: 0 10px;
}
.modal-wrapper .bar-container > div:nth-child(2) {
  cursor: pointer;
}
.modal-wrapper .bar-container > div:nth-child(2):hover {
  transform: scale(1.1);
}
.modal-wrapper .empty {
  position: relative;
  width: 100%;
  height: 10vh;
}
.modal-wrapper .breadcrumbs {
  background-color: #f8f8fa;
  padding: 16px 18px;
}

.modal-wrapper .breadcrumbs .breaditem {
  color: #151515;
}

.modal-wrapper .ant-breadcrumb-separator {
  color: rgb(0, 0, 0) !important;
  opacity: 0.4;
}

.modal-wrapper .empty {
  position: relative;
  width: 100%;
  height: 10vh;
}
.modal-wrapper .empty-bottom {
  position: relative;
  width: 100%;
  height: 900px;
  background-color: rgba(21, 21, 21, 0.85);
}
@media screen and (max-width: 700px) {
  .modal-wrapper .breadcrumbs {
    padding: 16px 0px;
  }
}

@media screen and (max-width: 500px) {
  .modal-wrapper .breadcrumbs {
    padding: 16px 0px;
    transform: translateX(-30px);
  }
}
