.indicator-content {
  display: flex;
  padding: 0 1rem;
}

.indicator-graphs {
  position: relative;
  background-color: white;
  padding: 60px;
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 60px;
  flex: 0.7;
}
.indicator-graphs-footer {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.indicator-graphs-footer .share {
  text-decoration-line: none;
  width: 200px;
  font-style: normal;
  font-weight: 600;
  justify-content: space-around;
  font-size: 14px;
  line-height: 20px;
  color: #151515;
  display: flex;
  background-color: rgba(255, 255, 255, 0.671);
  padding: 5px;
}
.indicator-graphs-footer .share div img {
  cursor: pointer;
  transition: 0.2s ease-in;
}
.indicator-graphs-footer .share div img:hover {
  transform: scale(1.3);
}

.indicator-graphs .indicator-chart-section {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.indicator-graphs .indicator-chart-section .chart-section-title {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 32px;
  text-transform: uppercase;
  color: #151515;
  padding: 0;
}

.indicator-graphs .indicator-chart-section .chart-section-category {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #151515;
  margin-top: -20px;
}

.indicator-graphs .indicator-chart-section .indicator-chart img {
  max-width: 100%;
  display: block;
  margin: auto;
}
.indicator-related {
  position: relative;
  background-color: rgb(253, 253, 253);
  padding: 25px;
  flex: 0.3;
}

.indicator-related .download-data-btn {
  display: block;
  background-color: #0e9898;
  position: relative;
  width: 100%;
  text-align: center;
  color: white;
  padding: 1.5rem;
  cursor: pointer;
  transition: 0.2s ease-in;
  max-width: 400px;
}

.indicator-related .download-data-btn:hover {
  background-color: #000000;
}
.indicator-related .download-data-btn > span {
  font-size: 16px;
  line-height: 24px;
  color: white;
  font-weight: 600;
}

.indicator-title {
  display: block;
  padding-top: 1.5rem;
  position: relative;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 2.45px;
  line-height: 32px;
  text-transform: uppercase;
  color: #0e9898;
}
.indicator-title::after {
  content: '';
  position: absolute;
  width: 20%;
  height: 2px;
  background-color: #0e9898;
  bottom: 0;
  left: 0;
}

.indicator-related-components {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  padding-left: 0;
  padding-bottom: 60px;
}

.indicator-related-components .indicator-related-card {
  max-height: 150px;
  cursor: pointer;
  padding: 15px;
  display: flex;
  padding-left: 4px;
  cursor: pointer;
  transition: 0.2s ease;
}
.indicator-related-components .indicator-related-card > div:nth-child(2) {
  flex: 0.5;
}

.indicator-related-components .indicator-related-card > img {
  max-width: 100%;
  display: block;
  width: 100%;
}
.indicator-related-components .indicator-related-card:hover {
  box-shadow: 2px 9px 35px -11px rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 2px 9px 35px -11px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 2px 9px 35px -11px rgba(0, 0, 0, 0.37);
  box-sizing: border-box;
  cursor: pointer;
}
.indicator-related-components .indicator-related-card .indicator-related-card-title {
  flex: 0.5;
  letter-spacing: 1.2px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
  color: #151515;
}

.indicator-related-reports {
  padding: 10px;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
}

.indicator-related-reports .indicator-report {
  display: flex;
  justify-content: center;
  gap: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16.8px;
  color: #151515;
  max-height: 150px;
  overflow: hidden;
}

.indicator-related-reports .indicator-report:hover {
  box-shadow: 2px 9px 35px -11px rgba(0, 0, 0, 0.37);
  -webkit-box-shadow: 2px 9px 35px -11px rgba(0, 0, 0, 0.37);
  -moz-box-shadow: 2px 9px 35px -11px rgba(0, 0, 0, 0.37);
  box-sizing: border-box;
  cursor: pointer;
}
.indicator-related-reports .indicator-report p {
  padding-top: 0.5rem;
  padding-left: 5px;
  flex: 0.5;
  letter-spacing: 1.2px;
}
.indicator-related-reports .indicator-report > div {
  padding: 0;
  position: relative;
  flex: 0.5;
}
.indicator-related-reports .indicator-report > div > img {
  max-height: 150px;
  object-fit: cover;
  display: block;
  max-width: 100%;
  width: 100%;
}
.indicator-themes {
  display: grid;
  grid-template-columns: repeat(2, minmax(50px, 1fr));
  grid-gap: 20px;
  padding-top: 10px;
  padding-bottom: 30px;
}

h3.target {
  padding-top: 20px;

  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  color: #151515;
}

p.decent {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;

  color: #151515;
}

.indicator-filters-wrapper {
  position: relative;
  width: 100%;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 0px;
  background-color: white;
}
.indicator-filters-wrapper .button-filters {
  position: relative;
  display: flex;
  gap: 15px;
}
.indicator-filters-wrapper .button-filters button {
  display: flex;
  align-items: center;
  gap: 9px;
  padding: 12px 16px;
}
.indicator-filters-wrapper .button-filters button:hover {
  border: 1px solid #0e9898;
}
.indicator-filters-wrapper .button-filters button .btn-filter-title {
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #0e9898;
}
.indicator-filters-wrapper .button-filters button .btn-filter-title span {
  text-transform: none;
}
.indicator-tab-filters {
  position: relative;
  width: 100%;
  padding: 0px;
  padding-top: 20px;
  display: flex;
  gap: 30px;
}

.indicator-tab-filters .filters-tabpane {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #6b6b6b;
}
.indicator-tab-filters .filters-tabpane:hover {
  color: #0e9898 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0e9898 !important;
  border: #0e9898 !important ;
}
.ant-tabs-tab {
  border: #0e9898 !important ;
  outline: #0e9898 !important ;
}

.ant-tabs-tab-btn:hover {
  color: #0e9898;
}
.indicator-tab-filters button {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 9px;
  padding: 12px 16px;
}

.indicator-tab-filters button .btn-filter-title {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #0e9898;
}
.indicator-tab-filters button:hover {
  border: 1px solid #0e9898;
}

.map-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

.bar-chart {
  padding-top: 30px;
}

.select-options {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.select-options .sel-opts {
  position: relative;
  display: flex;
  gap: 10px;
}
@media screen and (max-width: 370px) {
  .select-options .sel-opts {
    flex-wrap: wrap;
  }
}
/*SPINNER**/
.indicator-page-spinner {
  position: relative;
  width: 100%;
  height: 300px;
  background: white;
  display: grid;
  place-content: center;
}

/*ERROR**/
.indicator-page-error {
  position: relative;
  width: 100%;
  height: 300px;
  background: white;
  display: grid;
  place-content: center;
  color: rgb(0, 0, 0);
}

/*NO data*/

.related-unavailable {
  position: relative;
  width: 100%;
  background-color: white;
  height: 150px;
  display: grid;
  place-content: center;
  font-size: 18px;
}

.indicator-messages {
  height: 300px;
  display: grid;
  place-content: center;
  font-weight: 500;
}

/*NO DATA*/
.indicator-page-no-data {
  padding-top: 3rem;
  position: relative;
  width: 100%;
}
.indicator-page-no-data img {
  display: block;
  max-width: 100%;
  margin: auto;
}
.indicator-filters-wrapper .button-filters .year-filters {
  width: 28rem;
  display: flex;
  gap: 10px;
}
.indicator-filters-wrapper .button-filters .dropdown-button-wrapper {
  display: flex;
  gap: 10px;
}
.indicator-filters-wrapper .button-filters .dropdown-button-wrapper .dropdown-filter {
  font-size: 17px;
  color: #0e9898;
  display: grid;
  place-content: center;
}
.indicator-filters-wrapper .button-filters .year-filters .start-year,
.end-year {
  width: 9rem;
}

.indicator-filters-wrapper .button-filters .year-filters .start-year {
  color: #0e9898;
}
.indicator-filters-wrapper .button-filters .year-filters .end-year {
  color: #0e9898;
}

@media screen and (max-width: 1500px) and (min-width: 1200px) {
  /* .indicator-content {
    margin-right: 70px;
  }
  .indicator-graphs {
    flex: 0.5;
  }
  .indicator-related {
    flex: 0.6;
  } */

  /* .indicator-related-components {
    flex-direction: row;
  }
  .indicator-related-components .flexLayout {
    padding: 2rem;
    padding-top: 10px;
  }
  .indicator-related-reports {
    flex-direction: row;
  }
  .indicator-themes {
    grid-template-columns: repeat(3, minmax(50px, 1fr));
  } */
}
@media screen and (max-width: 1200px) and (min-width: 900px) {
  .indicator-related .download-data-btn {
    padding: 0.4rem;
  }
  .indicator-related-reports {
    flex-direction: row;
  }

  .indicator-related-components .flexLayout {
    padding: 2rem;
    padding-top: 10px;
  }
  .indicator-related-components .flexLayout .layout-space {
    grid-gap: 5px;
  }
  .indicator-related-components .card-wrapper {
    height: 200px !important;
  }
  .indicator-related-components .card-wrapper .chart-title {
    font-size: 14px;
  }
  .indicator-related-components .card-wrapper .chart-ratio {
    font-size: 17px;
  }
}

@media screen and (max-width: 1200px) {
  .indicator-graphs {
    padding-right: 1rem;
  }
  .indicator-content {
    flex-direction: column;
  }
  .indicator-related-components {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 500px));
  }
}

@media screen and (max-width: 800px) {
  .indicator-filters-wrapper {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .indicator-filters-wrapper .button-filters button {
    gap: 9px;
    padding: 6px 8px;
    width: 72%;
    max-width: 300px;
  }
  .indicator-filters-wrapper .button-filters .dropdown-button-wrapper {
    flex-wrap: wrap;
  }
  .indicator-filters-wrapper .button-filters {
    gap: 10px;
    display: grid;
  }
  .indicator-content {
    padding: 0 1.5rem;
  }

  .indicator-graphs {
    padding-left: 0rem;
    padding-right: 0rem;
    flex: 1;
  }
  .indicator-related {
    padding: 0;
  }
  .indicator-related-components {
    padding: 0;
    padding-top: 1.5rem;
    grid-template-columns: repeat(1, minmax(200px, 700px));
  }
  .indicator-related-components .chart-card-wrapper.larger {
    max-width: 800px;
  }
  .indicator-related-components .chart-card-wrapper .chart-button-present {
    position: absolute;
    margin-top: 129px;
  }
  .indicator-related .download-data-btn {
    padding: 1rem;
  }
  .indicator-related-reports {
    display: grid;
    grid-template-columns: repeat(1, minmax(300px, 1fr));
  }
  .indicator-related-reports .single-report-wrapper {
    max-width: 700px;
  }
}
.indicator-related-components .chart-card-wrapper .chart-button-present {
  position: absolute;
  margin-top: 129px;
}
@media screen and (max-width: 500px) {
  .indicator-filters-wrapper .button-filters .dropdown-button-wrapper {
    display: grid;
  }
}

@media screen and (max-width: 390px) {
  .indicator-filters-wrapper {
    padding: 0 1.5rem;
  }
  .indicator-filters-wrapper .button-filters {
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: auto;
  }
  .indicator-filters-wrapper .button-filters button {
    gap: 6px;
    width: 75%;
    padding: 3px 4px;
  }
  .indicator-filters-wrapper .year-filters {
    display: flex;
  }
}

.download-csv-btn-map {
  position: relative;
  transition: 0.1s ease-in;
  cursor: pointer;
}

.download-csv-btn-map:hover {
  transform: scale(1.4);
}
