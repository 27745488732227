.indicator-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  padding: 25px 65px;
  padding-bottom: 10px;
}

.indicator-wrapper .header {
  width: 100%;
  position: relative;
}

.add-indicator-container {
  display: flex;
  justify-content: 'center';
  align-items: 'center';
  padding-top: 25px;
  padding-bottom: 4.5rem;
}

.add-indicator-container a {
  margin: auto;
  box-sizing: border-box;
  display: inline-flex;
  font-size: 14px;
  line-height: 21px;
  color: rgb(0, 0, 0);
  padding: 16px 48px;
  transition: 0.4s ease-in;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}

.add-indicator-container.height-min {
  min-height: 400px;
}

.add-indicator-container > a:hover > span:nth-child(2) {
  color: rgba(0, 0, 0, 0.733);
}

.add-indicator-container a.new:hover {
  color: rgba(0, 0, 0, 0.733);
}

.add-indicator-container.active a {
  color: #151515;
  background-color: white;
}

.add-indicator-btn {
  font-size: 48px;
  font-weight: 700;
  padding: 30px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 4.5rem;
}
.add-indicator-btn a {
  margin: auto;
  box-sizing: border-box;
  display: inline-flex;
  font-size: 14px;
  line-height: 21px;
  color: rgb(0, 0, 0);
  padding: 16px 48px;
  transition: 0.4s ease-in;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}
.add-indicator-btn > a:hover > span:nth-child(2) {
  color: rgba(0, 0, 0, 0.733);
}

.add-indicator-btn a.new:hover {
  color: rgba(0, 0, 0, 0.733);
}

.add-indicator-btn.active a {
  color: #151515;
  background-color: white;
}
.theme-subheader-wrapper.explorer-subheader {
  height: auto;
}
.theme-subheader-wrapper.explorer-subheader .theme-subheader-title {
}

/*MODAL styling*/

@media screen and (max-width: 1024px) {
  .indicator-wrapper {
    flex-direction: column;
  }

  .indicator-wrapper .filter-button {
    position: relative;
    display: inline-block;
    font-size: 20px;
    padding-bottom: 1rem;
    padding-top: 1rem;
  }

  .indicator-wrapper .filter-button span {
    position: relative;
    display: inline;
    padding: 0.3rem 0.5rem;
    max-width: 120px;
    display: flex;
    background-color: #d9d9d9;
    align-items: center;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
  }
}

@media screen and (max-width: 700px) {
  .indicator-wrapper {
    flex-direction: column;
    padding: 20px 1.5rem;
    padding-bottom: 10px;
  }
  .theme-subheader-wrapper.explorer-subheader {
    height: 100px;
  }
  .indicator-wrapper .filter-button span {
    padding: 0.1rem 0.2rem;
    max-width: 100px;
    font-size: 18px;
  }
  .download-img-modal .ant-modal-body {
    padding: 10px !important;
  }
  .add-indicator-container.height-min {
    min-height: 600px;
  }
}

@media screen and (max-width: 500px) {
  .indicator-wrapper .filter-button {
    padding-bottom: 1rem;
  }
  .indicator-wrapper .filter-button span {
    padding: 0.1rem 0.3rem;
    max-width: 100px;

    font-size: 16px;
  }
}
