.subcategory-wrapper {
  position: relative;
  width: 100%;
  padding: 0.7rem 4rem;
  background: #fff;
}
.noIndicator-error {
  display: block;
  text-align: center;
  font-size: 25px;
  line-height: 32px;
}

.subcategory-indicator-cards {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  position: relative;
  width: 100%;
}

@media screen and (max-width: 999px) and (min-width: 700px) {
  .subcategory-indicator-cards {
    padding-left: 60px;
    display: grid;
    column-gap: 20px;
    row-gap: 2rem;
    grid-template-columns: repeat(2, minmax(200px, 600px));
  }
}

@media screen and (max-width: 700px) {
  .subcategory-wrapper {
    padding: 15px 1.5rem;
  }

  .subcategory-indicator-cards {
    display: grid;
    column-gap: 20px;
    row-gap: 2rem;
    grid-template-columns: repeat(1, minmax(200px, 600px));
  }
}

.subcategory-indicator-cards-res {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  gap: 20px;
  width: 90vw;
  margin: auto;
  scroll-snap-type: x mandatory;
  padding: 1.5rem;
  position: relative;
}

.subcategory-indicator-cards-res article {
  scroll-snap-align: start;
  width: 80vw;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  max-width: 150px;
}

.subcategory-indicator-cards-res article > div {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.subcategory-indicator-cards-res .chart-button {
  display: none;
}
