.home-tabs-container {
  position: relative;
  width: 100%;
  padding: 3.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  justify-content: center;
  gap: 20px;
}

.home-tabs-container section {
  padding: 12px;
  flex: 0.5;
  background: rgb(245, 245, 245);
  font-size: 34px;
  font-weight: 700;
  letter-spacing: 2px;
  color: black;
  transition: ease-in 0.3s;
}

.home-tabs-container section:hover {
  transform: scale(1.01);
  cursor: pointer;
  background: #0e9898;
  color: white;
}

.home-tabs-container section.active {
  background: #0e9898;
  color: white;
}

@media screen and (max-width: 700px) {
  .home-tabs-container {
    padding: 1rem 1.5rem;
  }
  .home-tabs-container section {
    font-size: 24px;
  }
}

@media screen and (max-width: 400px) {
  .home-tabs-container section {
    font-size: 16px;
  }
}
