.category-section-wrapper {
  position: relative;
  width: 100%;
  padding: 0.7rem 4rem;
  background: #fff;
}
.category-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
}

.category-section-header .text {
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 32px;
  color: #151515;
}
.category-section-header .view-all-btn {
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #151515;
  gap: 8px;
  transition: ease-in 0.3s;
}
.category-section-header .view-all-btn:hover {
  color: #0e9898;
}

@media screen and (max-width: 700px) {
  .category-section-wrapper {
    padding: 0.7rem 1.5rem;
  }

  .category-section-header {
    padding-right: 1.3rem;
  }
  .category-section-header .text {
    font-size: 20px;
    line-height: 30px;
  }
  .category-section-header .view-all-btn {
    font-size: 15px;
    line-height: 24px;
  }
}

.category-section-wrapper .categ-row {
  padding: 20px;
  margin: auto;
  padding-bottom: 0px;
}

@media screen and (max-width: 500px) {
  .category-section-wrapper {
    position: relative;
    width: 100%;
    padding: 15px 1.5rem;
    background: #fff;
  }
  .category-section-header {
    padding-right: 1.2rem;
  }
}

@media screen and (max-width: 400px) {
  .category-section-header {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}
