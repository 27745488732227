.employment-indicators-wrapper {
  color: #212121;
  padding-left: 60px;
  padding-right: 60px;
  /* background-image: url('./employmentBg.png');
  background-repeat: no-repeat;
  background-size: cover; */
  background-color: rgb(245, 245, 245);
  padding-top: 1rem;
  /* background-position: top; */
  /* min-height: 500px; */
  padding-bottom: 2rem;
  margin-right: 3.5rem;
  margin-left: 3.5rem;
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.employment-indicators-wrapper main {
  width: 100%;
  position: relative;
  padding-left: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  grid-gap: 0rem;
}

section h1.text a {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 46px;
  color: black;
}
section h1.text {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 46px;
}
section h1.text a {
  color: #212121;
  text-decoration: none;
  transition: 0.1s ease;
}
section h1.text a:hover {
  color: #004444;
}

@media screen and (max-width: 764px) {
  .employment-indicators-wrapper {
    margin-right: 0rem;
    margin-left: 0rem;
  }
}

.employment-indicators-wrapper .explore-ind {
  position: relative;
  padding: 2rem;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
  padding-left: 0;
  letter-spacing: 2.8px;
}
.employment-indicators-wrapper .explore-ind::before {
  content: '';
  position: absolute;
  width: 3px;
  background-color: rgba(211, 211, 211, 0.644);
  height: 90%;
  top: 30px;
  right: 0;
}
.employment-indicators-wrapper .explore-ind span {
  position: relative;
  display: block;
  padding-bottom: 1rem;
  color: #0e9898;
  width: 150px;
}
.employment-indicators-wrapper .explore-ind span::after {
  content: '';
  position: absolute;
  left: 0;
  height: 2px;
  width: 40px;
  bottom: 0;
  background-color: #0e9898;
}
.employment-indicators-wrapper .column {
  display: flex;
}

.employment-indicators-wrapper .text {
  padding-top: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  z-index: 3;
}

.employment-indicators-wrapper .text h1 {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 46px;
}
.employment-indicators-wrapper .text h1 a {
  color: #212121;
  text-decoration: none;
  transition: 0.1s ease;
}
.employment-indicators-wrapper .text h1 a:hover {
  color: #004444;
}
.employment-indicators-wrapper .text h3 {
  color: #212121;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 41px;
  text-transform: none;
}
.employment-indicators-wrapper .indicators-wrapper {
  position: relative;
  width: 80%;
  padding-top: 5px;
  display: grid;
  grid-template-columns: repeat(1, 250px);
}
.employment-indicators-wrapper .indicators-wrapper .indicator {
  color: rgba(0, 0, 0, 0.85);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.15s ease-in;
}

.employment-indicators-wrapper .indicators-wrapper .indicator:hover {
  color: #0e9898;
}
.modules-spinner {
  position: relative;
  width: 100%;
  height: 70vh;
  display: grid;
  place-content: center;
}

/*Indicator section grid*/

@media screen and (max-width: 500px) {
  .employment-indicators-wrapper {
    flex-direction: column;
  }
  .employment-indicators-wrapper main {
    padding-left: 0rem;
  }
  .employment-indicators-wrapper .explore-ind::before {
    top: unset;
    width: 100%;
    height: 3px;
    bottom: 10px;
  }
}

@media screen and (max-width: 960px) {
  .employment-indicators-wrapper .indicators-wrapper {
    width: 80%;
  }
}

@media screen and (max-width: 764px) {
  .employment-indicators-wrapper .indicators-wrapper {
    width: 100%;
    margin: auto;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1215px) {
  .employment-indicators-wrapper .indicators-wrapper {
    width: 80%;
  }
}

/*ADJUST BACKGROUND IMAGE*/

@media screen and (max-width: 800px) {
  .employment-indicators-wrapper {
    background-size: 125% 78%;
    background-position: bottom;
  }
}
@media screen and (max-width: 700px) {
  .employment-indicators-wrapper {
    background-size: contain;
    background-position: bottom;
  }
  .employment-indicators-wrapper .text {
    font-size: 18px;
  }
  section h1.text a {
    font-size: 18px;
  }
}

@media screen and (max-width: 630px) {
  .employment-indicators-wrapper .indicators-wrapper {
    padding-top: 20px;
    grid-template-columns: repeat(1, 300px);
  }
  .employment-indicators-wrapper .text h3 {
    font-weight: 600;
    font-size: 22px;
    line-height: 34px;
  }
}

@media screen and (max-width: 700px) {
  .employment-indicators-wrapper {
    padding: 1.5rem;
  }
  .employment-indicators-wrapper .column .explore-ind {
    font-size: 18px;
    line-height: 18px;
    padding-right: 1rem;
  }
  .employment-indicators-wrapper .text h1 {
    font-size: 24px;
    line-height: 31px;
    padding-top: 0.8rem;
  }
}

@media screen and (max-width: 400px) {
  .employment-indicators-wrapper .indicators-wrapper {
    position: relative;
    width: 100%;
    padding-top: 0;
    grid-template-columns: repeat(1, 200px);
  }

  .employment-indicators-wrapper .indicators-wrapper .indicator {
    font-size: 13px;
  }
}

@media screen and (max-width: 700px) {
  .employment-indicators-wrapper main {
    display: flex;
    overflow: scroll;
    scroll-snap-type: x mandatory;
  }

  .employment-indicators-wrapper main section {
    scroll-snap-align: start;
  }
  .employment-indicators-wrapper .explore-ind {
    font-size: 16px;
  }
  .employment-indicators-wrapper .explore-ind span {
    width: 200px;
  }
}
