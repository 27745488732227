.all-themes-wrapper {
  background-color: white;
}

section.all-themes-list {
  position: relative;
  width: 100%;
  padding-left: 60px;
  padding-top: 1rem;
  padding-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(6, minmax(100px, 450px));
  grid-gap: 10px 20px;
  padding-right: 60px;
}

@media screen and (max-width: 1150px) {
  section.all-themes-list {
    grid-template-columns: repeat(3, minmax(100px, 450px));
  }
}

@media screen and (max-width: 700px) {
  section.all-themes-list {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    grid-template-columns: repeat(auto-fill, minmax(100px, 450px));
  }
}

@media screen and (max-width: 700px) and (min-width: 500px) {
  section.all-themes-list {
    grid-template-columns: repeat(2, minmax(100px, 350px));
  }
}
