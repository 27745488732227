@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,400;0,600;0,700;1,200&display=swap');

* {
  font-family: 'Source Sans Pro', sans-serif !important;
}

.home-content {
  background-color: white;
  max-width: 1580px;
  overflow-x: hidden;
}
.home-intro-content {
  padding: 0 3.5rem;
  padding-top: 1.5rem;
}

@media screen and (max-width: 1280px) {
  .home-intro-content {
    padding: 0 3.5rem;
  }
}

@media screen and (max-width: 700px) {
  .home-intro-content {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: 0.3rem;
  }
}

.home-intro-content h1 {
  color: #0e9898;
  margin: 0;
}
.home-intro-insights {
  font-size: 20px;
  font-weight: bold;
  line-height: 21px;
  letter-spacing: 2px;
  color: black;
  margin-top: -10px;
}

@media screen and (max-width: 390px) {
  .home-intro-content h1 {
    font-size: 22px !important;
  }
}

@media screen and (max-width: 364px) {
  .home-intro-content h1 {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 610px) and (min-width: 450px) {
  .home-intro-content h1 {
    font-size: 28px;
    line-height: 46px;
    margin-bottom: -10px;
  }
  .home-intro-insights {
    margin-top: 10px;
  }
}

@media screen and (max-width: 450px) {
  .home-intro-content h1 {
    margin-top: -10px;
    font-size: 24px;
    line-height: 36px;
  }

  .home-intro-insights {
    font-size: 14px;
    margin-top: -2px;
    line-height: 1;
    letter-spacing: 0px;
  }
}

@media screen and (max-width: 380px) and (min-width: 350px) {
  .home-intro-content h1 {
    font-size: 19px;
  }
  .home-intro-insights {
    font-size: 12px;
    margin-top: -3px;
  }
}

@media screen and (max-width: 350px) {
  .home-intro-content h1 {
    font-size: 18px;
  }
  .home-intro-insights {
    font-size: 12px;
    margin-top: -6px;
  }
}

/* Admin Dashboard header font styling*/
.ant-table-thead > tr > th {
  font-weight: 600 !important;
  font-size: 17px !important;
}

.admin-table-row {
  font-size: 16px;
}

.ant-modal-body {
  font-size: 16px !important;
}
.ant-modal-body input {
  font-size: 16px;
}

.ant-modal-body .ant-form-item {
  font-size: 16px;
}

img {
  max-inline-size: 100%;
  block-size: auto;
}

.ant-table-wrapper {
  overflow-x: scroll;
}
.ant-table {
  min-width: 1200px;
}

html {
  -webkit-print-color-adjust: exact;
}

.explore-ind-test {
  position: relative;
  padding: 2rem;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 21px;
  font-weight: 700;
  padding-left: 0rem;
  letter-spacing: 2.8px;
}

.explore-ind-test span {
  position: relative;
  display: block;
  padding-bottom: 1rem;
  color: #0e9898;
  width: 300px;
}
.explore-ind-test span::after {
  content: '';
  position: absolute;
  left: 0;
  height: 2px;
  width: 40px;
  bottom: 0;
  background-color: #0e9898;
}

@media screen and (max-width: 700px) {
  .explore-ind-test {
    font-size: 16px;
    padding-left: 1.5rem;
  }
}

@media screen and (max-width: 1280px) {
  .explore-ind-test.theme {
    padding-left: 3.5rem;
  }
}
@media screen and (max-width: 700px) {
  .explore-ind-test.theme {
    padding-left: 1.5rem;
  }
  .explore-ind-test.report {
    padding-left: 0rem;
  }
  .explore-ind-test {
    padding-top: 1rem;
    padding-bottom: 0rem;
  }
}
