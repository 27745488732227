.reports-container {
  position: relative;
  width: 100%;
  background-color: white;
  color: black;
  padding: 20px 60px;
  padding-bottom: 2px;
  padding-top: 1.5rem;
}

.reports-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  padding: 61px 65px;
  padding-bottom: 10px;
}

.reports-wrapper .header {
  width: 100%;
  position: relative;
}

.reports-container > h1.title {
  font-size: 48px;
  font-weight: 700;
  line-height: 57.6px;
  color: rgb(33, 33, 33);
}
.reports-content {
  position: relative;
  width: 100%;
  padding: 2px 60px;
  display: flex;
}

.reports-content > .reports {
  flex: 0.8;
}

/*Filters*/

.done-filter {
  padding-top: 0.7rem;
  display: flex;
  padding-left: 0.7rem;
  transition: 0.1s ease;
  justify-content: space-between;
  width: 70%;
  max-width: 200px;
}
.done-filter span {
  cursor: pointer;
  color: black;
  background-color: #d9d9d9;
  padding: 0.4rem 1rem;
}

@media screen and (max-width: 1024px) {
  .reports-content > .reports {
    flex: 1;
  }
}

@media screen and (max-width: 700px) {
  .reports-content {
    padding: 2px 1.5rem;
  }
}

.publication-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding-left: 5px;
  text-align: start;
  padding: 0 1rem;
}

.publication-date {
  font-size: 12.8px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.6px;
  margin-top: 20px;
  text-align: start;
  padding-left: 5px;
  width: 100%;
  color: #424242;
  padding: 0 1rem;
  text-transform: uppercase;
}

.publication-date:hover,
.publication-title:hover {
  text-decoration: underline;
  text-decoration-color: rgba(10, 105, 194, 0.25);
}

.reports-publications {
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 370px));
  grid-gap: 2rem;
  padding-top: 35px;
  flex: 0.8;
  position: relative;
  width: 100%;
  padding-bottom: 70px;
}

@media screen and (min-width: 1500px) {
  .reports-publications {
    grid-template-columns: repeat(auto-fill, 1fr);
  }
}

@media screen and (max-width: 1500px) and (min-width: 1201px) {
  .reports-publications {
    padding-right: 0;
    grid-template-columns: repeat(3, minmax(200px, 400px));
  }
}

@media screen and (max-width: 1200px) and (min-width: 700px) {
  .reports-publications {
    padding: 0;
    padding-top: 3rem;
    grid-template-columns: repeat(2, minmax(200px, 450px));
  }
}

@media screen and (max-width: 700px) and (min-width: 500px) {
  .reports-publications {
    padding: 0;
    padding-top: 3rem;
    grid-template-columns: repeat(2, minmax(200px, 360px));
  }
}

@media screen and (max-width: 500px) {
  .reports-publications {
    padding: 0;
    padding-top: 3rem;
    grid-template-columns: repeat(1, minmax(200px, 500px));
  }
}

.reports-container > p.subtitle {
  color: #626161;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  margin-top: -20px;
}

.ant-card-grid {
  float: left;
  width: auto !important;
  min-height: 300px;
  max-height: 300px;
  min-width: 160px;
  max-width: 160px;
  padding: 0 !important;
  margin: 12px;
  margin-top: 30px;
  text-align: center;
  border: none;
  box-shadow: none !important;
  transition: all 0.3s;
}

.ant-image {
  min-width: fit-content;
  min-height: fit-content;
}
.publications-pagination {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
}

.publications-pagination .ant-pagination-item-active {
  font-weight: 500;
  font-size: medium !important;
  width: 30px !important;
  height: 30px !important;
  background: #fff;
  border: 1px solid #666666 !important;
  color: #666666 !important;
}

.publications-pagination .ant-pagination-item {
  font-weight: 500;
  font-size: medium !important;
  width: 30px !important;
  height: 30px !important;

  background: #fff;
  border-radius: 0px !important;
  border: 1px solid #666666 !important;
  color: #666666 !important;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus-visible .ant-pagination-item-link {
  border: 1px solid #eeeeee !important;
  color: #eeeeee !important;
  cursor: not-allowed;
}

.publications-pagination .ant-pagination-prev,
.publications-pagination .ant-pagination-next,
.publications-pagination .ant-pagination-jump-prev,
.publications-pagination .ant-pagination-jump-next {
  display: inline-block;
  width: 30px !important;
  height: 30px !important;
  font-size: medium;
  color: rgba(0, 0, 0, 0.85);
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border: 1px solid #d9d9d9c4;
  border-radius: 0px !important;
  cursor: pointer;
  transition: all 0.3s;
}

@media screen and (max-width: 750px) {
  .reports-heading > h1.title {
    font-size: 35px;
  }
}

@media screen and (max-width: 750px) {
  .reports-wrapper {
    flex-direction: column;
  }
}

.screen-reports {
  position: relative;
  width: 100%;
  flex: 0.8;
  display: flex;
  flex-direction: column;
}

.publication-card {
  height: 280px;
  cursor: pointer;
  transition: 0.1s ease-in;
  position: relative;
  box-shadow: 10px 10px 20px -3px rgba(0, 0, 0, 0.34);
  -webkit-box-shadow: 10px 10px 20px -3px rgba(0, 0, 0, 0.34);
  -moz-box-shadow: 10px 10px 20px -3px rgba(0, 0, 0, 0.34);
}

.publication-card:hover {
  transform: scale(1.05);
}

.noReports-image {
  padding: 3rem;
  position: relative;
  width: 100%;
  text-align: center;
}
.noReports-image img {
  display: block;
  max-width: 50%;
  margin: auto;
}

.filter-sort {
  width: 100%;
  padding-left: 1px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media screen and (min-width: 1024px) {
  .filter-sort {
    justify-content: flex-end;
  }
}
@media screen and (max-width: 390px) {
  .filter-sort {
    flex-direction: column;
    gap: 2rem;
    align-items: flex-start;
  }
}
