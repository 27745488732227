.related-indicators-wrapper {
  position: relative;
  width: 100%;
  background-color: rgb(255, 255, 255);
  height: 350px;
}

.related-indicators-wrapper .header {
  padding: 15px 20px;
  padding-bottom: 5px;
  padding-left: 28px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.related-indicators-wrapper .pagination .arrows {
  position: absolute;
  bottom: 10px;
  display: flex;
  justify-content: center;
  gap: 30px;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
  margin-left: 130px;
}
.related-indicators-wrapper .pagination .arrows > div {
  cursor: pointer;
  transition: ease;
}
.related-indicators-wrapper .header .pagination .arrows > div:hover {
  transform: scale(1.1);
}
.related-indicators-wrapper .header h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 35px;
  line-height: 49px;
  text-transform: uppercase;
}
.related-indicators-wrapper .indicatorsCharts-wrapper {
  width: 100%;
  position: relative;
  display: flex;
}
.indicatorsCharts-wrapper .indicatorsCharts-filters {
  position: relative;
  width: 30%;
  height: auto;
  margin-top: -30px;
}
.indicatorsCharts-wrapper .indicatorsCharts-filters .subcategory-filter {
  display: block;
  padding: 14px;
  padding-top: 5px;
  padding-left: 28px;
  padding-bottom: 1px;
  font-weight: 500;
  font-size: 20px;
}
.indicatorsCharts-wrapper .indicatorsCharts-filters section {
  display: flex;
  flex-direction: column;
  height: 400px;
  padding: 20px;
  padding-top: 0px;
}

.related-indicators-wrapper .indicatorsCharts {
  position: relative;
  width: 100%;
  padding: 10px;
}

.pagination-wrapper {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, minMax(150px, 1fr));
  gap: 2px;
  align-content: space-between;
}

/*Search btn*/
.indicator-search-btn {
  padding-right: 13px;
}
.indicator-search-btn .s-input input {
  width: 200px;
  outline: none;
  border: none;
  border: 1px solid #0e9898;
  padding: 8px 15px;
  font-size: 14px;
  border-radius: 20px;
}

.modal-search img {
  overflow: hidden;
  max-height: 250px;
  display: block;
  max-width: 100%;
  margin: auto;
}

.modal-search {
  width: 400%;
  max-height: 300px;
  margin-left: 120px;
  margin-top: -30px;
}
.indicators-checkbox {
  display: flex;
  flex-direction: column;
  width: 240px;
}

.indicator-or {
  display: block;
  font-size: 16px;
  margin-top: 5px;
  font-weight: 400;
}

.no-subcategories-modal {
  display: block;
  padding-left: 10px;
  margin-top: 30px;
}

@media screen and (max-width: 1100px) {
  .pagination-wrapper {
    grid-template-columns: repeat(auto-fit, minMax(150px, 1fr));
    max-height: 280px;
    grid-gap: 20px;
    overflow-y: scroll;
    border: 1px solid #e1e1e169;
  }
  .modal-search {
    width: 100%;
    max-height: 290px;
    margin-left: 2px;
    margin-top: -2px;
  }
  .related-indicators-wrapper {
    height: 400px;
  }
}

@media screen and (max-width: 700px) {
  .pagination-wrapper {
    grid-template-columns: repeat(2, minMax(150px, 1fr));
    max-height: 280px;
    overflow-y: scroll;

    padding-top: 1.5rem !important;
  }
  .pagination-wrapper .indicator-chart-card-wrapper {
    max-width: 230px;
  }

  .related-indicators-wrapper .indicatorsCharts-wrapper {
    flex-direction: column;
  }

  .reset-filt {
    margin-top: 2rem;
    margin-left: 0.6rem;
    display: inline-block;
    padding: 0.2rem 0.4rem;
    background-color: #d9d9d9;
    cursor: pointer;
  }

  .related-indicators-wrapper .indicatorsCharts {
    padding-top: 0;
  }
  /*Search btn*/
  .indicator-search-btn {
    padding-right: 15px;
  }
  .indicator-search-btn .s-input input {
    width: 10px;
    padding: 0 !important;
    font-size: 13px;
    border-radius: 10px;
  }
  .related-indicators-wrapper .header {
    padding-bottom: 10px;
    align-items: center;
    padding-left: 8px;
    background-color: rgb(250 250 250);
    flex-wrap: wrap;
  }
  .related-indicators-wrapper .header span > span.filt {
    display: inline-block;
    padding: 0.2rem 0.4rem;
    background-color: #d9d9d9;
    border-radius: 6px;
  }
  .modal-search {
    width: 100%;
    max-height: 175px;
    margin-left: 152px;
    margin-top: -2px;
  }
  .modal-search img {
    max-height: 150px;
    display: block;
    max-width: 100%;
    margin: auto;
  }
}

@media screen and (max-width: 500px) {
  .pagination-wrapper {
    padding-top: 1.5rem !important;
    display: flex;
    scroll-snap-type: x mandatory;
    grid-gap: 2rem;
    max-height: 280px;
    overflow-y: scroll;
  }
  .pagination-wrapper > div {
    scroll-snap-align: start;
    height: 200px;
    width: 240px;
  }
  .modal-search {
    width: 100%;
    max-height: 175px;
    margin-left: 100px;
    margin-top: -2px;
  }
}
@media screen and (max-width: 420px) and (min-width: 380px) {
  .modal-search {
    margin-left: 80px;
  }
}
@media screen and (max-width: 380px) {
  /* .pagination-wrapper {
    grid-template-columns: repeat(2, minMax(150px, 250px));
    max-height: 280px;
    place-content: center;
    overflow-y: scroll;
  } */
  .modal-search {
    width: 100%;
    max-height: 175px;
    margin-left: 100px;
    margin-top: -2px;
  }
}
