.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}

.logo {
  float: left;
  width: 120px;
  height: 70px;
  margin: 16px 24px 16px 0;
  display: flex;
  align-items: center;
}

.menu {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 50px;
  height: 100px;
  display: flex;
  align-items: center;
  float: right;
  text-transform: uppercase;
  color: #282828;
}

.right-menu {
  float: right;
  color: #282828;
}

.pic {
  position: absolute;
  width: 43.22px;
  height: 70px;
  left: 60px;
  top: 24px;
  mix-blend-mode: multiply;
}

.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.ant-menu-overflow-item.avatar-menu-item::after {
  border: none !important;
}

.borderless::after {
  opacity: 0;
}

.col-left-menu {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  margin-top: -15px;
  padding-right: 12px;
}

.col-left-menu .menu-dropdowns {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.header-dropdown {
  display: block;
  cursor: pointer;
  font-size: 30px;
  margin-top: 10px;
}

.search-icon:hover {
  transform: scale(1.2);
}

.header-icons {
  display: flex;
}

.header-category {
  position: relative;

  font-weight: 700;
  letter-spacing: 2.8px;
  text-transform: uppercase;
  padding-bottom: 12px;
}
.header-category::before {
  content: '';
  position: absolute;
  bottom: 0px;
  height: 2px;
  width: 50px;
}

/**HEADER SUBMENU*/

.data-submenu {
  position: relative;
  width: 100%;
  display: flex;
  height: 300px;
  margin: auto;
}

.data-submenu .data-by-topic {
  padding-top: 1rem;
  position: relative;
  flex: 0.15;
  font-size: 16px;
  max-width: 170px;
  text-transform: uppercase;
  font-weight: 700;
  padding-right: 1rem;
  color: #0e9898;
  line-height: 21px;
  letter-spacing: 2.45px;
  padding-bottom: 1rem;
}
.data-submenu .data-by-topic:nth-child(1) {
  padding-bottom: 0.5rem;
}
.data-submenu .data-by-topic:nth-child(2) {
  padding-top: 0.5rem;
}

.data-submenu .data-submenu-menus {
  position: relative;
}

.data-submenu .data-submenu-menus > div {
  cursor: pointer;
}

.data-submenu .data-submenu-menus > div > p:hover {
  color: #004040;
}
.data-submenu .data-submenu-menus::after {
  content: '';
  right: 0;
  top: 0;
  width: 3px;
  height: 80%;
  background-color: #e9e9e9b0;
  position: absolute;
}

.data-submenu .data-submenu-menus::before {
  content: '';
  right: 0;
  top: 0;
  width: 3px;
  height: 15%;
  background-color: #0e9898;
  position: absolute;
  z-index: 3;
}
.data-submenu .data-submenu-menus.institution::before {
  top: 54px;
}
.data-submenu .submenu-data-list {
  padding: 2rem;
  padding-left: 3rem;
  padding-top: 0;
  font-weight: 100;
}
.data-submenu .submenu-data-list .category-title {
  display: block;
  padding: 1rem;
  padding-left: 0;
  position: relative;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 2.45px;
  font-weight: 700;
  text-transform: uppercase;
  color: #0e9898;
  transition: 0.2s ease-in;
}

.data-submenu .submenu-data-list .category-title:hover {
  color: #004444;
}
.data-submenu .submenu-data-list .category-title::before {
  content: '';
  bottom: 0;
  width: 30px;
  height: 2px;
  background-color: #0e9898;
  position: absolute;
  left: 0;
}

.data-submenu .submenu-data-list .submodule-list {
  position: relative;
  display: flex;
  flex-direction: column;
}
.data-submenu .submenu-data-list .submodule-list > li {
  font-size: 16px;
  line-height: 19.2px;
  font-weight: 600;
  margin-top: 1rem;
  letter-spacing: 1.3px;
}
.data-submenu .submenu-data-list .submodule-list > li:hover {
  color: #0e9898;
}
.data-submenu .header-inst {
  position: relative;
}
.data-submenu .header-inst {
  position: relative;
  padding: 3.5rem;
  padding-top: 1rem;
  padding-left: 3rem;
  width: 100%;
  grid-template-columns: repeat(5, 200px);
}
.data-submenu .header-inst > p {
  display: inline-block;
  position: relative;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 2.45px;
  font-weight: 700;
  color: #0e9898;
  text-transform: uppercase;
  padding: 1rem;
  padding-left: 0;
  padding-top: 0;
}
.data-submenu .header-inst > p::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 20%;
  left: 0;
  height: 2px;
  background-color: #0e9898;
}

.data-submenu .header-inst-list {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  grid-gap: 1rem;
}

.data-submenu .header-inst-list a {
  font-size: 14px;
  font-weight: 600;
  transition: 0.1s ease-in;
}

.data-submenu .header-inst-list a:hover {
  color: #0e9898;
}
.data-submenu .header-inst-list a:hover {
}

.data-submenu .header-inst-list a span:nth-child(1) {
}

.submenu-theme {
  position: relative;
  transition: 0.1s ease-in;
}
.submenu-theme .theme-link {
  display: block;
}
.submenu-theme .theme-link p {
  font-weight: 700;
  letter-spacing: 1.8px;
}
.submenu-theme .theme-link picture img {
  background-color: rgb(128, 0, 255);
}
.submenu-theme:hover {
  transform: scale(1.05);
}

/*Themes*/

.row-header-wrapper {
  max-width: 1800px;
  margin: auto;
}

/*RESPONSIVENESS*/
.mobile-nav {
  display: none;
  background-color: white;
  width: 120%;
  transform: translateX(-16px);
  height: 100vh;
  position: fixed;
  z-index: 2000;
  top: 0;
}

.mobile-nav-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  height: 100vh;
  flex-direction: column;
}
.mobile-nav-wrapper .links {
  flex: 0.27;
  position: relative;
  width: 100%;
  background-color: rebeccapurple;
}
.mobile-nav-wrapper .content {
  flex: 0.73;
  position: relative;
  width: 100%;
  background-color: #0e9898;
}

.tab {
  height: 100vh;
  width: 100%;
  display: flex;
}
.mobile-nav-wrapper .ant-tabs-left > .ant-tabs-content-holder {
  border-left: none !important;
}
.mobile-nav-wrapper .ant-tabs-nav {
  flex: 0.27 !important;
}
.mobile-nav-wrapper .ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar {
  left: 0;
  background-color: #0e9898 !important;
  width: 4px;
}

.mobile-nav .tab-content {
  animation: animate 0.25s ease-in;
  height: 100vh;
}

@keyframes animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mobile-nav-wrapper .ant-tabs-tab {
  display: block !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin: 0 !important;
}

.mobile-nav-wrapper .ant-tabs-tab.ant-tabs-tab-active {
  background-color: aliceblue;
}
.mobile-nav-wrapper .ant-tabs-tab::before {
  top: 0;
  content: '';
  position: absolute;
  left: 0;
  height: 180%;
  width: 4px;
  background-color: rgb(0, 0, 0);
}
.mobile-nav-wrapper .tabcontent {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 4px 20px;
  justify-content: space-between;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  max-width: 100vw;
}
.mobile-nav-wrapper .mobile-logo {
  padding: 20px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100vw;
}
.mobile-nav-wrapper .mobile-logo img {
  max-width: 100%;
  display: block;
  width: 200px;
  height: 70px;
}
@media screen and (max-width: 400px) {
  .mobile-nav-wrapper .mobile-logo img {
    width: 150px;
    height: 50px;
  }
}
.mobile-nav-wrapper .ant-tabs-tab:nth-last-child(2) {
  overflow: hidden;
}

.mobile-nav-wrapper .ant-tabs-content-holder {
  flex: 0.73;
}

.ant-drawer-close {
  left: 0 !important;
  right: unset !important;
  position: relative !important;
}
.ant-drawer-body {
  padding-top: 0px !important;
}

/*TRANSITION ANT DRAWER*/
.ant-drawer > * {
  transition: transform 0.7s ease, box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1) !important;
}

@media screen and (max-width: 1280px) {
  .desktop-nav.active {
    display: none;
  }
  .mobile-nav.active {
    display: block;
    color: black;
  }
}

/**DATA SUBMENU RESPONSIVENESS*/

@media screen and (max-width: 1280px) {
  .data-submenu {
    flex-direction: column;
    padding: 0;
    max-width: 600px;
    width: 80%;
    margin: unset;
    padding-top: 0;
    padding-left: 0;
  }
  .data-submenu a {
    color: black;
    font-size: 15px;
  }
  .data-submenu .data-by-topic::after,
  .data-by-topic::before {
    display: none;
  }

  .data-submenu .submenu-data-list {
    padding-left: 0;
  }
  .data-submenu .data-by-topic:nth-child(1) {
    padding-bottom: 1rem;
  }
  .data-submenu .data-by-topic:nth-child(2) {
    padding-top: 1rem;
  }

  .data-submenu-menus {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: -20px;
  }
  .data-submenu .data-by-topic {
    flex: 1;
  }

  .data-submenu .data-by-topic:nth-child(1),
  .data-by-topic:nth-child(2) {
    padding-bottom: 0;
  }

  .header-inst {
    padding-left: 0rem !important;
  }
  .header-inst-list {
    grid-template-columns: repeat(2, 300px) !important;
  }
}
.mobile-nav-wrapper .ant-tabs-nav-list {
  width: 100% !important;
}

@media screen and (max-width: 900px) {
  .ant-tabs-content-holder {
    display: none !important;
  }
  .ant-tabs {
    position: relative;
    width: 100% !important;
  }

  .ant-tabs-left > .ant-tabs-nav {
    width: 100%;
    min-width: 100vw !important;
  }
  .tab {
    display: block;
    padding-right: 4rem;
  }
  .data-submenu {
    padding-top: 0px;
  }
}

/*LOGO AND MENU RESPONSIVE HANDLER*/

.header-responsive {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.responsive-logo-menu {
  flex: 0.6;

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.responsive-icons {
  flex: 0.2;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 500px) and (min-width: 400px) {
  .responsive-icons {
    flex: 0.25;
  }
}

@media screen and (max-width: 400px) {
  .responsive-icons {
    flex: 0.25;
  }
}
.theme-btn-responsive {
  padding-top: 1rem;
  max-width: 700px;
}

@media screen and (min-width: 1400px) {
  .theme-btn-responsive {
    padding-top: 1rem;
    max-width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 1030px) {
  .header-inst-list {
    grid-template-columns: repeat(2, 200px) !important;
  }
}

@media screen and (max-width: 900px) {
  .data-submenu-menus::after,
  .data-submenu-menus::before {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  .header-inst-list {
    grid-template-columns: repeat(1, 200px) !important;
  }
}

@media screen and (max-width: 500px) {
  .data-submenu-menus {
    display: block;
  }
  .data-submenu .data-by-topic:nth-child(1) {
    padding-bottom: 0.3rem;
  }
  .data-submenu .data-by-topic:nth-child(2) {
    padding-top: 0.3rem;
  }
}
@media screen and (min-width: 1280px) and (max-width: 1642px) {
  .data-submenu {
    height: 400px;
  }
}

@media screen and (max-width: 500px) {
  .data-submenu .data-submenu-menus {
    display: flex;
    align-items: center;
  }
  .data-submenu-menus > .data-by-topic:nth-child(2) {
    margin-top: 3px;
  }
}
