.theme-card-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.79);
  cursor: pointer;
  aspect-ratio: 1/1;
  transition: 0.15s ease-in;
  max-height: 310px;
  max-width: 330px;
  margin: auto;
  scroll-snap-align: start;
}
.theme-card-wrapper:hover {
  transform: scale(1.025);
}

.theme-card-image {
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.93;
}
.theme-card-wrapper .theme-card-text {
  position: absolute;
  width: 100%;
  height: 70px;
  bottom: 0px;
  padding-left: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.theme-card-wrapper .theme-card-text-with-background {
}

.theme-card-wrapper .theme-card-text h3 {
  bottom: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: white;
  text-transform: uppercase;
}
.theme-card-wrapper .theme-card-text h2 {
  text-transform: capitalize;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  letter-spacing: 1.6px;
}

@media screen and (max-width: 700px) {
  .theme-card-wrapper .theme-card-text h3 {
    font-size: 11px;
  }
  .theme-card-wrapper .theme-card-text h2 {
    font-size: 14px;
  }
}

.theme-test-wrapper {
  display: grid;
  grid-template-columns: repeat(4, 160px);
  overflow: scroll;
  width: 90vw;
  margin: auto;
  grid-gap: 40px;
  scroll-snap-type: x mandatory;
}
.theme-test-div {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.79);
  cursor: pointer;
  aspect-ratio: 1/1;
  transition: 0.15s ease-in;
  max-height: 310px;
  max-width: 330px;
  margin: auto;
  scroll-snap-align: start;
}

.theme-test {
}
.theme-test-desc {
}
