/*To Fix- Responsive FontSize for mobile */

.card-wrapper {
  width: 100%;
  position: relative;
  padding-top: 0px;
  box-sizing: border-box;
  margin: auto;
  min-height: 273px;
  display: flex;
  justify-content: center;
}
.card-wrapper::after {
  content: '';
  position: absolute;
  width: 1px;
  height: 80%;
  top: 10%;
  left: 0;
  background-color: rgba(126, 126, 126, 0.185);
}
.chart-card-wrapper {
  max-width: 400px;
  padding: 17px;
  display: inline-block;
  box-sizing: border-box;
  background-color: white;
  transition: 0.3s ease-in;
  margin: auto;
  border: 1px solid white;
  overflow: hidden;
  position: relative;
}

.chart-card-wrapper.larger {
  max-width: 600px;
  width: 90%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-wrapper.larger {
  height: 300px;
}
.chart-card-wrapper:hover {
  box-shadow: 10px 10px 40px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(126, 126, 126, 0.185);
  box-sizing: border-box;
  cursor: pointer;
}
.chart-card-wrapper:hover .card-hovered {
  display: block;
}

.chart-card-wrapper:hover .chart-button {
  display: flex;
}

.chart-card-wrapper .chart-title {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
}
.chart-card-wrapper .chart-ratio {
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 32px;
  color: #0e9898;
}

.chart-card-wrapper .chart-year {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #6b6b6b;
}
.chart-card-wrapper .chart-button-present {
  position: relative;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #151515;
  transition: 0.2s ease-in;
  display: flex;
  align-items: center;
  gap: 20px;
}

.chart-card-wrapper .chart-button {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #151515;
  transition: 0.2s ease-in;
  display: none;
  align-items: center;
  gap: 20px;
  padding-left: 1rem;
}
.chart-card-wrapper .chart-button:hover {
  color: #116530;
}
.card-empty-div {
  position: relative;
  width: 100%;
  height: 40px;
}

.card-hovered {
  padding-top: 20px;
  position: absolute;
  bottom: 19px;
  width: 100%;
  display: none;
  margin: auto;
  transform: translateX(-20px);
  overflow: hidden;
}

.horizontal {
  display: flex;
  flex-direction: row;
}

.flexLayout {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.flexLayout.horizontal {
  flex-direction: row-reverse;
  justify-content: space-around;
  height: 200px;
}
.flexLayout .layout-space {
  flex-direction: column;
  display: flex;
  justify-content: space-around;
  gap: 20px;
}
.flexLayout .layout-space a:hover {
  color: #116530;
}

@media screen and (max-width: 1200px) {
  .card-hovered {
    bottom: 15px;
  }
}
@media screen and (max-width: 900px) {
  .card-hovered {
    bottom: 32px;
  }
}

@media screen and (max-width: 840px) {
  .card-hovered {
    bottom: 40px;
  }
}

@media screen and (max-width: 907px) and (min-width: 769px) {
  .category-section-wrapper .card-hovered {
    bottom: 20px;
  }
}

@media screen and (max-width: 768px) {
  .card-hovered {
    bottom: 6px;
  }
}

@media screen and (max-width: 576px) {
  .card-hovered {
    bottom: 15px;
  }
}

/* /* @media screen and (max-width: 500px) {
  .card-hovered {
    bottom: 24px;
  } */
@media screen and (max-width: 409px) {
  .card-hovered {
    bottom: 15px;
  }
}
@media screen and (max-width: 410px) and (min-width: 390px) {
  .card-hovered {
    bottom: 25px;
  }
}
@media screen and (max-width: 350px) {
  .card-hovered {
    bottom: 10px;
  }
}

@media screen and (min-width: 490px) and (max-width: 590px) {
  .card-hovered {
    bottom: 20px;
  }
}

@media screen and (max-width: 370px) {
  .card-hovered {
    bottom: 18px;
  }
}

@media screen and (max-width: 999px) and (min-width: 700px) {
  .chart-card-wrapper .chart-button-present {
    font-size: 12px;
  }
  .chart-card-wrapper .chart-title {
    font-size: 14px;
  }
}

@media screen and (max-width: 420px) {
  .chart-card-wrapper .chart-button-present {
    font-size: 12px;
  }
  .chart-card-wrapper .chart-title {
    font-size: 14px;
  }
  .chart-card-wrapper .chart-ratio {
    font-size: 18px;
    line-height: 28px;
  }
  .flexLayout .layout-space {
    gap: 10px;
  }
}
@media screen and (min-width: 260px) and (max-width: 1200px) {
  .key-data-wrapper .card-wrapper img {
    max-height: 200px;
  }
}

.card-wrapper-res {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  height: 180px;
  width: 200px;
}

.card-wrapper-res .chart-title {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
}
.card-wrapper-res .chart-ratio {
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 32px;
  color: #0e9898;
}

.card-wrapper-res .chart-year {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #6b6b6b;
}

@media screen and (max-width: 700px) {
  .card-wrapper-res {
    display: flex;
  }

  .card-wrapper-res .chart-image img {
    max-height: 70px;
  }

  .card-wrapper-res .chart-title {
    height: 10vw;
    font-size: 12px;
    line-height: 14px;
  }
  .card-wrapper-res .chart-year {
    font-size: 10px;
    line-height: 12px;
  }
  .card-wrapper-res .chart-ratio {
    font-size: 14px;
  }

  section.section-key > article .chart-button {
    font-size: 11px;
  }
}
