.indicator-filters {
  flex: 0.2;
  position: relative;
  width: 100%;
  background: hsla(240, 20%, 98%, 0.356);
  padding: 8px;
  padding-bottom: 12px;
  border: 1px solid #d4d4d4a2;
}

.indicator-filters .filters-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #151515;
  padding-left: 17px;
  padding-bottom: 15px;
  letter-spacing: 1.5px;
}

.indicator-filters .filter-area {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: -100px;
}
.indicator-filters .filter-area .filter-comp {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
}
.indicator-filters .filter-area .filter-category {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  color: #151515;
  padding-bottom: 12px;
}

.indicator-filters .filter-area .filter-category::first-letter {
  text-transform: uppercase;
}
.indicator-filters .filter-area .filter-checkbox {
  padding-top: 10px;
}
.indicator-filters .filter-area .filter-checkbox span input[type='checkbox'] {
  padding: 30px;
}
.indicator-filters .filter-area .checkboxes {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.indicator-filters .filter-area .checkboxes .checkbox-value {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #151515;
}
.indicator-filters .filter-area .checkboxes .checkbox-value::first-letter {
  text-transform: uppercase;
}
@media screen and (max-width: 1024px) {
  .indicator-filters {
    flex: 1;
    padding: 10px;
  }
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #0e9898 !important;
  border-color: #0e9898 !important;
}

.ant-checkbox .ant-checkbox-inner:hover {
  border-color: #0e9898 !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #0e9898 !important;
}

.ant-radio-checked .ant-radio-inner:after {
  background-color: #0e9898 !important;
}

.ant-radio:hover .ant-radio-inner {
  border-color: #0e9898 !important;
}
[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel {
  background-color: green;
}
[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  margin-bottom: 5px;
  overflow: hidden;
  border: 0px;
}

/* .filter-range-picker {
  background-color: orangered;
  display: flex;
  flex-direction: column;
  width: 160px;
} */

.filter-range-picker:hover {
  border: #0e9898 1px solid !important;
}

.ant-picker-suffix {
  display: none !important;
}
.ant-picker-range .ant-picker-active-bar {
  background: #0e9898 !important;
  margin-left: 2px !important;
}
.ant-picker-focused {
  border-color: #0e9898 !important;
}

.indicator-filters .ant-collapse-header {
  font-weight: 600;
  font-size: 16px;
  padding: 0 !important;
  padding-bottom: 2px !important;
  padding-left: 17px !important;
}
.indicator-filters .ant-picker-input input {
  font-size: 12px !important;
}
