.institutions-list {
  position: relative;
  width: 100%;
  padding: 1rem 3.5rem;
  margin: auto;
}

.institutions-list section {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 350px));
  grid-gap: 1rem;
  justify-content: center;
}

.institutions-list h1 {
  text-align: center;
  font-size: 36px;
  font-weight: 400;
}

article.institution-list {
  max-width: 350px;
  background: #0e9898;
  padding: 16px;
  transition: 0.2s ease-in;
}
article.institution-list h3,
article.institution-list h2 {
  text-align: center;
  color: white;
}
article.institution-list h3 {
  font-weight: 700;
  font-size: 30px;
}

article.institution-list:hover {
  transform: scale(1.04);
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  article.institution-list {
    display: flex;
    justify-content: space-between;
  }
  article.institution-list h2,
  article.institution-list h3 {
    font-size: 13px;
  }
}
