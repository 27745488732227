.single-report-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  padding: 0px 0px;
  flex-direction: column;
  max-width: 450px;
  cursor: pointer;
  transition: 0.2s ease-in;
  background-color: rgb(245, 245, 245);
  margin-bottom: 20px;
  box-shadow: 10px -2px 26px -13px rgba(0, 0, 0, 0.38);
  -webkit-box-shadow: 10px -2px 26px -13px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: 10px -2px 26px -13px rgba(0, 0, 0, 0.38);
  scroll-snap-align: start;
}
.single-report-wrapper::after {
  content: '';
  width: 90%;
  margin: auto;
  height: 3px;
  background-color: #2a9898;
  bottom: 3px;
  left: 0;
  transform: scale(0);
  transform-origin: left;
  transition: 0.3s ease;
}
.single-report-wrapper:hover {
  transform: scale(1.06);
}
.single-report-wrapper:hover::after {
  transform: scale(1);
}

.single-report-wrapper .txt-content .title {
  position: relative;
  width: 100%;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #151515;
  padding-top: 0.7rem;
  padding-left: 1rem;
}
.single-report-wrapper .txt-content .title:hover > span {
  color: #2a9898;
}
.single-report-wrapper .txt-content .title span:nth-child(2) {
  visibility: hidden;
}
.single-report-wrapper:hover .txt-content .title span:nth-child(2) {
  visibility: visible;
}

.single-report-wrapper .txt-content .subtitle {
  padding-left: 1rem;
  width: 90%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #474747;
  padding-top: 2rem;
}
.single-report-wrapper .txt-content .author {
  padding-left: 1rem;
  width: 90%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #474747;
  padding-top: 5px;
}
.single-report-wrapper img {
  width: 100%;
  display: block;
  margin: auto;
  max-width: 100%;
  object-fit: cover;
  height: 150px;
}

@media screen and (max-width: 560px) and (min-width: 500px) {
  .single-report-wrapper {
    padding: 8px;
  }
  .single-report-wrapper .txt-content .title {
    font-size: 13px;
    line-height: 22px;
  }
  .single-report-wrapper .txt-content .subtitle {
    font-size: 12px;
    line-height: 18px;
  }
}

@media screen and (max-width: 700px) {
  .single-report-wrapper {
    width: 180px;
  }
}
