.search-content {
  position: relative;
  width: 100%;
}
.search-content .title {
  color: #212121;
  font-weight: 700;
  line-height: 50.6px;
  font-size: 42px;
  text-align: center;
  font-weight: 600;
}

.search-content section {
  min-height: 500px;
  max-width: 60%;
  margin: auto;
  padding: 6px 12px;
}

.search-content section .search-link {
  cursor: pointer;
}

.search-content section .search-link:nth-child(2n + 1) {
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}
.search-content section .search-link:nth-child(2n + 1):hover {
  background-color: rgb(243, 243, 243);
}
.search-content section .search-link:nth-child(2n + 2) {
  background-color: rgb(245, 245, 245);
  cursor: pointer;
}
.search-content section .search-link:nth-child(2n + 2):hover {
  background-color: rgb(251, 250, 250);
  cursor: pointer;
}
.search-content .search-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 16px;
  transition: 0.2s ease-in;
  margin-top: 10px;
}

.search-content .search-item:hover > p,
.search-content .search-item:hover > p > span {
  color: #0e9898;
}

.search-content .search-item > p:nth-child(1) {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: black;
  flex: 0.8;
}
.search-content .search-item > p:nth-child(1) > span {
  transition: 0.2s ease-in;
}
.search-content .search-item .search-category {
  transition: 0.2s ease-in;
  font-size: 12px;
  color: #757575;
  line-height: 18px;
  letter-spacing: 2.1px;
  font-weight: 700;
}
.search-content .search-item > p:nth-child(1) > span:nth-child(2) {
  font-weight: 500;
  display: inline-block;
  font-size: 14px;
  width: 100%;
}

@media screen and (max-width: 700px) {
  .search-content section {
    min-height: 500px;
    max-width: 80%;
    margin: auto;
    padding: 6px 12px;
  }
}
