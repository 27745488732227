.indicator-chart-wrapper {
  flex: 0.8;
  position: relative;
  width: 100%;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  padding-bottom: 5px;
}
.indicator-chart-wrapper .header {
  display: flex;
  padding: 8px;
  padding-left: 17px;
  padding-right: 17px;
  padding-bottom: 0;
}
.header .text {
  flex: 0.75;
}

.header .text h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
  color: #151515;
  margin-bottom: 5px;
}

.indicator-chart-wrapper .header .text p {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.indicator-chart-wrapper .header .icons {
  flex: 0.25;
  display: flex;
  justify-content: flex-end;
}
.indicator-chart-wrapper .header .icons .items {
  gap: 20px;
  display: flex;
}
.indicator-chart-wrapper .header .icons .items div img {
  width: 18px;
  height: 18px;
}
.indicator-chart-wrapper .header .icons .items div {
  transition: 0.2s ease;
  cursor: pointer;
}
.indicator-chart-wrapper .header .icons .items div:hover {
  transform: scale(1.02);
}

.indicator-chart-wrapper .chart {
  position: relative;
  width: 100%;
}
.indicator-chart-wrapper .chart img {
  max-width: 100%;
  position: relative;
  display: block;
  object-fit: contain;
}
.indicator-footer {
  padding: 40px;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.indicator-footer .txt {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 26px;
  text-decoration-line: underline;
  color: #151515;
  transition: 0.2s ease;
}
.indicator-footer .txt:hover {
  cursor: pointer;
  font-size: 18px;
}

.indicator-footer .share {
  text-decoration-line: none;
  width: 220px;

  font-style: normal;
  font-weight: 600;
  justify-content: space-around;
  font-size: 14px;
  line-height: 20px;
  color: #4d4d4d;
  display: flex;
  background-color: rgba(255, 255, 255, 0.671);
  padding: 16px;
}

.undp-text {
  color: white;
  margin-top: 5px;
  padding: 0;
  margin-bottom: 0;
  text-transform: uppercase;
}

.indicator-hashtags {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  text-align: start;
  padding-left: 5px;
  color: #828282;
}

.edt-url a {
  color: #0e9898 !important;
}

.publication-date:hover,
.publication-title:hover {
  text-decoration: underline;
  text-decoration-color: rgba(10, 105, 194, 0.25);
}

.edt-text {
  color: white;
  margin-top: 5px;
  padding: 0;
  margin-bottom: 0;
  font-size: x-large;
  text-transform: uppercase;
}
.indicator-footer .share div > img {
  cursor: pointer;
  transition: 0.1s ease;
}
.indicator-footer .share div:hover > img {
  transform: scale(1.4);
}
.explore-chart-spin {
  position: relative;
  width: 100%;
  display: grid;
  place-content: center;
  height: 300px;
}

.echart-tooltip {
  z-index: 0 !important;
  padding: 0px !important;
  width: auto;
  box-shadow: none !important;
  font-size: 16px !important;
  /* background: none !important; */
  background-color: none !important;
  margin-left: 120px;
  max-height: 300px;
  pointer-events: all !important;
  overflow-y: auto;
}

.echart-tooltip.modalopen {
  margin-left: -50px;
}

.echart-tooltip.modalopen.indicator {
  margin-left: 130px;
}

.echart-tooltip.modalopen.indicator {
  margin-left: 130px;
}
.positive-trend {
  color: green;
}

.negative-trend {
  color: red;
}

@media screen and (max-width: 1024px) {
  .indicator-chart-wrapper {
    flex: 1;
  }
}

@media screen and (max-width: 700px) {
  .header .text h5 {
    font-size: 20px;
    line-height: 22px;
  }

  .indicator-chart-wrapper .header .text p {
    font-size: 13px;
    line-height: 17px;
  }
  .indicator-chart-wrapper .header .icons .items div img {
    width: 15px;
    height: 15px;
  }
}
/*TOOLTIP POSITION*/

@media screen and (min-width: 1500px) {
  .echart-tooltip {
    margin-left: 200px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1200px) {
  .echart-tooltip.indicator {
    margin-left: 100px;
  }
}
@media screen and (max-width: 1500px) and (min-width: 1400px) {
  .echart-tooltip.indicator {
    margin-left: 157px;
  }
}
@media screen and (min-width: 1500px) {
  .echart-tooltip.indicator {
    margin-left: 250px;
  }
}
@media screen and (max-width: 1250px) {
  .echart-tooltip.indicator {
    margin-left: 50px;
  }
}
@media screen and (max-width: 1100px) {
  .echart-tooltip.indicator {
    margin-left: -20px;
  }
}
@media screen and (max-width: 1000px) {
  .echart-tooltip.indicator {
    margin-left: -100px;
  }
}
@media screen and (max-width: 900px) {
  .echart-tooltip.indicator {
    margin-left: -180px;
  }
}

.dcsv-img:hover {
  transform: scale(1.2);
}
