.subheader-wrapper {
  background: white;
  color: rgb(0, 0, 0);
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  padding: 48px 0px;
  padding-top: 1.5rem;
  position: relative;
  width: 100%;
}

.subheader-wrapper .ant-breadcrumb {
  color: rgb(0, 0, 0);
  font-weight: 700;
  letter-spacing: 2.8px;
  font-size: 14px !important;
  line-height: 20px;
  padding: 0 60px !important;
  position: relative;
  text-transform: uppercase;
  width: 100%;
}

.subheader-wrapper .ant-breadcrumb a {
  color: rgb(0, 0, 0);
}
.subheader-wrapper span.ant-breadcrumb-link a {
  color: rgb(0, 0, 0);
}
.subheader-wrapper span.ant-breadcrumb-link a:hover {
  color: #0e9898;
}

.subheader-wrapper .ant-breadcrumb a:hover {
  color: #0e9898;
}

.subheader-wrapper .ant-breadcrumb > span:last-child a {
  font-weight: normal;
  color: rgb(0, 0, 0);
}
.subheader-wrapper .ant-breadcrumb-separator {
  color: rgb(0, 0, 0);
  opacity: 0.6;
}

.subheader-wrapper .title {
  font-size: 48px;
  font-weight: 600;
  font-weight: 700;
  line-height: 57.6px;
  color: rgb(33, 33, 33);
  padding: 0 60px;
}

.subheader-wrapper .description {
  color: #626161;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  padding: 0 60px;
}
.subheader-wrapper .source {
  color: #626161;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 35px;
  padding: 0 60px;
}

.subheader-btn {
  padding: 0 60px;
  text-decoration: none;
  transition: 0.2s ease;
}
.subheader-btn button {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px 48px;
}
.subheader-btn button:hover {
  color: white;
  background-color: black;
}
@media screen and (max-width: 940px) {
  .category-img {
    transform: translateY(20%);
  }
}
@media screen and (max-width: 1000px) {
  .category-img {
    transform: translateY(30%);
  }
}
@media screen and (max-width: 500px) {
  .title {
    padding: 0 30px;
  }
  .description {
    padding: 0 30px;
  }
  .source {
    padding: 0 30px;
  }
  .ant-breadcrumb {
    padding: 0 30px !important ;
  }
  .subheader-btn {
    padding: 0 30px;
  }
}

@media screen and (max-width: 1000px) {
  .subheader-wrapper .title {
    font-size: 42px;
  }
}

@media screen and (max-width: 700px) {
  .subheader-wrapper {
    padding: 1.5rem 0px;
  }
  .subheader-wrapper .ant-breadcrumb {
    font-size: 11px !important;
    line-height: 18px;
    padding: 0 1.5rem !important;
  }
  .subheader-wrapper .title {
    padding: 0 1.5rem;
    font-size: 36px;
    font-weight: 600;
    font-weight: 700;
    line-height: 50.6px;
    margin-top: -1rem;
  }
  .subheader-wrapper .description {
    padding: 0 1.5rem;
    font-size: 16px;
    line-height: 30px;
  }
  .subheader-wrapper .source {
    padding: 0 1.5rem;
    font-size: 16px;
    line-height: 30px;
  }
}

@media screen and (max-width: 350px) {
  .subheader-wrapper .ant-breadcrumb {
    word-wrap: break-word;
    width: 90%;
  }
}
@media screen and (max-width: 300px) {
  .subheader-wrapper .ant-breadcrumb {
    word-wrap: break-word;
    width: 80%;
  }
}

@media (min-width: 768px) {
  .subheader-wrapper .ant-col-md-16 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media (min-width: 1280px) {
  .subheader-wrapper .ant-col-md-16 {
    display: block;
    flex: 0 0 66%;
    max-width: 66%;
  }
}
