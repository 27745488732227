.home-reports-wrapper {
  position: relative;
  width: 100%;
  background: #ffffff;
  overflow: hidden;
  padding: 3.5rem;
  padding-top: 0.5rem;
}

.reports-section-wrapper {
  position: relative;
  width: 100%;
  padding: 16px 0;
  display: grid;
  grid-template-columns: repeat(4, minmax(100px, 450px));
  grid-gap: 10px 20px;
  justify-content: flex-start;
}
@media screen and (min-width: 911px) and (max-width: 1200px) {
  .reports-section-wrapper {
    grid-template-columns: repeat(3, minmax(160px, 430px));
  }
}
@media screen and (max-width: 911px) and (min-width: 500px) {
  .reports-section-wrapper {
    grid-template-columns: repeat(2, minmax(100px, 430px));
  }
}
@media screen and (max-width: 525px) {
  .reports-section-wrapper {
    grid-template-columns: repeat(1, minmax(280px, 250px));
  }
}

.home-reports-wrapper h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  color: #151515;
  padding-top: 15px;
}

.home-reports-wrapper p {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 26px;
  color: #6b6b6b;
  margin-top: -14px;
}
.home-reports-wrapper .main-report {
  position: relative;
  width: 90%;
  margin: auto;
  padding-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-reports-wrapper .main-report .img-container {
  position: relative;
  display: inline-block;
}
.home-reports-wrapper .main-report .img-container img {
  max-width: 100%;
  display: block;
}
.home-reports-wrapper .main-report .absolute-wrapper {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  padding-left: 32px;
  padding-top: 20px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.66) 47.58%, rgba(0, 0, 0, 0.91) 100%);
}
.home-reports-wrapper .main-report .absolute-wrapper .report-title {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  color: white;
  max-width: 418px;
}
.home-reports-wrapper .main-report .absolute-wrapper .report-subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: white;
  padding-top: 16px;
  padding-bottom: 10px;
}
.report-view-btn {
  padding: 10px;
  padding-right: 3px;
  text-align: end;
}

.report-view-btn a {
  position: relative;
  display: inline-block;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #005757;
  transition: 0.2s ease-in;
  letter-spacing: 0.3px;
}
.report-view-btn a::before {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -4px;
  background-color: rgb(228, 228, 228);
  height: 3px;
}
.report-view-btn a::after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -4px;
  background-color: #2a9898;
  transform: scale(0);
  transform-origin: left;
  height: 3px;
  transition: 0.4s linear;
}
.report-view-btn a:hover {
  color: #2a9898;
}

.report-view-btn a:hover::after {
  transform: scale(1);
}

.reports-first-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.reports-message {
  height: 300px;
  display: grid;
  place-content: center;
}
@media screen and (max-width: 460px) {
  .home-reports-wrapper .main-report .absolute-wrapper .report-title {
    font-size: 15px;
    line-height: 20px;
  }
  .home-reports-wrapper .main-report .absolute-wrapper .report-subtitle {
    font-size: 12px;
    line-height: 22px;
    padding-top: 10px;
    padding-bottom: 5px;
  }
}

@media screen and (max-width: 900px) {
  .reports-section-wrapper {
    grid-template-columns: repeat(auto-fill, minmax(200px, 450px));
  }
}

@media screen and (max-width: 700px) {
  .report-view-btn {
    padding-top: 1px;
    padding-right: 2rem;
  }
  .report-view-btn a {
    font-size: 16px;
  }
  .reports-section-wrapper {
    grid-template-columns: repeat(2, minmax(100px, 450px));
    grid-gap: 10px 20px;
    justify-content: flex-start;
  }
  .single-report-wrapper .txt-content .title {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 900px) {
  .home-reports-wrapper {
    padding: 0 1.5rem;
  }
  .home-reports-wrapper h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 900px) and (min-width: 765px) {
  .home-reports-wrapper {
    padding: 0 3.5rem;
  }
}
@media screen and (max-width: 500px) {
  .home-reports-wrapper {
    padding: 0 1.5rem;
  }
  .home-reports-wrapper h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 920px) and (min-width: 700px) {
  .reports-section-wrapper {
    grid-template-columns: repeat(2, minmax(250px, 400px));
  }
}

@media screen and (max-width: 699px) and (min-width: 501px) {
  .reports-section-wrapper {
    grid-template-columns: repeat(2, minmax(200px, 300px));
  }
}

@media screen and (max-width: 700px) {
  .reports-section-wrapper {
    overflow: scroll;
    scroll-snap-type: x mandatory;
    grid-template-columns: repeat(4, minmax(200px, 300px));
    width: 90vw;
    margin: auto;
  }
}
